import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as m from '../utils/helper/methods';

import { makeStyles } from '@mui/styles';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import Loading from '../components/global/Loading';
import SectionHeader from '../components/global/SectionHeader';
import SnackbarMessage from '../components/global/SnackbarMessage';
import SectionContainer from '../components/global/SectionContainer';
import moment from 'moment';
import ContentTable from '../components/global/ContentTable';
import CustomTokenCode from '../components/player/CustomTokenCode';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));


export default function SecureTokenOverview(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    let { token } = useParams();

    const [message, setMessage] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const [loading, setLoading] = useState(false);
    const [tokenInfo, setTokenInfo] = useState([]);
    const [streamInfo, setStreamInfo] = useState([]);

    document.title = "Secure Token Overview | nanoStream Cloud Dashboard"

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowMessage(false);
    }


    const redirect = (link) => {
        navigate(link);
    }

    const decodeToken = () => {
        let decodedToken = m.DECODE_TOKEN(token);
        if (decodeToken) {
            if (decodedToken) {
                setLoading(false);
                setTokenInfo([
                    { label: "JWT token", value: token, copy: true },
                    { label: "Not Before (nbf)", nbf: moment.unix(decodedToken.nbf).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                    { label: "Expires (exp)", exp: moment.unix(decodedToken.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                    ...(decodedToken.orgahash ? [{ label: "Entire Organisation", bool: { is: true }, copy: false }] : []),
                    ...(decodedToken.streams ? [{ label: "Stream name(s)", value: `[${decodedToken.streams.map(s => s)?.join(", ")}]`, copy: true }] : []),
                    ...(decodedToken.tag ? [{ label: "Tag", tag: decodedToken.tag }] : []),
                    ...(decodedToken.ip ? [{ label: "IP", value: decodedToken.ip, copy: true }] : []),
                    ...(decodedToken.domain ? [{ label: "Domain", link: decodedToken.domain, copy: true }] : []),
                ]);
            }
            setStreamInfo(
                decodedToken.streams
                    ? decodedToken.streams.map((stream, i) => { return { label: `Streamname ${i + 1}`, value: stream, copy: true } })
                    : false
            );
            // if streams, set code snippet
            // if orgawide, set security obj
        }
        if (!decodedToken) {
            setLoading(false);
            redirect(link.PLAYBACK_TOKEN);
            // setMessage({
            //     type: "info",
            //     message: ""
            // });
            // setShowMessage(true);
        }
    }

    useEffect(() => {
        if (token) decodeToken();
    }, [token])

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showMessage}
                close={handleCloseMessage}
                type={message.type}
            >
                {message.message}
            </SnackbarMessage>
            {
                loading && !tokenInfo
                    ? <Loading />
                    :
                    <Fragment>
                        <Grid item xs={12}>
                            <SectionHeader
                                title="Secure playback token overview"
                                underline={"Here you can overview the token you just created."}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SectionContainer
                                title="Decoded token information"
                                underline="Warning: JWTs are credentials, which can grant access to resources. Be careful where you paste them! We do not record tokens, all validation and debugging is done on the client side."
                            >
                                {
                                    tokenInfo
                                    && <ContentTable data={tokenInfo} />
                                }
                            </SectionContainer>

                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <CustomTokenCode
                                title="Security Config Object"
                                underline="Paste this object either into the group object or each entries element of your player configuration."
                                token={token}
                            />
                        </Grid>
                    </Fragment>
            }
        </Grid>
    )
}
