import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../../utils/helper/link-config';
import * as m from '../../utils/helper/methods';

import streams from '../../assets/streams-background.jpeg';
import datagrid from '../../assets/datagrid_background.jpeg';
import world from '../../assets/world-background.jpeg';
import dashboard from '../../assets/city-background.jpeg'

import { makeStyles } from '@mui/styles';
import { alpha, Button, Divider, Grid, Link, Typography } from '@mui/material';
import { HelpOutline, PlayCircleOutline, OpenInNew, PieChartOutline, AddCircleOutline } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import ArticleCard from '../global/ArticleCard';
import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    quickStartWrapper: {
        backgroundImage: `linear-gradient(to right bottom, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.8)})`,
        borderRadius: theme.spacing(2),
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(3)
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },

    },
    title: {
        fontSize: '28px !important'
    },
}));

export default function WelcomePage(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const orga = props.orga;
    let supportUrl = `${link.NANO_SUPPORT}?bintu.id=${orga ? orga.id : ""}&bintu.name=${orga ? orga.name : ""}&bintu.webhook=${orga ? orga.webhook : ""}&bintu.vod=${orga ? orga.vod : ""}&bintu.webrtc=${orga ? orga.webrtc : ""}&bintu.transcoding=${orga ? orga.transcoding : ""}&bintu.h5live=${orga ? orga.h5live : ""}&bintu.hash=${orga ? orga.hash : ""}&bintu.hls=${orga ? orga.hls : ""}&bintu.secure=${orga ? orga.secure : ""}&bintu.metrics=${orga ? orga.metrics : ""}&bintu.sla=${orga ? orga.sla : ""}&bintu.trial=${orga ? orga.trial : ""}`

    const handleLinkClicked = (link) => () => {
        navigate(link);

    }

    const handleOpenExternal = (link) => () => {
        window.open(link, "_blank")
    }

    return (
        <Grid container className={classes.root}>
            <SectionHeader
                light
                specialHeader
                title={`Welcome to nanoStream Cloud!`}
                underline="Let's start streaming around the world in ultra low latency and interact with your audience."
            />
            <Grid item xs={12} mt={2}>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={"auto"}
                mt={2}
            >
                <div className={classes.quickStartWrapper}>
                    <SectionHeader
                        orange
                        title="Quick Start | Go live!"
                        underline="You are now all set to start streaming! Enjoy our nanoStream Cloud and create your low latency live stream."
                    />
                    <Button
                        sx={{ mt: 1 }}
                        variant="outlined"
                        color="secondary"
                        endIcon={<AddCircleOutline />}
                        onClick={handleLinkClicked(link.CREATE_STREAM)}
                    >
                        Create stream
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12} mt={2} mb={1}>
                <SectionHeader
                    title="How to get started"
                    underline="To help you get started with the nanoStream Cloud, we've put together a range of helpful resources!"
                    interact
                    button="Talk to us"
                    clicked={handleOpenExternal(supportUrl)}
                    icon={<OpenInNew />}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} pr={2} mt={2}>
                <ArticleCard
                    image={streams}
                    title="How to setup your live stream"
                    underline="Watch this tutorial to learn how to create and start your fist stream. Our expert will guide you through this process within a few clicks."
                >
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<PlayCircleOutline />}
                        onClick={handleOpenExternal(link.GETTING_STARTED_CREATE_STREAM)}
                    >
                        Watch Video
                    </Button>
                </ArticleCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} pr={2} mt={2}>
                <ArticleCard
                    image={dashboard}
                    title="Getting Started with the Dashboard"
                    underline="Have a look into our docs to learn how to use the nanoStream Cloud Dashboard."
                >
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<OpenInNew />}
                        onClick={handleOpenExternal(link.GETTING_STARTED_CREATE_STREAM)}
                    >
                        Learn more
                    </Button>
                </ArticleCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} pr={2} mt={2}>
                <ArticleCard
                    image={datagrid}
                    title="Analytics for your streams"
                    underline="Know how your streams are performing, and analyse the data at the tip of your fingers! Experience for yourself how it can help you."
                >
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<PieChartOutline />}
                        onClick={handleOpenExternal(link.METRICS_DOCS)}
                    >
                        Read more
                    </Button>
                </ArticleCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} pr={2} mt={2}>
                <ArticleCard
                    image={world}
                    title="Learn more"
                    underline="You can find an overview of further links to each component of the nanoStream Cloud here. These include the API, blog articles and samples."
                >
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<HelpOutline />}
                        onClick={handleLinkClicked(link.SUPPORT)}
                    >
                        Help Center
                    </Button>
                </ArticleCard>
            </Grid>
            <Grid item xs={12} mt={4} mb={2}>
                <Divider />
            </Grid>
        </Grid >

    )
}
