import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as analytics from '../utils/helper/analytics';
import * as link from '../utils/helper/link-config';
import * as m from '../utils/helper/methods';

import workflow from '../assets/workflow.png';
import world from '../assets/world-background.jpeg';
import nsc_orange from '../assets/orange-nsc.png';
import security from '../assets/security.png';
import transcoding from '../assets/transcoding.png';
import metrics from '../assets/metrics.png';
import cdn from '../assets/cdn.png';
import api from '../assets/api_dashboard.png';
import adaptive from '../assets/adaptive.png';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, alpha, Collapse, IconButton, Button, Box, Stack, Chip, Hidden } from '@mui/material';
import { CalendarMonthOutlined, CreditCardOff, CreditCardOffOutlined, SwitchAccessShortcutAdd } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import SignIn from '../components/registration/SignIn';
import SignUp from '../components/registration/SignUp';
import SnackbarMessage from '../components/global/SnackbarMessage';



export default function Auth(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [login, setLogin] = useState(true);
    const [socialMedia] = useState(link.SOCIAL_MEDIA);

    document.title = "Sign Up | nanoStream Cloud Dashboard";

    const changeAuthState = () => navigate(link.AUTH);
    const handleClickedSocialMediaLink = (link) => () => window.open(link);

    useEffect(() => { if (m.CHECK_ACCESS()) { navigate(link.DASHBOARD) } }, [])
    useEffect(() => { if (searchParams.has('signup')) navigate(link.SIGNUP) }, []);

    return (
        <Fragment>
            <Grid container p={2}
                sx={{
                    minHeight: '100vh', height: '100%', maxHeight: '100%',
                    bgcolor: theme.palette.secondary.light,
                    backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.secondary.light, 0.4)}, ${theme.palette.secondary.main})`
                }}
            >
                <Grid item container xs={12} md={7} p={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8} sx={{ color: theme.palette.common.white, textAlign: 'center' }}>
                        <Typography variant="h2" mb={1} sx={{ lineHeight: 1, fontWeight: 700 }}>
                            Welcome to <br />
                            <Box
                                component="img" src={nsc_orange} mt={.5}
                                sx={{ height: { xs: 50, md: 90 } }}
                            />
                        </Typography>
                        <Typography variant="h6">
                            Sign up and get access to the complete nanoStream Cloud Service.
                        </Typography>
                        <Stack direction="row" mt={2} flexWrap={"wrap"} justifyContent={"center"}>
                            {
                                [
                                    { icon: <CalendarMonthOutlined fontSize="small" />, label: "7 days free trial" },
                                    { icon: <CreditCardOffOutlined fontSize="small" />, label: "No credit card required" },
                                    { icon: <SwitchAccessShortcutAdd fontSize="small" />, label: "Instant access via dashboard or API" },
                                ].map((chip) => (
                                    <Chip
                                        sx={{ mr: .5, my: .5 }}
                                        icon={chip.icon}
                                        key={chip.label} label={chip.label}
                                        color="primary" variant="outlined"
                                    />
                                ))
                            }
                        </Stack>
                        {/* </Grid>
                    <Grid item xs={12} md={8} sx={{ p: { xs: 2, md: 0 } }}> */}
                        <SignUp signin={changeAuthState} />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        {
                            socialMedia.map((s, i) => (
                                <IconButton key={i}
                                    color="primary" size="medium"
                                    onClick={handleClickedSocialMediaLink(s.link)}
                                >
                                    {s.icon}
                                </IconButton>
                            ))
                        }
                    </Grid>
                </Grid>
                <Hidden mdDown>
                    <Grid item container md={5}
                        justifyContent={'center'} alignItems={"center"}
                        sx={{
                            borderRadius: theme.spacing(2), p: 4,
                            backgroundImage: `linear-gradient(to top, ${alpha(theme.palette.secondary.light, 0.8)}, ${alpha(theme.palette.secondary.dark, 0.9)}), url(${world})`,
                            backgroundSize: 'cover', backgroundRepeat: "no-repeat", backgroundPosition: "top",
                        }}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3" color={theme.palette.common.white} sx={{ fontWeight: 400, textAlign: 'center', lineHeight: 1 }}>
                                <Box component="span" sx={{ color: theme.palette.primary.main, fontWeight: 700 }}>Stream in real-time</Box> globally around the clock
                            </Typography>
                            <Stack direction="column" flexWrap={"wrap"} alignItems={"flex-start"} justifyContent={"center"} sx={{ mb: 6, mt: 2, px: 3 }}>
                                {
                                    [
                                        "Experience total control, security and reliability",
                                        "Get 100% insights to boost your service and audience experience"
                                    ].map((t) => (
                                        <Typography sx={{ color: theme.palette.common.white, fontWeight: 700 }} variant="h6" key={t}>
                                            <Box component="span" sx={{ color: theme.palette.primary.main }}>&#x2022;</Box> {t}
                                        </Typography>
                                    ))
                                }
                            </Stack>
                            <Stack direction="row" mt={2} spacing={.5} justifyContent={"center"}>
                                {
                                    [
                                        { img: transcoding, label: "Live transcoding" },
                                        { img: cdn, label: "Global CDN" },
                                        { img: adaptive, label: "Adaptive Player" }
                                    ].map((item) => (
                                        <Box key={item.label} sx={{ width: 150, textAlign: 'center' }}>
                                            <Box
                                                component={"img"}
                                                src={item.img}
                                                sx={{ width: 75, mb: 0.5 }}
                                            />
                                            <Typography sx={{ color: theme.palette.common.white, fontWeight: 700, wordWrap: "normal" }}>
                                                {item.label}
                                            </Typography>
                                        </Box>
                                    ))
                                }
                            </Stack>
                            <Stack direction="row" mt={4} spacing={.5} justifyContent={"center"}>
                                {
                                    [
                                        { img: metrics, label: "Analytics" },
                                        { img: api, label: "API Integration & Cloud Dashboard" },
                                        { img: security, label: "Stream Security" }
                                    ].map((item) => (
                                        <Box key={item.label} sx={{ width: 150, textAlign: 'center' }}>
                                            <Box
                                                component={"img"}
                                                src={item.img}
                                                sx={{ width: 75, mb: 0.5 }}
                                            />
                                            <Typography sx={{ color: theme.palette.common.white, fontWeight: 700, wordWrap: "normal" }}>
                                                {item.label}
                                            </Typography>
                                        </Box>
                                    ))
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </Fragment >
    )
}
