import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import Map from '../components/worldmap/Map';
import { getAvailableCountries, getPlayoutsAndIngests } from '../utils/api-requests';
import SectionHeader from '../../components/global/SectionHeader';

export default function Worldmap(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    document.title = "Worldmap | nanoStream Cloud Dashboard";

    useEffect(() => {
        getPlayoutsAndIngests()
            .then((success) => {
                console.log(success);
                setData(success.data)
            }).catch((error) => {
                console.log("Error: ", error);
            })
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionHeader
                    title="Worldmap"
                    underline="Monitor where your customers are located and analyze the statistics per country."
                />
            </Grid>
            <Grid item xs={12}>
                <Map data={data} />
            </Grid>
        </Grid>
    )
}
