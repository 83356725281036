import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Chip, Grid, Stack, TablePagination, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import SectionHeader from '../global/SectionHeader';
import { getTagCollection } from '../../utils/bintu/api-requests';
import SnackbarMessage from '../global/SnackbarMessage';

export default function AllTags(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [tags, setTags] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(25);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    const handleChangePage = (event, newPage) => { setPage(newPage); }

    const handleChangeRowsPerPage = (event) => {
        setLimit(event.target.value);
        setPage(0);
    }

    const fetchTags = () => {
        let data = {
            limit,
            offset: page === 0 ? 0 : page * limit
        }

        getTagCollection(data)
            .then((response) => {
                if (response.success) {
                    setCount(response.data.overall_tag_count);
                    setTags(response.data.tags);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => {
        fetchTags();
    }, [])

    return (
        <Grid container>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12} p={2} sx={{ borderRadius: theme.spacing(2), bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900] }}>
                <SectionHeader
                    title={"All Tags"}
                    underline={tags.length === 0 ? " You have no tags yet! Create a new tag while creating or editing a stream. This might help you later find or filter your stream later." : "This is an overview of all tags your organisation uses."}
                />
                <Stack useFlexGap={true} my={1.5} spacing={.5} direction="row" flexWrap={"wrap"}>
                    {
                        tags.map((t, i) => (
                            <Chip
                                key={t.tag} label={t.tag}
                                sx={{ borderRadius: 1 }}
                                size="small" // clickable
                                color="primary"
                            />
                        ))
                    }
                </Stack>
                <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPageOptions={[15, 25, 35, 45]}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    )
}
