import React, { useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as m from '../../utils/helper/methods';

import world from '../../assets/datagrid_background.jpeg';


import { makeStyles } from '@mui/styles';
import { alpha, Button, Box, Grid, Typography } from '@mui/material';
import { ArrowRightAltOutlined, OpenInNewOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.info.dark}, ${alpha(theme.palette.secondary.main, 0.7)}),
        url(${world})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: "center bottom",
        borderRadius: theme.spacing(2),
        padding: theme.spacing(3),
        color: theme.palette.common.white
    },
    image: {
        height: 200,
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
        alignItems: 'center'

    }
}));

export default function SwitchToDashboardv2(props) {
    const classes = useStyles();
    const created = props.created;

    const handleOpenInNew = (link) => () => {
        window.open(link, '_blank');
    }


    const handleOpenInSelf = (link) => () => {
        window.open(link, '_self');
    }

    return (
        <Box className={classes.root} mt={2} mb={1}>
            <Grid container flexWrap={'wrap'}>
                <Grid item xs={12}>
                    <Typography variant="h4" >
                        You are using the new nanoStream Cloud System with Dashboard V3
                    </Typography>
                    <Typography variant="body1" sx={{ maxWidth: { md: '50%' } }}>
                        This new update contains a lot of improvements, especially for Adaptive Bitrate Streaming!
                    </Typography>
                    <Button
                        sx={{ mt: 1, mr: 1 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<OpenInNewOutlined />}
                        onClick={handleOpenInNew(link.DASHBOARD_V3_RELEASE)}
                    >
                        Learn More
                    </Button>
                    <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<ArrowRightAltOutlined />}
                        onClick={handleOpenInSelf(link.BINTU_DASHBOARD_V2)}
                    >
                        Go back to v2
                    </Button>
                </Grid>
            </Grid>
        </Box >
    )
}
