import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, Button, Divider, Chip, Avatar, Typography, useTheme } from '@mui/material';
import { ErrorOutline, ExpandLess, ExpandMore } from '@mui/icons-material';

import * as helper from '../../utils/analytics-helper';

import AlertTable from './AlertTable';
import SectionHeader from '../../../components/global/SectionHeader';
import SectionContainer from '../../../components/global/SectionContainer';
import Loading from '../../../components/global/Loading';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function AlertSection(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [alerts, setAlerts] = useState(props.data);
    const color = Object.values(helper.ALERT_COLOR_SCHEME).find(s => s.title === props.severity)?.color;

    useEffect(() => {
        setAlerts(props.data);
    }, [props.data])

    return (
        <SectionContainer
            title={`${props.severity} Alerts (${alerts.length})`}
            underline={alerts.length === 0 ? `There are no ${props.severity.toLowerCase()} alerts reported.` : "By expanding the alerts you can learn more about each alert."}
            extraStyle={{ "borderLeft": `10px solid ${color}` }}
        >
            <Grid container>
                {
                    props.loading
                        ? <Grid item xs={12} mt={1} sx={{ textAlign: 'center' }}>
                            <Loading />
                        </Grid>
                        : <Grid item xs={12}>
                            {
                                alerts.length > 0
                                && <AlertTable list={alerts} severity={props.severity} />
                            }
                        </Grid>
                }
            </Grid>

        </SectionContainer>
    )
}
