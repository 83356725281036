import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { datadogRum } from "@datadog/browser-rum";

import * as link from '../../utils/helper/link-config';
import * as m from '../../utils/helper/methods';

import Auth from '../../pages/Auth';
import SetPassword from '../../pages/SetPassword';
import Logout from '../registration/Logout';
import Dashboard from '../../pages/Dashboard';
import CreateStream from '../../pages/CreateStream';
import Metrics from '../../analytics/pages/Metrics';
import Organisation from '../../pages/Organisation';
import NotFound from '../../pages/404';
import About from '../../pages/About';
import Support from '../../pages/Support';
import Invite from '../../pages/Invite';
import CreatePlaybackToken from '../../pages/SecurePlaybackToken';
import Stream from '../../pages/Stream';
import Webcaster from '../../pages/Webcaster';
import Streams from '../../pages/AllStreams';
import Playout from '../../pages/Playout';
import SecureTokenOverview from '../../pages/SecureTokenOverview';
import NewStream from '../../pages/NewStream';
import Alerts from '../../analytics/pages/Alerts';
import SignUp from '../../pages/SignUp';
import Welcome from '../../pages/Welcome';
import Worldmap from '../../analytics/pages/Worldmap';

export default function Router(props) {
    const [hasAccess, setHasAccess] = useState(m.CHECK_ACCESS());

    const ProtectedRoute = () => {
        return hasAccess ? <Outlet /> : <Navigate to={link.AUTH} replace={true} />;
    }

    const checkAccess = () => { setHasAccess(m.CHECK_ACCESS()); }

    useEffect(() => {
        checkAccess();
        window.addEventListener('storage', checkAccess)
    }, [])

    return (
        <Routes>
            <Route path={link.AUTH} element={<Auth />} />
            <Route path={link.SIGNUP} element={<SignUp />} />
            <Route path={link.WELCOME} element={<Welcome />} />
            <Route path={`${link.INVITE_TOKEN}/:token`} element={<Invite />} />
            <Route path={`${link.SET_PASSWORD}/:token`} element={<SetPassword />} />
            <Route path={`${link.PLAYOUT}/:id`} element={<Playout {...props} isExternal={!hasAccess} />} />
            <Route element={<ProtectedRoute />}>
                <Route path={link.ABOUT} element={<About />} />
                <Route path={link.CREATE_STREAM} element={<CreateStream />} />
                <Route path={link.STREAM}>
                    <Route path={`${link.NEW_STREAM}/:id`} element={<NewStream />} />
                    <Route path={`${link.STREAM}/:id`} element={<Stream />} />
                    <Route path={link.STREAMS} element={<Streams />} />
                </Route>
                <Route path={link.DASHBOARD} element={<Dashboard />} />
                <Route path={link.METRICS} element={<Metrics />} />
                <Route path={`${link.ALERTS}`} element={<Alerts />} />
                <Route path={`${link.WORLDMAP}`} element={<Worldmap />} />
                <Route path={link.USER} element={<Organisation />} />
                <Route path={link.WEBCASTER}>
                    <Route path={`${link.WEBCASTER}/:id`} element={<Webcaster />} />
                    <Route path="" element={<Webcaster />} />
                </Route>
                <Route path={link.SUPPORT} element={<Support />} />
                <Route path={link.PLAYBACK_TOKEN}>
                    <Route path={`${link.PLAYBACK_TOKEN}/:token`} element={<SecureTokenOverview />} />
                    <Route path="" element={<CreatePlaybackToken />} />
                </Route>
                <Route path={link.LOGOUT} element={<Logout />} />
                <Route path={"*"} element={<NotFound />} />
            </Route>
        </Routes>
    )

}

