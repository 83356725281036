import React from 'react';

import { makeStyles } from '@mui/styles';
import { alpha, Fab, Tooltip, useScrollTrigger, useTheme, Zoom } from '@mui/material';
import { KeyboardArrowUpOutlined } from '@mui/icons-material';


export default function ScrollToTop(props) {
    const theme = useTheme();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 65
    });

    const handleScrollToTop = (event) => {
        // const anchor = (event.target.ownerDocument || document).querySelector(props.anchorId);

        // if (anchor) {
        //     anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        // }

        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <Zoom in={trigger}>
            <div onClick={handleScrollToTop} role="presentation">
                <Tooltip title="Scroll to top" placement="top">
                    <Fab
                        size="small"
                        sx={{
                            right: theme.spacing(2),
                            bottom: theme.spacing(9),
                            position: 'fixed',
                            color: theme.palette.common.white,
                            backgroundImage: `linear-gradient(to left, ${alpha(theme.palette.primary.main, 0.8)}, ${theme.palette.primary.main}) !important`
                        }}>
                        <KeyboardArrowUpOutlined />
                    </Fab>
                </Tooltip>
            </div>
        </Zoom>
    );
}
