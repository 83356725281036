import React, { cloneElement, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import { alpha, Grid, Typography } from '@mui/material';

import AnalyticsHeader from './AnalyticsHeader';
import SectionContainer from '../../../components/global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function AnalyticsContainer(props) {
    const classes = useStyles();
    // const [filter, setFilter] = useState(null);
    const [timeFilter, setTimeFilter] = useState(null);
    const [countryFilter, setCountryFilter] = useState(null);

    // const updateFilter = (updatedFilter) => {
    //     setFilter(updatedFilter)
    // }

    const updateTime = (updatedTime) => {
        setTimeFilter(updatedTime)
    }

    const updateCountry = (updatedCountry) => {
        setCountryFilter(updatedCountry)
    }


    return (
        <SectionContainer>
            <AnalyticsHeader
                {...props}
                // updateFilter={updateFilter}
                updateTime={updateTime}
                updateCountry={updateCountry}
            />
            {
                cloneElement(props.children,
                    {
                        // ...filter && { filter: filter },
                        ...timeFilter && { time: timeFilter },
                        ...countryFilter && { country: countryFilter }
                    })
            }
            {/* {props.children} */}
        </SectionContainer>
    )
}
