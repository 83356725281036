import React from 'react';

import * as link from '../../utils/helper/link-config';

import world from '../../assets/world-background.png';
import dashboard from '../../assets/analytics.png';

import { makeStyles } from '@mui/styles';
import { alpha, Button, Grid, Typography } from '@mui/material';
import { OpenInNew, ArrowRightAlt, Chat } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    bannerBackground: {
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${alpha(theme.palette.secondary.main, 0.7)}),
        url(${world})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: "center bottom",
        minHeight: 200,
        borderRadius: theme.spacing(2),
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4)
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2),
            flexDirection: 'row',
        },
    },
    buttonWrapper: {
        marginTop: `${theme.spacing(2)} !important`
    },
    button: {
        marginRight: `${theme.spacing(1)} !important`,
        marginBottom: `${theme.spacing(1)} !important`
    },

    textWrapper: {
        [theme.breakpoints.up('xs')]: {
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
            paddingRight: theme.spacing(1)
        }
    },
    title: {
        fontSize: '28px !important'
    },
    dashboard: {
        [theme.breakpoints.up('xs')]: {
            width: 300
        },
        [theme.breakpoints.up('lg')]: {
            width: 500
        },
        maxWidth: '100%'
    }
}));

export default function MetricsBanner(props) {
    const classes = useStyles();

    const handleButtonClicked = (link) => () => {
        window.open(link, "_blank")
    }

    return (
        <div className={classes.bannerBackground}>
            <div className={classes.textWrapper}>
                <Typography color="white" variant="h3" className={classes.title}>
                    Start using our client focused API
                </Typography>
                <Typography color="white" variant="body1">
                    Our metrics API allows users to directly access source data that is visualized in the Analytics dashboard.
                </Typography>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        endIcon={<ArrowRightAlt />}
                        variant="contained"
                        onClick={handleButtonClicked(link.METRICS_API_DOC)}
                    >
                        See Client API
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        endIcon={<Chat />}
                        onClick={handleButtonClicked(link.NANO_SUPPORT)}
                    >
                        Talk to us
                    </Button>
                </div>
            </div>
            <img src={dashboard} className={classes.dashboard} />
        </div>
    )
}
