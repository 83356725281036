import React, { useEffect, useState } from 'react';

import * as ls from '../../../utils/helper/ls-vars';
import * as bintu from '../../../utils/bintu/api-requests';
import * as link from '../../../utils/helper/link-config';

import { makeStyles, styled } from '@mui/styles';
import { TextField, Grid, FormControl, Typography, MenuItem, Button, Switch, FormGroup, FormControlLabel, Collapse } from '@mui/material';
import { CheckCircle, Cloud, CloudDone, DoNotDisturb, DoNotDisturbOn, HighlightOff, TaskAlt, TaskAltOutlined } from '@mui/icons-material';

import SectionContainer from '../../global/SectionContainer';
import SnackbarMessage from '../../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    item: {
        textTransform: 'capitalize'
    }
}));

export default function AddThumbnails(props) {
    const classes = useStyles();
    const [process, setProcess] = useState(props.opcode?.includes("thumbs")); // useState(props.opcode === null ? "none" : props.opcode);
    const [interval, setInterval] = useState(props.opcode?.includes(":") ? props.opcode?.split(":")[1] : 30);
    // const [options] = useState(["none", "thumbs"]);

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleUpdateInterval = (event) => {
        setInterval(event.target.value);
    }

    const handleBlurredTextfield = () => {
        if (interval === "" || Number(interval) < 10 || Number(interval) > 3600 || /[^0-9]+/.test(interval)) {
            setInterval(30);
        }
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdateProcessing = () => {
        let data = {
            streamid: props.streamid,
            opcode: process ? "thumbs" : "none",
            ...process && { interval: parseInt(interval) }
        }

        bintu.setOpcode(data)
            .then((response) => {
                if (response.success) {
                    console.log(response.data)
                    let fallback = `${link.THUMBNAILS}/${props.streamname}.jpg`
                    let data = {
                        newOpcode: response.data.opcode,
                        newThumbnail: response.data.playout.thumbnails || fallback
                    }
                    props.updated(data)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }


    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <FormGroup sx={{ mb: 1 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={process}
                                onChange={() => { setProcess(!process) }}
                            />
                        }
                        label={`Thumbnail Generation: ${process ? "Active" : "Inactive"}`}
                    />
                </FormGroup>
                <Collapse in={process}>
                    <TextField
                        variant="standard"
                        label="Interval (s)"
                        value={interval}
                        onBlur={handleBlurredTextfield}
                        onChange={handleUpdateInterval}
                    />
                </Collapse>
                <Typography sx={{ fontStyle: 'italic' }} variant="body2" color="textSecondary" mt={1}>
                    <b>Important Note</b>: To apply any changes, you must (re-)ingest your stream. If your stream is live, the modifications will only take effect once you stop and restart it.
                </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleUpdateProcessing}
                >
                    Save settings
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Dismiss Changes
                </Button>
            </Grid>
        </Grid>
    )
}
