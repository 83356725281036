import React, { useState, useEffect } from 'react';
import moment from 'moment';

import * as ls from '../../utils/helper/ls-vars';
import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Grid, TextField, Typography, Collapse, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, Hidden } from '@mui/material';
import { Delete, PersonAddAlt1Outlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import CopyButton from '../global/CopyButton';
import { Fragment } from 'react';
import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';
import ContentDialog from '../global/ContentDialog';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function InviteNewUser(props) {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [inviteToken, setInviteToken] = useState("");

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const createNewToken = () => {
        bintu.createInviteToken()
            .then((response) => {
                if (response.success) {
                    let inviteUrl = `${link.BASE}${link.INVITE_TOKEN}/${response.data.token}`
                    setInviteToken(inviteUrl)
                }
            }).catch((error) => {
                if (!error.success) {
                    setInviteToken("");
                    setError(error);
                    setShowError(true);
                }
            });
    }

    useEffect(() => {
        if (props.open) {
            createNewToken();
        }
    }, [props.open])

    return (
        <Fragment>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <ContentDialog
                open={props.open}
                hideCancel
                submit={props.handleClose}
                close={props.handleClose}
                submitButton={"Close"}
                title="Invite new user"
                content={
                    <Grid container mb={1}>
                        <Typography variant="body2">
                            Share this URL, including the invite token, with a person you want to become part of your organization.
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                sx={{ width: '80%' }}
                                className={classes.textfield}
                                value={inviteToken}
                                autoComplete="false"
                                variant="standard"
                            />
                            <CopyButton copy={inviteToken} />
                        </Grid>
                    </Grid>
                }
            />
        </Fragment >

    )
}
