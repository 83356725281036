import React, { useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';
import * as link from '../../utils/helper/link-config';

import { makeStyles, styled } from '@mui/styles';
import { TextField, Grid, FormControl, Typography, MenuItem, Button, Switch, FormGroup, FormControlLabel, Collapse } from '@mui/material';
import { CheckCircle, Cloud, CloudDone, DoNotDisturb, DoNotDisturbOn, HighlightOff, TaskAlt, TaskAltOutlined } from '@mui/icons-material';

import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    item: {
        textTransform: 'capitalize'
    }
}));

export default function AddThumbnail(props) {
    const classes = useStyles();
    const [setting, setSetting] = useState({
        process: true, interval: 30
    });

    const handleUpdateInterval = (event) => {
        setSetting({
            ...setting, interval: event.target.value
        });
    }

    const handleBlurredTextfield = () => {
        if (setting.interval === "" || Number(setting.interval) < 10 || Number(setting.interval) > 3600 || /[^0-9]+/.test(setting.interval)) {
            setSetting({
                ...setting, interval: 30
            });
        }
    }

    useEffect(() => {
        props.updateThumbnail(setting);
    }, [setting])


    return (
        <SectionContainer
            title="Set thumbnail generation"
            underline="The range for the interval is between 10s and 3600s."
        >
            <Grid container>
                <Grid item xs={12}>
                    <FormGroup sx={{ mb: 1 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setting.process}
                                    onChange={() => setSetting({ ...setting, process: !setting.process })}
                                />
                            }
                            label={`Thumbnail Generation: ${setting.process ? "Active" : "Inactive"}`}
                        />
                    </FormGroup>
                    <Collapse in={setting.process}>
                        <TextField
                            variant="standard"
                            label="Interval (s)"
                            value={setting.interval}
                            onBlur={handleBlurredTextfield}
                            onChange={handleUpdateInterval}
                        />
                    </Collapse>
                </Grid>
            </Grid>
        </SectionContainer>

    )
}
