import React, { useEffect, useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import darkCode from "react-syntax-highlighter/dist/esm/styles/prism/material-dark";
import lightCode from "react-syntax-highlighter/dist/esm/styles/prism/material-light";

import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { ExpandMore, OpenInNewOutlined } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import SectionContainer from '../global/SectionContainer';
import CopyButton from '../global/CopyButton';
import SectionHeader from '../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        width: '100%',
        boxShadow: 'none !important',
        background: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]} !important`,
    },
    code: {
        fontSize: '12px !important'
    },
    details: {
        padding: theme.spacing(2)
    },
    descriptionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'

    }
}));

export default function CustomTokenCode(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [codeSnippet, setCodeSnippet] = useState("");

    const handleOpenInNew = () => {
        window.open(link.H5LIVE_SECURITY, '_blank');
    }

    const generateCodeSnippet = () => {
        setCodeSnippet(`"security": {
    "jwtoken": ${props.token}
}`)
    }

    useEffect(() => {
        generateCodeSnippet();
    }, [])

    return (
        <Accordion className={classes.root} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <SectionHeader
                    title={props.title}
                    interact
                    button="Learn more"
                    icon={<OpenInNewOutlined />}
                    clicked={handleOpenInNew}
                />
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div className={classes.descriptionWrapper}>
                    <Typography variant="body1" color="textSecondary">
                        {props.underline}
                    </Typography>
                    <CopyButton copy={codeSnippet} />
                </div>
                <code>
                    <pre id={"code-snippet"}>
                        <SyntaxHighlighter className={classes.code} showLineNumbers language="js" style={theme.palette.mode === 'light' ? lightCode : darkCode}>
                            {codeSnippet}
                        </SyntaxHighlighter>
                    </pre>
                </code>
            </AccordionDetails>
        </Accordion>
    )
}
