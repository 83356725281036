import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, IconButton, Stack, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Close, OpenInFull, OpenWith, QueryStats } from '@mui/icons-material';

export default function PopUpOverlay(props) {
    const { data, close, open } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        position: 'absolute', zIndex: 1, m: 1,
                        bgcolor: theme.palette.primary.main, borderRadius: theme.spacing(2),
                        p: 1, border: 2, borderColor: theme.palette.common.white,
                        backgroundImage: `linear-gradient(to bottom, transparent, ${alpha(theme.palette.primary.light, 0.9)})`,
                        display: 'inline-block',

                    }}
                    component="div"
                >
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} spacing={1}>
                        <Typography variant="body1" color="white" sx={{ fontWeight: 700 }}>
                            {data?.country}
                        </Typography>
                        <IconButton size="small" onClick={close}>
                            <Close fontSize="inherit" sx={{ color: theme.palette.common.white }} />
                        </IconButton>
                    </Stack>
                    <Typography variant="body2" color="white">
                        <b>Ingest</b>: {data?.ingest?.count}
                    </Typography>
                    <Typography variant="body2" color="white">
                        <b>Playout</b>: {data?.playout?.count}
                    </Typography>
                    <Button
                        startIcon={<QueryStats />} color="secondary" onClick={open}
                        sx={{ mt: 1 }} size="small" variant="outlined"
                    >
                        See More Details
                    </Button>
                </Box>
            </Grid>
        </Grid >
    )
}
