import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';

import * as ls from '../../utils/helper/ls-vars';

import { ColorModeContext } from './color-context';

import Layout from './Layout';
import Router from './Router';

export default function App(props) {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
    const choosenMode = sessionStorage.getItem(ls.THEME);
    const [mode, setMode] = useState(choosenMode ? choosenMode : (prefersDarkMode ? "dark" : "light"));

    const generateClassName = createGenerateClassName({
        productionPrefix: 'nanoStream-cloud',
        seed: 'nanoStream-cloud'
    });

    const theme = useMemo(() => responsiveFontSizes(createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#ed7d0e',
            },
            secondary: {
                main: '#020305',
                light: '#001F3A'
            },
            background: {
                default: mode === "dark" ? '#121212' : '#f4f4f4'
            },
        },
        typography: {
            fontFamily: [
                'Source Sans Pro',
            ],
            h1: {
                fontWeight: 600,
            },
            h3: {
                fontWeight: 700,
            },
            // h4: {
            //   fontWeight: 700,
            //   fontFamily: ['Source Code Pro'],
            //   textTransform: 'uppercase',
            //   fontSize: '24px'
            // },
            h4: {
                fontWeight: 700,
                fontSize: '24px',
                // textTransform: 'uppercase'
            },
            h5: {
                fontWeight: 700,
                fontSize: '20px',
                // textTransform: 'uppercase'
            },
            overline: {
                lineHeight: 1
            }
        },
        components: {
            MuiChip: {
                styleOverrides: {
                    colorPrimary: {
                        background: '#ed7d0e4D',
                        color: '#ed7d0e',
                        fontWeight: 600
                    },
                    outlinedPrimary: {
                        background: 'transparent',
                        border: '1px solid #ed7d0e',
                    },
                    clickableColorPrimary: {
                        '&:hover': {
                            backgroundColor: '#ed7d0e',
                            color: '#ffffff',
                        }
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 600,
                        textTransform: 'inherit',
                        borderRadius: '10px',
                        boxShadow: 'none !important',
                    },
                    containedPrimary: {
                        backgroundColor: '#ed7d0e',
                        color: '#ffffff',
                        '&:hover': {
                            boxShadow: 'none',
                        }
                    },
                    containedSecondary: {
                        backgroundColor: '#ed7d0e33',
                        color: '#ed7d0e',
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#ed7d0e66',
                            color: '#ed7d0e',
                        }
                    },
                    outlinedPrimary: {
                        borderColor: '#ed7d0e',
                        color: '#ed7d0e',
                    },
                    outlinedSecondary: {
                        borderColor: mode === "dark" ? '#fff' : '#3e2516',
                        color: mode === "dark" ? '#fff' : '#3e2516',
                        '&:hover': {
                            borderColor: '#3e251690'
                        }
                    }
                },
            },
        }
    })), [mode]);

    const colorMode = useMemo(() => ({
        toggleColorMode: (theme) => {
            if (theme === "auto") {
                sessionStorage.removeItem(ls.THEME);
                setMode(prefersDarkMode ? "dark" : "light");
            }
            if (theme === "light" || theme === "dark") {
                sessionStorage.setItem(ls.THEME, theme);
                setMode(theme);
            }
        }
    }));

    console.log(theme)

    return (
        <ColorModeContext.Provider value={colorMode}>
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Layout>
                            <Router />
                        </Layout>
                    </BrowserRouter>
                </ThemeProvider>
            </StylesProvider>
        </ColorModeContext.Provider>
    )
}
