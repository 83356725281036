import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import { alpha, Button, Collapse, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { CheckCircleOutline, KeyboardArrowUp, LocalOfferOutlined, SlideshowOutlined } from '@mui/icons-material';

import DateFilter from './DateFilter';
import AddTags from '../create/AddTags';
import Search from './Search';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    state: {
        padding: theme.spacing(0.2, 1),
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1)
        },
        borderRadius: '5px',
        textAlign: 'center',
        display: 'inline-block',
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
            [theme.breakpoints.up('sm')]: {
                transform: 'scale(1.05)'
            }
        }
    },
    stateLabel: {
        textTransform: 'uppercase',
        fontWeight: '600 !important'
    },
    all: {
        // backgroundColor: alpha(theme.palette.primary.main, 0.2),
        backgroundColor: 'transparent',
        // color: theme.palette.primary.main,
        // borderColor: theme.palette.primary.main,

    },
    live: {
        backgroundColor: alpha(theme.palette.success.main, 0.2),
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
    },
    ended: {
        backgroundColor: alpha(theme.palette.error.dark, 0.2),
        color: theme.palette.error.dark,
        borderColor: theme.palette.error.dark
    },
    created: {
        backgroundColor: alpha(theme.palette.info.main, 0.2),
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main
    },
    deleted: {
        backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.2) : alpha(theme.palette.common.white, 0.2),
        color: theme.palette.common.black,
        borderColor: theme.palette.common.black
    },
    locked: {
        backgroundColor: alpha(theme.palette.text.secondary, 0.2),
        color: theme.palette.text.secondary
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

export default function Filter(props) {
    const classes = useStyles();
    const [filter, setFilter] = useState(props.filter);
    const [applyTags, setApplyTags] = useState(false);
    const [fetchStreamgroup, setFetchStreamgroup] = useState(props.fetchStreamgroup);
    const states = ["all", "live", "created", "ended", "deleted", "locked"];

    const handleUpdateDates = ({ from, until }) => {
        setFilter({ ...filter, from, until });
    }

    const handleUpdateState = (event) => {
        const newState = event.target.textContent;
        setFilter({
            ...filter,
            state: newState
        });
    }

    const handleUpdateTagList = (tagList) => {
        setFilter({
            ...filter,
            tags: tagList
        });
    };

    useEffect(() => {
        if (props.fetchStreamgroup !== fetchStreamgroup) setFetchStreamgroup(props.fetchStreamgroup)
    }, [props.fetchStreamgroup])

    useEffect(() => {
        props.updated(filter);
    }, [filter])

    return (
        <Grid
            container
            className={classes.root}
            direction="row"
            alignItems="center"
            pb={1}
        >
            <Grid
                item
                xs={12}
                md={"auto"}
                order={{ xs: 2, md: 1 }}
                sx={{
                    display: { xs: 'flex', md: 'inherit' },
                    justifyContent: 'center',
                }}
            >
                <DateFilter
                    from={filter.from}
                    until={filter.until}
                    update={handleUpdateDates}
                />
                <Button
                    size="small"
                    sx={{ ml: 1 }}
                    variant={!fetchStreamgroup ? "contained" : "outlined"}
                    startIcon={!fetchStreamgroup ? <CheckCircleOutline /> : <SlideshowOutlined />}
                    onClick={props.handleFetchStreamgroup}
                >
                    Show all streams
                </Button>
                <Button
                    size="small"
                    sx={{ ml: 1 }}
                    variant={applyTags ? "contained" : "outlined"}
                    startIcon={applyTags ? <KeyboardArrowUp /> : <LocalOfferOutlined />}
                    onClick={() => { setApplyTags(!applyTags) }}
                >
                    Filter by tags
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                mt={applyTags ? 1 : 0}
                order={{ xs: 3 }}
            >
                <Collapse in={applyTags}>
                    <AddTags
                        unsetRoot
                        updateTagList={handleUpdateTagList}
                    />
                </Collapse>
            </Grid>
            <Grid
                item
                xs={12}
                md={"auto"}
                order={{ xs: 1, md: 2 }}
                sx={{
                    display: { xs: 'flex', md: 'inherit' },
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    mb: { xs: 0.5, md: 0 },
                    ml: { xs: 0, md: 1 },
                }}
            >
                {
                    states.map((state) => (
                        <div
                            key={state}
                            onClick={handleUpdateState}
                            className={clsx(classes.state,
                                classes[state], {
                                [classes.active]: filter.state === state
                            })}
                        >
                            <Typography variant="button" className={classes.stateLabel} >
                                {state}
                            </Typography>
                        </div >
                    ))
                }
            </Grid>
        </Grid>
    )
}
