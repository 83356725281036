import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Button, Chip, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { CalendarMonthOutlined, CancelOutlined, CheckCircle, CheckCircleOutline, HighlightOff, LockOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

import State from '../global/State';
import CopyButton from '../global/CopyButton';

export default function PlayerUnderline(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { stream, isTranscode, version, isSecure } = props;
    const [playerState, setPlayerState] = useState(props.playerState);

    const handleClickedFullStreamInfo = () => {
        navigate(`${link.STREAM}/${stream.id}`);
    }

    useEffect(() => {
        setPlayerState(props.playerState);
    }, [props.playerState])


    return (
        <Stack
            direction="row" spacing={.8} useFlexGap flexWrap={"wrap"} alignItems={"center"}
            divider={<Typography variant="body1" color="textSecondary">&#8226;</Typography>}
        >
            <State state={stream.state} />
            <Chip size="small" variant="outlined" label={`PLAYER VERSION ${version}`} />
            <Tooltip title="Created at">
                <Typography variant="subtitle2" color="textSecondary">
                    <CalendarMonthOutlined sx={{ fontSize: 12 }} /> {moment(stream.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                </Typography>
            </Tooltip>
            {
                isTranscode
                    ? <Chip
                        size="small"
                        icon={<CheckCircle />}
                        label={`Streamgroup`}
                        variant="outlined"
                        color="primary"
                    />
                    : <Chip
                        size="small"
                        icon={<HighlightOff />}
                        label={`No streamgroup`}
                        color="default"
                    />
            }
            {
                isSecure &&
                <Chip
                    size="small" color="info"
                    icon={<LockOutlined />}
                    label={"Secure Stream"}
                    variant="contained"
                />
            }
        </Stack>

    )
}
