/* global BigInt */
import { useMemo } from 'react';
import { getTokenFromLocalStorage } from '../bintu/api-requests';
import { DECODE_TOKEN } from './methods';
import permissionHelper from '../permissions/permission_utils';

const usePermission = (requiredPermission) => {
    let token = getTokenFromLocalStorage();
    let decodedToken = DECODE_TOKEN(token);
    const grantedPermissions = decodedToken ? BigInt(decodedToken?.permissions?.bintu) : 0n;

    // const grantedPermissions = 0n;

    return useMemo(() => {
        return permissionHelper.checkPermission(requiredPermission, grantedPermissions);
    }, [requiredPermission]);
};

const useAudience = (requiredAudience) => {
    let token = getTokenFromLocalStorage();
    let decodedToken = DECODE_TOKEN(token);

    return useMemo(() => {
        if (!decodedToken || !decodedToken.aud) return false;
        return decodedToken.aud.includes(requiredAudience);
    }, [requiredAudience]);
};

export { usePermission, useAudience };