import { ManageAccounts, Person, Visibility } from "@mui/icons-material"

export const OPTIONS = [
    { id: "trial", title: "Trial" },
    { id: "vod", title: "VOD" },
    { id: "webrtc", title: "Webcaster" },
    { id: "transcoding", title: "Transcoding" },
    { id: "h5live", title: "H5Live" },
    { id: "hls", title: "HLS" },
    { id: "secure", title: "Secure" },
    { id: "streamguard", title: "Streamguard" },
    { id: "metadata", title: "Metadata" },
    { id: "meetEnabled", title: "nanoMeet" },
    { id: "customStreamnames", title: "Custom Streamnames" }
]

export const SUPPORT = [
    { id: "8/5 Best Effort", title: "8/5 Best Effort" },
    { id: "8/5 Pro Support", title: "8/5 Pro Support" },
    { id: "24/7", title: "24/7" },
]

export const METRICS = [
    { id: "basic", title: "Basic" },
    { id: "full", title: "Full" },
    { id: "premium", title: "Premium" },
]

export const OPCODES = [
    { id: "rec", title: "Record" },
    { id: "thumbs", title: "Thumbnails" },
    { id: "none", title: "None" }
]

export const ACCESS_LEVEL_NANO = {
    nanoReadOnly: { id: "nanoReadOnly", title: "Read Only", icon: <Visibility />, color: "info" },
    nanoUser: { id: "nanoUser", title: "User", icon: <Person />, color: "success" },
    nanoAdmin: { id: "nanoAdmin", title: "Admin", icon: <ManageAccounts />, color: "error" }
}