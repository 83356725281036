import React, { Fragment, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import { Grid, Tooltip, Typography, Button, IconButton, Menu, ListItemIcon, MenuItem } from '@mui/material';
import { ArrowRightAlt, Download, Info, InfoOutlined, MoreHoriz, RefreshOutlined } from '@mui/icons-material';

import SmallTimeRangeFilter from '../filter/SmallTimeRangeFilter';
import SmallCountryRangeFilter from '../filter/SmallCountryRangeFilter';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    container: {
        margin: '0 !important',
        padding: '0 !important'
    },
    root: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap'
        },
    },
    title: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        lineHeight: 1.1,
        marginRight: `${theme.spacing(2)} !important`,
        // textTransform: 'capitalize'
    },
    smallTitle: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        lineHeight: 1.1,
        fontWeight: '700 !important',
        marginRight: `${theme.spacing(2)} !important`,
    },
    backgroundIsOrange: {
        color: theme.palette.common.white
    },
    light: {
        color: theme.palette.primary.light
    },
}));

export default function AnalyticsHeader(props) {
    const classes = useStyles();
    // const [reportAnchor, setReportAnchor] = useState(null);
    // const open = Boolean(reportAnchor);

    // const handleOpenReportOption = (event) => {
    //     setReportAnchor(event.currenTarget)
    // }


    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.root}>
                <Typography
                    variant={props.small ? "body1" : "h4"}
                    className={clsx({
                        [classes.backgroundIsOrange]: props.orange,
                        [classes.smallTitle]: props.small,
                        [classes.title]: !props.small,
                        [classes.light]: props.light,
                    })}>
                    {props.title}
                    {
                        props.info
                        &&
                        <Tooltip title={props.info}>
                            <IconButton size="small" sx={{ ml: 1 }}>
                                <InfoOutlined color="primary" fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    }
                </Typography>
                <Grid container sx={{ width: 'auto', alignItems: 'center' }}>
                    <Grid item mr={.5}>
                        {
                            props.smallTimeRangeFilter
                            && <SmallTimeRangeFilter updateFilter={props.updateTime} />
                        }
                    </Grid>
                    <Grid item>
                        {
                            props.smallCountryRangeFilter
                            && <SmallCountryRangeFilter updateFilter={props.updateCountry} />
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                props.underline
                &&
                <Grid item xs={12}>
                    <Typography
                        variant={props.small ? "body2" : "body1"}
                        color="textSecondary"
                    >
                        {props.underline}
                    </Typography>

                </Grid>
            }
        </Grid >
    )
}
