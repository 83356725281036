import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import * as link from '../../utils/helper/link-config';
import * as m from '../../utils/helper/methods';

export default function Logout() {
    const navigate = useNavigate();

    document.title = "Logging out... | nanoStream Cloud Dashboard"

    const checkAccess = () => {
        if (!m.CHECK_ACCESS()) {
            navigate(link.AUTH)
        }
    }

    useEffect(() => {
        sessionStorage.clear();
        window.dispatchEvent(new Event("storage"));

        navigate(link.AUTH)
    }, [])

    useEffect(() => {
        checkAccess();
        window.addEventListener('storage', checkAccess)
    }, [])


    return <div />;
}
