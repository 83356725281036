import React from 'react';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { alpha, fade, IconButton, Typography } from '@mui/material';
import { PlayCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.2, 0.8),
        borderRadius: '5px',
        textAlign: 'center',
        display: 'inline-block',
        marginRight: theme.spacing(0.5),
    },
    center: {
        display: 'flex', alignItems: 'center',
        justifyContent: 'center'
    },
    stateLabel: {
        fontWeight: 700,
        textTransform: 'uppercase',
        fontWeight: '600 !important'
    },
    live: {
        backgroundColor: alpha(theme.palette.success.main, 0.2),
        color: theme.palette.success.main
    },
    ended: {
        backgroundColor: alpha(theme.palette.error.dark, 0.2),
        color: theme.palette.error.dark
    },
    created: {
        backgroundColor: alpha(theme.palette.info.main, 0.2),
        color: theme.palette.info.main
    },
    deleted: {
        backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.2) : alpha(theme.palette.common.white, 0.2),
        color: theme.palette.common.black
    },
    locked: {
        backgroundColor: alpha(theme.palette.text.secondary, 0.2),
        color: theme.palette.text.secondary
    },
}));

export default function State(props) {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleOpenPlayout = () => {
        navigate(`${link.PLAYOUT}/${props.streamid}`)
    }

    return (
        <Box
            className={clsx({ [classes.center]: props.center })}
        >
            <div className={clsx(classes.root, classes[props.state])}>
                {/* <div className={clsx(classes.state, classes[props.state])} /> */}
                <Typography variant="button" className={classes.stateLabel} >
                    {props.state}
                </Typography>
            </div>
            {
                props.state === "live" && props.streamid
                &&
                <IconButton size="small" onClick={handleOpenPlayout}>
                    <PlayCircleOutline color="primary" />
                </IconButton>
            }
        </Box>
    );
}