import React, { useEffect, useState } from 'react';

import * as ls from '../../../utils/helper/ls-vars';
import * as bintu from '../../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Checkbox, Collapse, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import AddTags from '../../create/AddTags';
import SectionContainer from '../../global/SectionContainer';
import SnackbarMessage from '../../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
}));

export default function UpdatePushUrl(props) {
    const classes = useStyles();
    const [pushUrl, setPushUrl] = useState(props.pushUrl);
    const [showTextfield, setShowTextfield] = useState(true);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdateTextfield = (event) => {
        setPushUrl(event.target.value);
    }

    const handleSetCheckbox = () => {
        setShowTextfield(!showTextfield);
    }

    const handleUpdatePushUrl = () => {
        let data = {
            streamid: props.streamid,
            pushUrl: !showTextfield ? null : pushUrl
        }

        bintu.setPushUrl(data)
            .then((response) => {
                if (response.success) {
                    let newUrl = response.data.pushUrl;
                    props.updated(newUrl)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <Collapse in={showTextfield}>
                    <TextField
                        sx={{
                            mt: 1
                        }}
                        variant="standard"
                        className={classes.textfield}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        color="primary"
                        label={"Push Url"}
                        value={pushUrl}
                        onChange={handleUpdateTextfield}
                    />
                </Collapse>
                <FormControlLabel
                    sx={{ display: 'block' }}
                    value={showTextfield}
                    control={<Checkbox />}
                    label={"Empty Push Url"}
                    onChange={handleSetCheckbox}
                />
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleUpdatePushUrl}
                >
                    Update
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )
}
