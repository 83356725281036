import { CameraOutlined, ComputerOutlined, GridView, PieChartOutlined, PlayCircleOutlineOutlined, PublicOutlined } from '@mui/icons-material';

import * as link from './link-config';

export const SUPPORT = {
    DASHBOARD: {
        title: "nanoStream Cloud Dashboard",
        underline: "The nanoStream Cloud Dashboard is a web-based tool implemented and designed to provide users with an intuitive and comprehensive overview of their streaming activities. It serves as a centralized hub to monitor and manage the users live video streaming workflow.",
        icon: <ComputerOutlined />,
        link: link.DASHBOARD_OVERVIEW,
    },
    CLOUD: {
        title: "nanoStream Cloud API",
        underline: "nanoStream Cloud is our unique live streaming platform based on different components for ultra low latency live streaming.",
        icon: <PublicOutlined />,
        link: link.NSC_INTRO,
        links: [
            {
                title: "Introduction",
                link: link.NSC_INTRO,
                description: "Learn more about bintu, the stream management dashboard and api.",
            },
            {
                title: "Getting Started",
                link: link.NSC_GETTING_STARTED,
                description: "Watch our introduction videos and tutorials for step-by-step instructions!",

            },
            {
                title: "nanoStream Cloud API",
                link: link.NSC_API,
                description: "Have a look at our API and how to use bintu.",
            },
            {
                title: "Latest News about the nanoStream Cloud",
                link: link.NSC_NEWS,
                description: "Keep up to date with nanoStream producs and technology updates!",

            },
            {
                title: "How to use the nanoStream Cloud Dashboard",
                link: link.NSC_HOW_TO,
                description: "Learn more about our nanoStream Cloud dashboard that improves your streaming workflow.",

            }
        ]
    },
    H5LIVE: {
        title: "nanoStream H5Live Player",
        underline: "nanoStream H5Live is our client-server technology based on nanoStream Cloud and nanoStream H5Live Player (aka nanoPlayer). It is the perfect playback solution for live video streaming in all HTML5 web browsers, with Ultra-Low latency live streaming (around 1 second end-to-end, glass-to-glass). The future-proof, plugin-free implementation enables a lot of exciting use cases.",
        icon: <PlayCircleOutlineOutlined />,
        link: link.H5LIVE_DOCS,
        links: [
            {
                title: "Introduction",
                link: link.H5LIVE_DOCS,
                description: "Learn more about the plugin-free H5Live player."
            },
            {
                title: "Getting Started",
                link: link.H5LIVE_GETTING_STARTED,
                description: "How to implement the H5live player on your web page."
            },
            {
                title: "nanoStream H5Live Player API",
                link: link.H5LIVE_API,
                description: "Have a look at our developer API and how to implement the player easily on your web page."
            },
            {
                title: "Latest Release",
                link: link.H5LIVE_LATEST_RELEASE,
                description: "Every time we implement new features you can find them here!"
            },
            {
                title: "Latest News about the nanoStream H5Live Player",
                link: link.H5LIVE_NEWS,
                description: "Learn more about our H5Live Player on our blog."
            }
        ]
    },
    METRICS: {
        title: "nanoStream Analytics",
        underline: "nanoStream Cloud Analytics offers a great way to monitor and analyze the performance and quality of service of your live streams.",
        icon: <PieChartOutlined />,
        link: link.METRICS_DOCS,
        links: [
            {
                title: "General Information",
                link: link.METRICS_DOCS,
                description: "Learn how to monitor and analyze the performance and quality of service of your live streams."
            },
            {
                title: "nanoStream Analytics API",
                link: link.METRICS_API_DOC,
                description: "Use our analytics api to directly access the data."
            },
            {
                title: "Latest News about the nanoStream Analytics",
                link: link.METRICS_NEWS,
                description: "Read more about the nanoStream Analytics in our blog."
            }
        ]
    },
    WEBCASTER: {
        title: "nanoStream Webcaster",
        underline: "nanoStream Webcaster works as a powerful live encoder from the broadcaster/publisher side, ingesting directly into nanoStream Cloud.",
        icon: <CameraOutlined />,
        link: link.WEBCASTER_INTRO,
        links: [
            {
                title: "Introduction",
                link: link.WEBCASTER_INTRO,
                description: "Learn how to build a browser-based live streaming workflow with nanoStream Webcaster and nanoStream Cloud."
            },
            {
                title: "Getting Started",
                link: link.WEBCASTER_GETTING_STARTED,
                description: "Get to know how to use nanoStream Webcaster with nanoStream Cloud."
            },
            {
                title: "nanoStream Webcaster API",
                link: link.WEBCASTER_API,
                description: "Use the nanoStream Webcaster API to implement it on your web page."
            },
            {
                title: "Latest News about the nanoStream Webcaster",
                link: link.WEBCASTER_NEWS,
                description: "Read more about the nanoStream Webcaster in our blog."
            }
        ]
    }
}
