import React from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@mui/styles';
import { alpha, Box, Grid, Typography } from '@mui/material';

import SectionHeader from './SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        background: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    mb: {
        marginBottom: theme.spacing(2)
    },
    noMargin: {
        marginTop: 0
    },
    contrast: {
        background: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
    },
    contrastBorder: {
        background: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
    },
    orange: {
        backgroundColor: theme.palette.common.white,
        backgroundImage: `linear-gradient(to bottom, 
            ${alpha(theme.palette.primary.main, 0.9)},
            ${alpha(theme.palette.primary.main, 1.0)})`,
        // background: alpha(theme.palette.primary.main, 0.4),
        // borderLeft: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
    }
}));

export default function SectionContainer(props) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div
            className={clsx({
                [classes.root]: !props.unsetRoot,
                [classes.orange]: props.orange,
                [classes.contrast]: props.contrast,
                [classes.contrastBorder]: props.contrastBorder,
                [classes.noMargin]: props.noMargin,
                [classes.mb]: props.mb
            })}
            style={{
                ...props.extraStyle,
            }}
        >
            {props.title && <SectionHeader {...props} />}
            {/* <Box
                sx={{
                    ...props.scroll && { maxHeight: { sm: props.scrollHeight || 'auto' } },
                    overflow: "auto",
                }}> */}
            {props.children}
            {/* </Box> */}
        </div >

    )
}
