import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    dateWrapper: {
        marginTop: theme.spacing(1)
    }
}));

export default function TokenDate(props) {
    const classes = useStyles();
    const [value, setValue] = useState(props.default);


    const handleUpdateDate = (newDate) => {
        setValue(newDate);
    }

    useEffect(() => {
        props.updateDate({ [props.id]: value });
    }, [value])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>

                <SectionContainer {...props}>
                    <div className={classes.dateWrapper}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                value={value}
                                onChange={handleUpdateDate}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </SectionContainer>
            </Grid>
        </Grid>

    )
}
