import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useTheme } from '@mui/styles';
import { Box, Grid, IconButton, InputAdornment, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, alpha } from '@mui/material';
import { Visibility, VisibilityOff, WarningRounded, WarningSharp } from '@mui/icons-material';

import { DECODE_TOKEN } from '../../utils/helper/methods';
import { BINTU_APIKEY, BINTU_TOKEN } from '../../utils/helper/ls-vars';

import SectionHeader from '../global/SectionHeader';
import CopyButton from '../global/CopyButton';

export default function KeysOverview(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { orga } = props;
    const token = sessionStorage.getItem(BINTU_TOKEN);
    const apikey = sessionStorage.getItem(BINTU_APIKEY);
    const decodedToken = DECODE_TOKEN(token);
    const [keys, setKeys] = useState([
        ...(token ? [{ type: "Bintu Token", value: token, exp: decodedToken.exp, show: false }] : []),
        ...(apikey ? [{ type: "Api Key", value: apikey, exp: false, show: false }] : []),
        ...((orga && orga?.playerKey) ? [{ type: "Player Key", value: orga.playerKey, exp: false, show: false }] : [])

    ]);

    const handleClickShowPassword = (index) => () => {
        setKeys(prevKeys => {
            const updatedKeys = [...prevKeys];
            updatedKeys[index].show = !updatedKeys[index].show;
            return updatedKeys;
        });
    };

    return (
        <Grid container>
            <Grid item xs={12} p={2} sx={{ borderRadius: theme.spacing(2), bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900] }}>
                <SectionHeader
                    title="API Overview"
                />
                <Box sx={{ px: 2, py: 1.5, mt: 1, borderRadius: 3, bgcolor: alpha(theme.palette.primary.main, 0.1), border: `1px solid ${theme.palette.primary.main}` }}>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Security disclaimer
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        API keys are sensitive information and should be treated with the highest security precautions. Do not share your API keys in publicly accessible areas such as GitHub, client-side code, or forums. Always keep your keys secure and restrict their usage to the minimum required scope. {/*  For more information, please refer to our <Link href="#" underline="hover">security guidelines</Link>. */}
                    </Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
                        Security Keys
                    </Typography>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {
                                        ["Type", "Expiration", "Value", ""].map((th, i) =>
                                            <TableCell>
                                                <Typography variant="button" color="textSecondary">
                                                    {th}
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    keys.map((k, i) => (
                                        <TableRow key={k.type}>
                                            <TableCell>
                                                <Typography variant="body2">
                                                    {k.type}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    k.exp
                                                        ?
                                                        <Typography variant="body2" color="textSecondary" sx={{ lineHeight: 1 }}>
                                                            {moment.unix(k.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)')}
                                                        </Typography>
                                                        :
                                                        <Typography variant="body2" color="error" sx={{ lineHeight: 1, fontStyle: 'italic' }}>
                                                            Has no expiration
                                                        </Typography>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    variant="standard"
                                                    type={keys[i].show ? 'text' : 'password'}
                                                    value={k.value}
                                                    autoComplete="off"
                                                    InputProps={{ disableUnderline: true }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={handleClickShowPassword(i)}
                                                    >
                                                        {keys[i].show ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                                                    </IconButton>
                                                    <CopyButton iconButton copy={k.value} />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid >
        </Grid >
    )
}
