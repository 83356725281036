import React, { useEffect, useState } from 'react';

import * as ls from '../../../utils/helper/ls-vars';
import * as bintu from '../../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import AddTags from '../../create/AddTags';
import SectionContainer from '../../global/SectionContainer';
import SnackbarMessage from '../../global/SnackbarMessage';
import ContentTable from '../../global/ContentTable';
import SectionHeader from '../../global/SectionHeader';
import Profile from './Profile';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function AddProfile(props) {
    const classes = useStyles();
    const [streamid, setStreamid] = useState(props.streamid);
    const [newProfile, setNewProfile] = useState("");
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleSelectedNewProfile = (profile) => {
        setNewProfile(profile);
    }

    const handleUpdateProfile = () => {

        let data = {
            streamid: streamid,
            profile: newProfile.name
        }

        bintu.addTranscodingProfile(data)
            .then((response) => {
                if (response.success) {
                    props.handleUpdateTranscode(response.data.playout.rtmp)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <Profile addProfile={handleSelectedNewProfile} />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleUpdateProfile}
                >
                    Add
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )
}
