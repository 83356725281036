import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../../utils/helper/link-config';

import { useTheme } from '@emotion/react';
import { alpha, Avatar, Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { AccountCircleOutlined, HelpOutline, Logout, NewReleases, PersonAddOutlined, SettingsOutlined } from '@mui/icons-material';

import InviteNewUser from '../organisation/InviteNewUser';
import { usePermission } from '../../utils/helper/hooks';
import permissionConfig from '../../utils/permissions/permission_config';


export default function ProfileMenu(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const canInvite = usePermission(permissionConfig.permissionMasks.inviteToken.create);
    const [dialog, setDialog] = useState({
        invite: false
    });

    const [orgaHelper, setOrgaHelper] = useState([
        { title: "My Organisation", link: link.USER, icon: <AccountCircleOutlined fontSize="small" /> },
        ...(canInvite ? [{ title: "Invite new user", link: false, open: "invite", icon: <PersonAddOutlined fontSize="small" /> }] : []),
    ]);

    const [menu] = useState([
        { title: "About", link: link.ABOUT, icon: <SettingsOutlined fontSize="small" /> },
        { title: "Help Center", link: link.SUPPORT, icon: <HelpOutline fontSize="small" /> },
        { title: "Logout", link: link.LOGOUT, icon: <Logout fontSize="small" /> },
    ]);

    const openInNew = (link) => () => {
        window.open(link, '_target')
    }

    const handleOpen = (link, open) => () => {
        if (link) {
            navigate(link);
            props.clicked();
        }
        if (open) {
            setDialog({
                ...dialog,
                [open]: !dialog[open]
            });
            if (dialog[open]) props.clicked();
        }
    }

    return (
        <Fragment>
            <InviteNewUser
                open={dialog.invite}
                handleClose={handleOpen(false, "invite")}
            />
            <Menu
                disableScrollLock
                hideBackdrop
                anchorEl={props.anchorEl}
                id="account-menu"
                open={props.open}
                onClose={props.clicked}
                onClick={props.clicked}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
                        overflow: 'visible',
                        mt: 1,
                        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    orgaHelper.map((item) => (
                        <MenuItem
                            onClick={handleOpen(item.link, item.open)}
                            key={item.title}
                            sx={{
                                color: `${theme.palette.primary.main} !important`,
                                textDecoration: 'none',
                                padding: theme.spacing(1, 1),
                                borderRadius: theme.spacing(0.5),
                                margin: theme.spacing(1),
                                '&:hover': {
                                    background: `${alpha(theme.palette.primary.main, 0.2)} !important`,
                                    color: theme.palette.primary.main
                                }
                            }}
                        >
                            <ListItemIcon sx={{ color: 'primary.main' }}>
                                {item.icon}
                            </ListItemIcon>
                            {item.title}
                        </MenuItem>
                    ))
                }
                {
                    props.offerUpgrade
                    &&
                    <MenuItem
                        onClick={openInNew(link.BUY)}
                        sx={{
                            color: 'info.main',
                            '&:hover': {
                                background: `${alpha(theme.palette.info.main, 0.2)} !important`,
                            }
                        }}
                    >
                        <ListItemIcon sx={{ color: 'info.main' }}>
                            <NewReleases />
                        </ListItemIcon>
                        Upgrade to Premium
                    </MenuItem>
                }
                <Divider />
                {
                    menu.map((item) => (
                        <MenuItem
                            onClick={handleOpen(item.link)}
                            key={item.title}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            {item.title}
                        </MenuItem>
                    ))
                }
            </Menu>
        </Fragment>
    )
}
