import React, { Fragment, useEffect, useState } from 'react';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { ArrowForwardIosSharp, OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/system';

import SectionContainer from '../global/SectionContainer';
import SectionHeader from '../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    accordionRoot: {
        width: '100%',
        boxShadow: 'none !important',
        background: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]} !important`,
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        }
    },
}));

export default function LatencyControlMode(props) {
    const classes = useStyles();

    const [latencyControlMode] = useState([
        { id: "classic", title: "Classic" },
        { id: "balancedadaptive", title: "Balanced Adaptive" },
        { id: "fastadaptive", title: "Fast Adaptive" },
    ]);
    const [selectedLatencyControlMode, setSelectedLatencyControlMode] = useState(props.latencyControlMode);
    const [updateSetup, setUpdateSetup] = useState({ is: false, id: latencyControlMode[1].id });

    const handleUpdateLatencyControlMode = (event) => {
        let newLatencyControlMode = event.target.value;
        setUpdateSetup({ mode: event.target.value, is: true });
        setSelectedLatencyControlMode(newLatencyControlMode);
    }

    const handleUpdateSetup = () => {
        props.updateLatencyControlMode(selectedLatencyControlMode);
        setUpdateSetup({ ...updateSetup, is: false });
    }

    const handleLeanMore = (link) => () => {
        window.open(link, '_blank');
    }

    return (
        <Accordion className={classes.accordionRoot}>
            <AccordionSummary
                sx={{ flexDirection: 'row-reverse', alignItems: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
                expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
            >
                <Typography variant="body1" ml={1}>
                    Latency Control:&nbsp;<b>{selectedLatencyControlMode}</b>&nbsp;
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <SectionHeader
                    small
                    title="Latency Control Mode"
                    // underline="This is the exact description of latency control mode"
                    button="Learn more"
                    interact
                    icon={<OpenInNew />}
                    clicked={handleLeanMore(link.DOCS_LATENCY_CONTROL)}
                />
                <FormControl fullWidth>
                    <InputLabel variant="standard">Latency Control Mode</InputLabel>
                    <Select
                        variant="standard"
                        className={classes.select}
                        label={"Latency Control Mode"}
                        value={selectedLatencyControlMode}
                        onChange={handleUpdateLatencyControlMode}
                    >
                        {
                            latencyControlMode.map((mode, i) => (
                                <MenuItem value={mode.id} key={mode.id}>
                                    <Typography variant="body2" className={classes.menuItem} >
                                        {mode.title}
                                    </Typography >
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Collapse in={updateSetup.is}>
                    <SectionContainer
                        orange
                        small
                        title="Update the Latency Control Mode"
                        underline={`If you click "OK" the player will be updated to the "${updateSetup.mode}" mode.`}
                    >
                        <Button
                            sx={{ mt: 1 }}
                            variant="contained"
                            color="primary"
                            onClick={handleUpdateSetup}
                        >
                            Ok
                        </Button>
                    </SectionContainer>
                </Collapse>
            </AccordionDetails>
        </Accordion>
    )
}
