import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { TextField, Grid, FormControl, Select, MenuItem } from '@mui/material';

import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    item: {
        textTransform: 'capitalize'
    }
}));

export default function AddLiveProcessing(props) {
    const classes = useStyles();
    const [process, setProcess] = useState("none");
    const [options] = useState(["none", "thumbs"])

    const handleUpdateLiveProcessing = (event) => {
        let newProcess = event.target.value;
        setProcess(newProcess);
    }

    useEffect(() => {
        props.updateLiveProcessing(process)
    }, [process])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} mr={2}>
                <SectionContainer noMargin
                    title="Add live processing to your stream"
                >
                    <FormControl mt={2}>
                        <Select
                            className={classes.item}
                            variant="standard"
                            color="primary"
                            value={process}
                            onChange={handleUpdateLiveProcessing}
                        >
                            {
                                options.map((o, i) => (
                                    <MenuItem className={classes.item} key={i} value={o}>
                                        {o}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </SectionContainer>
            </Grid>
        </Grid>

    )
}
