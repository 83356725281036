export const timeDateConverter = {
    lastMonth: {
       start: (now) => now.clone().startOf('month').subtract(1, 'month'),
       end: (now) => now.clone().startOf('month'),
       label: (start) => `${start.format('MMMM')} (total)`,
       refresh: '',
    },
    lastMonthToDate: {
       start: (now) => now.clone().startOf('month').subtract(1, 'month'),
       end: (now) => now.clone().startOf('hour').subtract(1, 'month'),
       label: (start, end) => `${start.format('D MMM')} - ${end.format('D MMM')} at ${end.format('HH:mm')}`,
       refresh: '&refresh=1h',
    },
    currentMonth: {
       start: (now) => now.clone().startOf('month'),
       end: (now) => now.clone().startOf('hour'),
       label: (start, end) => `${start.format('D MMM')} - ${end.format('D MMM')} at ${end.format('HH:mm')}`,
       refresh: '&refresh=1h',
    },
};