import React, { useEffect, useState } from 'react';

import * as ls from '../../../utils/helper/ls-vars';
import * as bintu from '../../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import AddTags from '../../create/AddTags';
import SectionContainer from '../../global/SectionContainer';
import SnackbarMessage from '../../global/SnackbarMessage';
import ContentTable from '../../global/ContentTable';
import SectionHeader from '../../global/SectionHeader';
import Profile from './Profile';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function UpdateProfile(props) {
    const classes = useStyles();
    const [details, setDetails] = useState(props.details);
    const [newProfile, setNewProfile] = useState("");
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleSelectedNewProfile = (profile) => {
        setNewProfile(profile);
    }

    const handleUpdateProfile = () => {

        let data = {
            streamid: details.id,
            newProfile: newProfile.name
        }

        bintu.updateTranscodingProfile(data)
            .then((response) => {
                if (response.success) {
                    let newTranscode = {
                        ...response.data.playout.rtmp[0],
                        id: details.id,
                        tags: details.tags,
                        pushUrl: response.data.pushUrl,
                        info: newProfile.vtrans,
                        index: details.index
                    }
                    props.handleUpdateTranscode(newTranscode)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }


    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <Profile addProfile={handleSelectedNewProfile} />
            </Grid>
            {
                newProfile
                &&
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <SectionContainer
                            contrast
                            small
                            title={`Existing Profile: Settings for Profile ${details?.index}`}
                            underline={`These are the quality details for the current Profile ${details?.index}, which you're about to modify.`}
                        >
                            <ContentTable
                                auto
                                data={[
                                    ...(details?.info ? [{
                                        label: "Resolution",
                                        value: `${details?.info.width === 0
                                            ? "as input"
                                            : `${details?.info.width
                                                ? `${details?.info.width}x${details?.info.height}`
                                                : `n/a`
                                            }`
                                            }`,
                                    }] : []),
                                    ...(details?.info ? [{ label: "Bitrate", value: `${details?.info.bitrate} kbit/s` }] : []),
                                    ...(details?.info ? [{
                                        label: "Framerate",
                                        value: `${details?.info.fps === 0
                                            ? "as input"
                                            : `${details?.info.fps
                                                ? `${details?.info.fps} fps`
                                                : `n/a`
                                            }`
                                            }`
                                    }] : []),
                                ]}
                            />
                        </SectionContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SectionContainer
                            contrast
                            small
                            title={`Proposed Profile: New Settings for Profile ${details?.index}`}
                            underline={`Here are the new quality settings you've chosen for Profile ${details?.index}.`}
                        >
                            <ContentTable
                                auto
                                data={[
                                    ...(newProfile.vtrans ? [{
                                        label: "Resolution",
                                        value: `${newProfile.vtrans.width === 0
                                            ? "as input"
                                            : `${newProfile.vtrans.width
                                                ? `${newProfile.vtrans.width}x${newProfile.vtrans.height}`
                                                : `n/a`
                                            }`
                                            }`,
                                    }] : []),
                                    ...(newProfile.vtrans ? [{ label: "Bitrate", value: `${newProfile.vtrans.bitrate} kbit/s` }] : []),
                                    ...(newProfile.vtrans ? [{
                                        label: "Framerate",
                                        value: `${newProfile.vtrans.fps === 0
                                            ? "as input"
                                            : `${newProfile.vtrans.fps
                                                ? `${newProfile.vtrans.fps} fps`
                                                : `n/a`
                                            }`
                                            }`
                                    }] : []),
                                ]}
                            />
                        </SectionContainer>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12} mt={2}>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleUpdateProfile}
                >
                    Confirm Changes
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )
}
