import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, TextField } from '@mui/material';

import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    }
}));

export default function AddSpecificOption(props) {
    const classes = useStyles();
    const [value, setValue] = useState(null);

    const handleUpdatedTextfield = (event) => {
        props.update({ [(props.id).toLowerCase()]: event.target.value })
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <SectionContainer {...props}>
                    <TextField
                        variant="standard"
                        className={classes.textfield}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        color="primary"
                        placeholder={props.placeholder}
                        label={props.id}
                        value={value}
                        onChange={handleUpdatedTextfield}
                    />
                </SectionContainer>
            </Grid>
        </Grid>
    )
}
