"use strict";
/* global BigInt */

const config = require('./permission_config');


/**
 * 
 * @param {Obj} permissionObject 
 * @param {BigInt} masks 
 * @returns Object
 */
const parsePermissionObj = (permissionObject, masks)=>{
    if(typeof permissionObject === 'object'){
        let group = 0n;
        Object.keys(permissionObject).forEach((val,i,arr)=> {
            if(!masks[val]){
                return;
            }
            group = group + parsePermissionObj(permissionObject[val],masks[val]);
        });
        return group;
    }else {
        if(!!permissionObject){
            return BigInt(masks);
        }else {
            return 0n;
        }
    }
    
}

const numberToInnerObj= (number ,reference,shortened)=>{
    if(typeof reference == 'object'){
        Object.keys(reference).forEach(val => {
            reference[val] = numberToInnerObj(number,reference[val],shortened);
            if(reference[val] === false && shortened){
                delete reference[val];
            }
            if(shortened && typeof reference[val] === 'object' && Object.keys(reference[val]).length <= 0){
                delete reference[val];
            }
        })
    }else { 
        const maskNumber = BigInt(reference);
      
        if(BigInt(maskNumber & number) > BigInt(0)){
            return true;
        }else {
            return false;
        }
    }
    return reference;
}

const permissionHelper =  {

    /**
     * Function to check if a certain permission has been set for a user.
     * @param {BigInt} requiredPermission - required permission flag to be checked
     * @param {BigInt} grantedPermissions - permission flags granted to the user
     * @param {boolean} allowPublic - route has functionality for public use 
     * @returns boolean - true if user has permission
     */
     checkPermission: (requiredPermission, grantedPermissions,allowPublic = false) => {
        if(requiredPermission < 0n){
            throw new Error(`Permissions must be positive BigInt numbers. (requiredPermission = ${requiredPermission})`)
        }
        if(grantedPermissions < 0n){
            throw new Error(`Permissions must be positive BigInt numbers. (grantedPermission = ${grantedPermissions})`)
        }
        if(!requiredPermission){
            throw new Error("A required permission needs to be defined to be able to check if it was granted.")
        }

        if(allowPublic && !grantedPermissions){
            return true;
        }else if (!allowPublic && !grantedPermissions){
            return false;
        }

        if(BigInt(requiredPermission & grantedPermissions) <= 0n){
           return false;
        }
        return true;
    },

    /**
     * Function to covert user representation (object of boolean flags) to bit representation (bigint)
     * 
     * @param {object} permissionObject - Object of boolean flags of granted permissions
     * @returns {BigInt} BigInt representing bitmask of permissions.
     */
     objectToNumber: (permissionObject)=>{
        const permissionMasks = JSON.parse(JSON.stringify(config.permissionMasks, (key, value) =>
            typeof value === 'bigint'
                ? permissionHelper.numberToMaskString(value) : value // return everything else unchanged
        ));

        let permission = BigInt(0n);
        Object.keys(permissionObject).forEach((val,i,arr)=> {
            const value = permissionMasks[val];
            if(!value){
                return;
            }

            const innerPermission = parsePermissionObj(permissionObject[val],permissionMasks[val]);

            permission = permission + innerPermission;

         
        });
        return permission;

    },
     
    /**
     * Function to convert datastorage representation (hex string) of a permission profile to the interal representation (BigInt)
     * @param {String} mask - Permission mask represented as a hex string (e.g '0xffffffff')
     * @returns BigInt representing the permission set as a bit mask 
     */
     maskToNumber :(mask)=>{
        const number = BigInt(mask);
        return number
    },


    /**
     * Function to convert the datastorage representation  (hex string) of a permission profile to the user representation (object of boolean flags)
     * 
     * @param {string} mask - Permission mask represented as a hex string (e.g '0xffffffff')
     * @param {boolean} shortened boolean that indicates if object should contain granted permissions only or not.
     * @returns And object, in the shape of config.permissionMasks, containing boolean values.
     */
     maskToObject:(mask,shortened =false)=>{
        const number = permissionHelper.maskToNumber(mask);
        return permissionHelper.numberToObject(number,shortened);
    },
    /**
     * Function to convert the bit representation (bigint) of a permission profile to the user representation (object of boolean flags)
     * 
     * @param {BigInt} number - The number representing all the bit-flags for the users permissions 
     * @param {boolean} shortened - boolean that indicates if object should contain granted permissions only or not.
     * @returns And object, in the shape of config.permissionMasks, containing boolean values.
     */
     numberToObject:(number,shortened=false)=>{
        if(number < 0n){
            throw new Error(`Permissions must be positive BigInt numbers. ${number}`)
        }
        const obj = JSON.parse(JSON.stringify(config.permissionMasks, (key, value) =>
            typeof value === 'bigint'
                ? permissionHelper.numberToMaskString(value)
                : value // return everything else unchanged
        ));
         
        const result = numberToInnerObj(number,obj,shortened);
      
        return result;
    },
    /**
     * DRY: Turn bigint into data storage representation (hex string).
     * @param {BigInt} number - BigInt representing bitmask of permissions.
     * @returns String containing hex number representation of BigInt
     */
     numberToMaskString :(number)=>{
        if(number < 0n){
            throw new Error("Permissions must be positive BigInt numbers.")
        }
        let setBitsString = BigInt(number).toString(16);
        return "0x"+setBitsString;
    }
}
module.exports = permissionHelper;


