import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import clsx from 'clsx';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import notfound_light from '../assets/StreamNotFound_light.png';
import notfound_dark from '../assets/StreamNotFound_dark.png';
import logo from '../assets/white-logo.png';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Grid, Typography, Button, AppBar, Toolbar } from '@mui/material';
import { AddCircleOutline, HeadsetMicOutlined, Login, } from '@mui/icons-material';

import Loading from '../components/global/Loading';
import Player from '../components/player/Player';
import SnackbarMessage from '../components/global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },

    logo: {
        height: 30,
        marginRight: theme.spacing(2)
    },
    appBarContent: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            cursor: 'pointer'
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start'
        },
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    item: {
        textAlign: 'center',
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    },
    notAuthed: {
        padding: theme.spacing(3),
        paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(3)})`
    },
}));

export default function Playout(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    let { id } = useParams();
    const [searchParams] = useSearchParams();

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [isExternal] = useState(props.isExternal);

    const customServerDomain = searchParams.get("general.serverDomain");
    const paramToken = searchParams.get("security.jwtoken") || searchParams.get("security.token");
    const orgaSecured = (sessionStorage.getItem(ls.BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA)).secure : false);

    const h5liveToken = isExternal
        ? paramToken
            ? paramToken
            : null
        : paramToken
            ? paramToken
            : orgaSecured
                ? JSON.parse(sessionStorage.getItem(ls.H5LIVE_HASH_SECURE))
                : null


    const [loading, setLoading] = useState(true);
    const [stream, setStream] = useState(false);

    document.title = "nanoStream H5Live Playout | nanoStream Cloud Dashboard";

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const redirect = (link) => () => {
        navigate(link);
    }

    const getStreams = () => {

        let data = {
            streamid: id,
        }
        bintu.getStreamById(data)
            .then((response) => {
                if (response.success) {
                    setStream(response.data);
                    setLoading(false);
                }
            }).catch((error) => {
                setStream(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => {
        if (!stream) {
            getStreams();
        }
    }, [])

    return (
        <Fragment>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {
                isExternal && !sessionStorage.getItem(ls.BINTU_ORGA)
                &&
                <AppBar
                    position="fixed"
                    elevation={0}
                    sx={{
                        background: '#ed7d0e',
                    }}
                >
                    <Toolbar sx={{ zIndex: 2147483648 }}>
                        <div className={classes.appBarContent} onClick={redirect(`${link.AUTH}`)}>
                            <img alt="nanocosmos" className={classes.logo} src={logo} />
                        </div>
                    </Toolbar>
                </AppBar>
            }
            <Grid container className={clsx(classes.root, { [classes.notAuthed]: isExternal && !sessionStorage.getItem(ls.BINTU_ORGA) })}>
                {
                    loading
                        ? <Loading />
                        : <Fragment>
                            {
                                stream
                                    ?
                                    <Grid item xs={12} m={props.isExternal ? "auto" : 0} mb={4}>
                                        <Player
                                            isExternal={isExternal}
                                            stream={stream}
                                            isSecure={orgaSecured && h5liveToken}
                                            token={h5liveToken}
                                            customServerDomain={customServerDomain}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12} className={classes.item}>
                                        <img alt="not-found" className={classes.image} src={theme.palette.mode === 'light' ? notfound_light : notfound_dark} />
                                        <Typography variant="h5" color="primary" >
                                            Oops, we could not find the stream you are looking for. <br />
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            If you think this was a mistake, contact our support.
                                        </Typography>
                                        <div className={classes.buttonWrapper}>
                                            {
                                                isExternal
                                                    ?

                                                    <Button
                                                        className={classes.button}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={redirect(link.AUTH)}
                                                        startIcon={<Login />}
                                                    >
                                                        Authenticate
                                                    </Button>
                                                    :
                                                    <Button
                                                        className={classes.button}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={redirect(link.CREATE_STREAM)}
                                                        startIcon={<AddCircleOutline />}
                                                    >
                                                        Create new stream
                                                    </Button>
                                            }
                                            <Button
                                                className={classes.button}
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => window.open(link.CONTACT, "_blank")}
                                                startIcon={<HeadsetMicOutlined />}
                                            >
                                                Talk to our support
                                            </Button>
                                        </div>
                                    </Grid>
                            }
                        </Fragment>
                }
            </Grid >
        </Fragment>
    )
}
