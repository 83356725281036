import React, { Fragment, useEffect, useState } from 'react';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Checkbox, Collapse, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, Typography } from '@mui/material';
import { ArrowForwardIosSharp, Check, CheckCircle, CheckCircleOutlined, OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/system';
import SectionHeader from '../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    accordionRoot: {
        width: '100%',
        boxShadow: 'none !important',
        background: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]} !important`,
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    },
    checkboxRoot: {
        margin: '0 auto',
        '& .MuiRadio-root': {
            padding: theme.spacing(0.3),
        },
        [theme.breakpoints.up('xs')]: {
            width: '45%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto'
        },
    },
}));

export default function ProfileSetter(props) {
    const classes = useStyles();
    const [switchRule] = useState([
        { id: "deviationOfMean2", title: "Automatic" },
        { id: "none", title: "Manual" }
    ]);
    const [selectedSwitchRule, setSelectedSwitchRule] = useState(props.adaptionRule.id);
    const [quality, setQuality] = useState(0);

    const handleUpdateSwitchRule = (event) => {
        let isAutomatic = event.target.checked;
        let newSwitchRule = isAutomatic ? switchRule[0].id : switchRule[1].id
        setSelectedSwitchRule(newSwitchRule);
        props.setAdaption(newSwitchRule);
    }

    const handleUpdateQuality = (event) => {
        const streamindex = Number(event.target.value)
        if (selectedSwitchRule === switchRule[0].id) {
            setSelectedSwitchRule(switchRule[1].id)
        }
        setQuality(streamindex);
    }

    const handleLeanMore = (link) => () => {
        window.open(link, '_blank');
    }

    useEffect(() => {
        if ((selectedSwitchRule === switchRule[1].id) || !(props.adaptionRule.id === selectedSwitchRule)) {
            props.switchStream(quality)
        }
    }, [quality])

    useEffect(() => {
        if (props.streamInfo) {
            let newStreamSwitch = props.streamInfo.rtmp.streamname
            if (quality !== newStreamSwitch) {
                setQuality(props.stream.playout.h5live.findIndex((s) => s.rtmp.streamname === newStreamSwitch))
            }
        }
    }, [props.streamInfo])


    return (
        <Accordion className={classes.accordionRoot}>
            <AccordionSummary
                sx={{ flexDirection: 'row-reverse', alignItems: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
                expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
            >
                <Typography variant="body1" ml={1}>
                    Stream Quality:
                    &nbsp;
                    <b>
                        {
                            quality === 0
                                ? "Original"
                                :
                                `
                                ${props.stream.playout.h5live[quality].info.height === 0 ? "res as input" : `${props.stream.playout.h5live[quality].info.height ? `${props.stream.playout.h5live[quality].info.height}p` : `n/a`}`} / 
                                ${props.stream.playout.h5live[quality].info.bitrate} kbps / 
                                ${props.stream.playout.h5live[quality].info.fps === 0 ? "fps as input" : `${props.stream.playout.h5live[quality].info.fps ? `${props.stream.playout.h5live[quality].info.fps}fps` : `n/a`}`}
                                `
                        }
                    </b>
                    &nbsp;
                </Typography>
                <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
                    ({selectedSwitchRule === switchRule[0].id ? "adaptive" : "manual"})
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <SectionHeader
                    small
                    title="Adaptive bitrate (ABR) quality"
                    underline="Select stream quality or automatic switching."
                    button="Learn more"
                    interact
                    icon={<OpenInNew />}
                    clicked={handleLeanMore(link.DOCS_STREAM_SWTICH)}
                />
                <FormControl sx={{ mt: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={selectedSwitchRule === switchRule[0].id}
                                onChange={handleUpdateSwitchRule}
                            />
                        }
                        label={`Automatic`}
                    />
                </FormControl>
                <Box variant="body1">
                    {
                        <FormControl>
                            <RadioGroup
                                row
                                value={quality}
                                onChange={handleUpdateQuality}
                            >
                                {
                                    props.stream.playout.h5live.map((stream, i) => (
                                        <FormControlLabel
                                            sx={{ alignItems: 'flex-start', p: 1.5 }}
                                            classes={{ root: classes.checkboxRoot, label: classes.checkboxLabel }}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                    checkedIcon={<CheckCircle />}
                                                    value={i}
                                                />
                                            }
                                            label={
                                                <Fragment>
                                                    <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
                                                        {stream.rtmp.streamname}
                                                    </Typography>

                                                    {
                                                        i === 0 &&
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            Original
                                                        </Typography>
                                                    }
                                                    {
                                                        stream.info && stream.info.bitrate &&
                                                        (
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                Resolution: {stream.info.width === 0 ? "as input" : `${stream.info.width ? `${stream.info.width}x${stream.info.height}` : `n/a`}`} <br />
                                                                Bitrate: {stream.info.bitrate} kbps <br />
                                                                Framerate: {stream.info.fps === 0 ? "as input" : `${stream.info.fps ? `${stream.info.fps}fps` : `n/a`}`} <br />

                                                            </Typography>
                                                        )
                                                    }
                                                </Fragment>
                                            }
                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}
