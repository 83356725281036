import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import moment from 'moment';

import * as analyticsHelper from '../../utils/analytics-helper';
import * as metrics from '../../utils/api-requests';
import { convertBytesToUnit, convertBytes } from '../../utils/helper/byte-formatter'

import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import SnackbarMessage from '../../../components/global/SnackbarMessage';
import Loading from '../../../components/global/Loading';

const chartId = "byte-usage";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function ByteUsage(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({
        labels: [],
        datasets: [{ data: [] }]
    });

    let options = {
        maintainAspectRatio: false,
        responsive: true,
        color: theme.palette.text.secondary,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: true,
                align: 'start',
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: theme.typography.body1,
                },
            },
            tooltip: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                cornerRadius: 12,
                caretSize: 0,
                bodyFont: theme.typography.body1,
                padding: 10,
                callbacks: {
                    title: (context) => {
                        return `${context[0].label} (UTC)`
                    },
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        elements: {
            line: {
                tension: 0.4,
            }
        }
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const getBytesUsage = (filter) => {
        setLoading(true);
        metrics.getByteUsage(filter)
            .then((response) => {
                if (response.success) {
                    let format = analyticsHelper.GET_TIME_FORMAT(filter.interval);
                    let updatedLabels = response.data.map((item) => moment.utc(moment.unix(item.timestamp)).format(format));
                    let playoutBytes = response.data.map((value) => value.playout.bytes);
                    let ingestBytes = response.data.map((value) => value.ingest.bytes);

                    // convert byte values
                    let sum = 0;
                    playoutBytes.forEach(num => sum += num);
                    ingestBytes.forEach(num => sum += num);
                    let convertedPlayoutBytes = [...playoutBytes];
                    let convertedIngestBytes = [...ingestBytes]
                    let unitToConvertTo = 'Bytes';

                    if (sum !== 0) {
                        unitToConvertTo = convertBytes(sum / (playoutBytes.length + ingestBytes.length)).unit;
                        convertedPlayoutBytes = playoutBytes.map(bytes => convertBytesToUnit(bytes, unitToConvertTo));
                        convertedIngestBytes = ingestBytes.map(bytes => convertBytesToUnit(bytes, unitToConvertTo));
                    }

                    // update widget title
                    props.updateUnit(unitToConvertTo)

                    setData({
                        labels: updatedLabels,
                        datasets: [
                            {
                                ...data.datasets,
                                label: `Playout usage in ${unitToConvertTo}`,
                                data: convertedPlayoutBytes,
                                borderColor: analyticsHelper.GENERATE_RANDOM_COLORS(1),
                                backgroundColor: `${analyticsHelper.GENERATE_RANDOM_COLORS(1)}`,
                                // borderRadius: 5,
                                // hoverOffset: 4,
                                // borderRadius: 5,

                            },
                            {
                                ...data.datasets,
                                label: `Ingest usage in ${unitToConvertTo}`,
                                data: convertedIngestBytes,
                                borderColor: `${analyticsHelper.GENERATE_RANDOM_COLORS(2)[1]}`,
                                backgroundColor: `${analyticsHelper.GENERATE_RANDOM_COLORS(2)[1]}`,
                                // borderRadius: 5,
                                // hoverOffset: 4,
                                // borderRadius: 5,

                            },
                        ]
                    });
                    setLoading(false);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                    setLoading(false);
                }
            })
    }

    useEffect(() => {
        if (props.time) getBytesUsage(props.time)
    }, [props.time])

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12} pt={2} pb={1} sx={{ height: '50vh', textAlign: 'center' }}>
                {
                    loading
                        ? <Loading />
                        :
                        <Line
                            id={chartId}
                            options={options}
                            data={data}
                        />
                }
            </Grid>
        </Grid>
    )
}
