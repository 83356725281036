import React from 'react';

import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        margin: '0 auto'
    },
}));

export default function Loading(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress thickness={5} />
        </div>

    )
}
