import React, { Fragment, useState } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Avatar, Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, alpha } from '@mui/material';
import { HeadsetOutlined, Key, QuestionAnswer, SlowMotionVideo } from '@mui/icons-material';
import ContentTable from '../global/ContentTable';
import SectionContainer from '../global/SectionContainer';
import SectionHeader from '../global/SectionHeader';

export default function Orga(props) {
    const theme = useTheme();
    const { orga } = props;
    const [data, setData] = useState([
        { icon: <Key />, label: "Orga Hash", value: orga.hash, copy: true },
        { icon: <SlowMotionVideo />, label: "Player Metrics", value: orga.metrics === null ? "No metrics" : `${orga.metrics} metrics`, copy: false },
        { icon: <QuestionAnswer />, label: "Support Level", value: orga.sla === null ? "Basic Support" : orga.sla, copy: false },
    ]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box p={2} sx={{ borderRadius: theme.spacing(2), bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[800] }}>
                    <SectionHeader
                        title="Details"
                    />
                    <List>
                        <Divider />
                        {
                            data.map((d, i) => (
                                <Fragment>
                                    <ListItem disablePadding>
                                        <ListItemIcon>
                                            <Box sx={{ borderRadius: '50%', p: .4, bgcolor: alpha(theme.palette.primary.main, 0.3) }}>
                                                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                                                    {d.icon}
                                                </Avatar>
                                            </Box>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
                                                    {d.value}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {d.label}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))
                        }
                    </List>
                </Box>
            </Grid>
        </Grid>
    )
}
