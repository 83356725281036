import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../utils/helper/link-config';


import { useTheme } from '@mui/styles';
import { Box, Chip, Divider, Grid, Hidden, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { HighlightOff, TaskAltOutlined } from '@mui/icons-material';

import State from '../global/State';
import Row from './Row';

export default function StreamList(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const rowsPerPageOptions = [25, 50, 100, 150];

    const [streams, setStreams] = useState(props.streams);
    const [streamCount, setStreamCount] = useState(props.count);
    const [tableCount, setTableCount] = useState({
        rowsPerPage: 25,
        page: 0
    });

    const handleChangePage = (event, newPage) => {
        setTableCount({
            ...tableCount,
            page: newPage
        });
    }

    const handleOpenStream = (streamid) => () => {
        navigate(`${link.STREAM}/${streamid}`);
    }

    const handleChangeRowsPerPage = (event) => {
        setTableCount({
            rowsPerPage: +event.target.value,
            page: 0
        });
    }

    useEffect(() => {
        props.updatePagination(tableCount);
    }, [tableCount])

    useEffect(() => {
        setStreamCount(props.count)
    }, [props.count])

    useEffect(() => {
        setStreams(props.streams)
    }, [props.streams])


    return (
        <Grid container>
            {
                streams
                &&
                <Grid item xs={12}>
                    <TableContainer sx={{ overflowX: "initial" }}>
                        <Table size="small">
                            <TableHead sx={{
                                background: theme.palette.background.default,
                                position: 'sticky',
                                zIndex: 100,
                                top: 56,
                                [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                                    top: 48,
                                },
                                [theme.breakpoints.up('sm')]: {
                                    top: 64,
                                },
                            }}>
                                <Hidden mdUp>
                                    <TableCell align="center">
                                        <Typography variant="button" color="textSecondary">
                                            Stream
                                        </Typography>
                                    </TableCell>
                                </Hidden>
                                <Hidden mdDown>
                                    <TableRow>
                                        {
                                            [
                                                "",
                                                "State",
                                                "Stream Id",
                                                "Stream Name",
                                                ...(props.isStreamgroup ? ["Streamgroup"] : []),
                                                "Last Updated (UTC)",
                                                "Tags",
                                            ].map((label) => (
                                                <TableCell key={label} align="left">
                                                    <Typography variant="button" color="textSecondary">
                                                        {label}
                                                    </Typography>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </Hidden>
                            </TableHead>
                            <TableBody sx={{ cursor: 'pointer' }}>
                                {
                                    streams.map((stream) => (
                                        <Fragment key={stream.id}>
                                            <Hidden mdUp>
                                                <TableRow onClick={handleOpenStream(stream.id)}>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <State state={stream.state} streamid={stream.id} />
                                                            <Divider sx={{ mr: 1, ml: 1 }} orientation="vertical" variant="middle" flexItem />
                                                            <Typography color="textSecondary" variant="subtitle2" sx={{ lineHeight: 1, textAlign: 'center' }}>
                                                                {moment(stream.updatedAt).format('MM/DD/YYYY hh:mm a')} (UTC)
                                                            </Typography>
                                                            <Divider sx={{ mr: 1, ml: 1 }} orientation="vertical" variant="middle" flexItem />
                                                            {
                                                                stream.streamgroup && stream.playout.h5live.length > 1
                                                                    ? <Chip
                                                                        size="small"
                                                                        icon={<TaskAltOutlined />}
                                                                        label={`Streamgroup (${stream.playout.h5live.length} Playouts)`}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                    />
                                                                    : <Chip
                                                                        size="small"
                                                                        icon={<HighlightOff />}
                                                                        label={`No streamgroup`}
                                                                        color="default"
                                                                    />
                                                            }
                                                        </Box>
                                                        <Box mt={0.5} mb={0.5}>
                                                            <Typography sx={{ lineHeight: 1 }} variant="body1">
                                                                {stream.id}
                                                            </Typography>
                                                            <Typography mb={1} sx={{ lineHeight: 1 }} variant="button" color="textSecondary">
                                                                Stream Id
                                                            </Typography>
                                                            <Typography sx={{ lineHeight: 1 }} variant="body1">
                                                                {stream.ingest.rtmp?.streamname}
                                                            </Typography>
                                                            <Typography sx={{ lineHeight: 1 }} variant="button" color="textSecondary">
                                                                Stream Name
                                                            </Typography>
                                                        </Box>
                                                        <Stack useFlexGap spacing={.3} direction="row" flexWrap={"wrap"}>
                                                            {
                                                                stream.tags.map((tag, i) => (
                                                                    <Chip
                                                                        key={i}
                                                                        size="small"
                                                                        label={tag}
                                                                        color="primary"
                                                                        sx={{ borderRadius: '5px !important' }}
                                                                    />
                                                                ))
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            </Hidden>
                                            <Hidden mdDown>
                                                <Row
                                                    {...props}
                                                    stream={stream}
                                                    clicked={handleOpenStream}
                                                />
                                            </Hidden>
                                        </Fragment>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={streamCount}
                        showFirstButton
                        showLastButton
                        component="div"
                        rowsPerPage={tableCount.rowsPerPage}
                        page={tableCount.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            }
        </Grid>
    )
}
