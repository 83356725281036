import React, { Fragment, useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { TextField, Grid, Button } from '@mui/material';

import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';
import ContentDialog from '../global/ContentDialog';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function DeleteStream(props) {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }


    const handleDeleteStream = () => {
        let data = {
            streamid: props.streamid,
        }

        bintu.deleteStream(data)
            .then((response) => {
                if (response.success) {
                    props.deleted();
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Fragment>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <ContentDialog
                open={props.open}
                close={props.cancel}
                submit={handleDeleteStream}
                submitButton={"Proceed & Delete"}
                title={`Delete Stream with id: ${props.streamid}`}
                content={`You are about to delete the stream with id: ${props.streamid}. All related resources will be deleted by this. Please ensure this is the intended action.`}
            />
        </Fragment>
    )
}
