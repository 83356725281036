import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';

import notfound_light from '../assets/PageNotFound_light.png';
import notfound_dark from '../assets/PageNotFound_dark.png';

import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { AddCircleOutline, HeadsetMicOutlined } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        textAlign: 'center',
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block'
    },
    title: {
        fontSize: '30px !important'
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    }
}));

export default function NotFound(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();

    document.title = "Oops, Page Not Found | nanoStream Cloud Dashboard";

    const redirect = (link) => () => {
        navigate(link);
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <img className={classes.image} src={theme.palette.mode === 'light' ? notfound_light : notfound_dark} />
                <Typography className={classes.title} variant="h5" color="primary">
                    Page not found
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Oops, we could not find the page you are looking for.
                </Typography>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={redirect(link.CREATE_STREAM)}
                        startIcon={<AddCircleOutline />}
                    >
                        Create new stream
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        onClick={() => window.open(link.CONTACT, "_blank")}
                        startIcon={<HeadsetMicOutlined />}
                    >
                        Talk to our support
                    </Button>
                </div>

            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid>
    )
}
