import React, { useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { alpha, Chip, Collapse, Grid, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { ClearRounded, KeyboardArrowDown, KeyboardArrowUp, LocalOfferOutlined, Tag } from '@mui/icons-material';

import SectionContainer from '../global/SectionContainer';
import Tags from '../organisation/Tags';
import SnackbarMessage from '../global/SnackbarMessage';
import { usePermission } from '../../utils/helper/hooks';
import permissionConfig from '../../utils/permissions/permission_config';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    textfield: {
        marginTop: `${theme.spacing(2)} !important`
    },
    tag: {
        marginRight: `${theme.spacing(0.5)} !important`,
        marginTop: `${theme.spacing(0.5)} !important`,
        borderRadius: '5px !important'
    },
    link: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));

export default function AddTags(props) {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [tag, setTag] = useState("");
    const [tagList, setTagList] = useState([]);
    const [tagProposes, setTagProposes] = useState([]);
    const [expand, setExpand] = useState(false);
    const canCreateTag = usePermission(permissionConfig.permissionMasks.tag.createTag);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const addNewTag = (newTag) => {
        // if (!canCreateTag) return;
        if (newTag !== "" && newTag.trim() !== "") {
            if (tagList.includes(newTag)) return;
            setTagList([
                ...tagList,
                newTag
            ]);
            setTag("");
        }
    }

    const handleUpdatedTextfield = (event) => {
        setTag(event.target.value);
    }

    const handleKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: addNewTag(tag); break;
            default: break;
        }
    }

    const handleDeleteTag = (tag) => (event) => {
        let updatedTagArray = tagList.filter((singleTag) => singleTag !== tag);
        setTagList(updatedTagArray);
    }

    const handleExpandTags = () => {
        setExpand(!expand);
    }

    const handleFetchTagsByRegex = () => {
        let data = { tag }

        bintu.getTagCollection(data)
            .then((response) => {
                if (response.success) {
                    setTagProposes(response.data.tags)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => {
        if (props.tags) {
            setTagList(props.tags)
        }
    }, [])

    useEffect(() => {
        props.updateTagList(tagList);
    }, [tagList])

    useEffect(() => {
        if (tag.length > 2) {
            handleFetchTagsByRegex();
        }
    }, [tag])

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <SectionContainer
                    {...props}
                    title={props.title}
                    underline={props.underline}
                >
                    {/* {
                        !canCreateTag &&
                        <Typography color="textSecondary" sx={{ fontStyle: 'italic' }}>
                            You can't create tags with your current access rights. Please refer to your system administrator.
                        </Typography>
                    } */}
                    <Collapse in={tagList.length > 0}>
                        {
                            tagList.map((t, i) => (
                                <Chip
                                    key={i}
                                    className={classes.tag}
                                    label={t}
                                    clickable
                                    color="primary"
                                    onDelete={handleDeleteTag(t)}
                                    deleteIcon={<ClearRounded sx={{ color: '#fff !important' }} />}
                                />
                            ))
                        }
                    </Collapse>
                    <TextField
                        className={classes.textfield}
                        fullWidth
                        // disabled={props.disabled || !canCreateTag}
                        variant="standard"
                        InputProps={{
                            startAdornment: (
                                < InputAdornment position="start">
                                    <LocalOfferOutlined color={props.disabled ? "text.secondary" : "primary"} />
                                </InputAdornment>)
                        }}
                        helperText="Press Enter to create/add new tag"
                        color="primary"
                        value={tag}
                        onChange={handleUpdatedTextfield}
                        onKeyPress={handleKeyPressed}
                    />
                    <Collapse in={tagProposes.length > 0}>
                        {
                            tagProposes.map((t, i) => (
                                <Chip
                                    key={i}
                                    label={t.tag}
                                    clickable
                                    color="primary"
                                    className={classes.tag}
                                    onClick={() => addNewTag(t.tag)}
                                />
                            ))
                        }
                    </Collapse>
                    <Link className={classes.link} variant="body2" underline="hover" onClick={handleExpandTags}>
                        Show all tags {expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Link>
                    <Collapse in={expand}>
                        <Tags clicked={addNewTag} />
                    </Collapse>
                </SectionContainer>
            </Grid>
        </Grid>

    )
}
