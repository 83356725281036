import { datadogRum } from '@datadog/browser-rum';
import * as ls from '../helper/ls-vars';

export const CHECK_ACCESS = () => {
    let hasAccess = (JSON.parse(sessionStorage.getItem(ls.AUTHORIZED)) || false) && sessionStorage.getItem(ls.BINTU_TOKEN) && Boolean(sessionStorage.getItem(ls.AUTHORIZED)) === true;
    if (hasAccess) {
        let email = sessionStorage.getItem(ls.BINTU_MAIL);
        let orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
        datadogRum.setUser({
            id: orga?.id,
            email: email
        });
    }
    return hasAccess;
}

export const CHECK_ROLE = () => {
    let role = sessionStorage.getItem(ls.ROLE);
    switch(role) {
        case "nanoAdmin": return 0;
        case "nanoUser": return 1;
        case "nanoReadOnly": return 2;
        default: return 2;
    }
}

export const GET_PARAM = (parameter) => {
    let params = new URLSearchParams(window.location.search);

    if (params.has(parameter)) {
        let value = params.get(parameter)
        return value;
    }
    return false;
}

export const TO_QUERY_STRING = (obj, prefix = '') => {
    const pairs = [];

    for (const key in obj) {
        if (!obj.hasOwnProperty(key)) continue;

        const fullPath = prefix ? `${prefix}.${key}` : key;
        const value = obj[key];

        if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
            pairs.push(TO_QUERY_STRING(value, fullPath));
        } else {
            pairs.push(encodeURIComponent(fullPath) + '=' + encodeURIComponent(value));
        }
    }
    return pairs.join('&');
}

export const GENERATE_SEARCH_QUERY = (sq) => {
    return Object.keys(sq).map(s => `${s}=${sq[s]}`).join('&')
}

export const DECODE_TOKEN = (token) => {
    if (token) {
        var base64Url = token.split('.')[1];
        if (base64Url) {
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            try {
                var jsonPayload = decodeURIComponent(window.atob(base64)
                    .split('')
                    .map((c) => {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join(''));
                return JSON.parse(jsonPayload);
            }
            catch {
                return false;
            }
        }
        return false
    }
}