import React, { useState, useEffect } from 'react';

import * as webrtcHelper from '../../utils/helper/webcaster';
import * as link from '../../utils/helper/link-config';

import { Button, ButtonGroup, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Camera, OndemandVideo, Videocam } from '@mui/icons-material';

export default function WebcasterSettings(props) {
    const theme = useTheme();

    const { devices,
        useCamera: initialUseCamera,
        extraSettings: initialExtraSettings,
        selectedDevice: initialSelectedDevice,
        maxBitrates: initialMaxBitrates,
        maxFramerate: initialMaxFramerate,
        resolution: initialResolution
    } = props;

    const [useCamera, setUseCamera] = useState(initialUseCamera);
    const [selectedDevice, setSelectedDevice] = useState(initialSelectedDevice);
    const [maxBitrates, setMaxBitrates] = useState(initialMaxBitrates);
    const [extraSettings, setExtraSettings] = useState(initialExtraSettings);
    const [maxFramerate, setMaxFramerate] = useState(initialMaxFramerate);
    const [resolution, setResolution] = useState(initialResolution);

    const handleSelectChange = (key, value) => setSelectedDevice({ ...selectedDevice, [key]: value });
    const handleMaxBitrateChange = (key, value) => setMaxBitrates({ ...maxBitrates, [key]: value });
    const handleCheckboxChange = (key) => setExtraSettings({ ...extraSettings, [key]: !extraSettings[key] });

    const handleSelectScreenshare = () => (!useCamera && props.openScreenshareDialog(), setUseCamera(false));

    useEffect(() => {
        if ((initialUseCamera !== useCamera) || (initialSelectedDevice !== selectedDevice) ||
            (initialMaxBitrates !== maxBitrates) || (initialExtraSettings !== extraSettings) ||
            (initialMaxFramerate !== maxFramerate) || initialResolution !== resolution) {
            props.update({
                useCamera, selectedDevice, extraSettings,
                maxBitrates, maxFramerate, resolution
            });
        }
    }, [useCamera, selectedDevice, maxBitrates, extraSettings, maxFramerate, resolution]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ButtonGroup fullWidth>
                    <Button
                        startIcon={<Videocam />}
                        onClick={() => setUseCamera(true)}
                        variant={useCamera ? "contained" : "outlined"}
                    >
                        Video
                    </Button>
                    <Button
                        startIcon={<OndemandVideo />}
                        onClick={handleSelectScreenshare}
                        variant={useCamera ? "outlined" : "contained"}
                    >
                        Screenshare
                    </Button>
                </ButtonGroup>
            </Grid>
            {
                useCamera &&
                <Grid item xs={12} lg={6}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel>Video Device</InputLabel>
                        <Select
                            value={selectedDevice.video}
                            onChange={(e) => handleSelectChange('video', e.target.value)}
                        >
                            {
                                devices?.videoinput?.map((v) => (
                                    <MenuItem key={v.label} value={v.deviceId}>{v.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }
            <Grid item xs={12} lg={useCamera ? 6 : 12}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Max Video Bitrate</InputLabel>
                    <Select
                        value={maxBitrates.video}
                        onChange={(e) => handleMaxBitrateChange('video', e.target.value)}
                    >
                        {
                            webrtcHelper.VIDEO_BITRATE.map((vb) => (
                                <MenuItem key={vb.value} value={vb.value}>{vb.label}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Resolution</InputLabel>
                    <Select
                        value={resolution}
                        onChange={(e) => setResolution(e.target.value)}
                    >
                        {
                            webrtcHelper.RESOLUTION.map((res) => (
                                <MenuItem key={res.height} value={res}>{res.width}x{res.height}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Max Video Framerate</InputLabel>
                    <Select
                        value={maxFramerate}
                        onChange={(e) => setMaxFramerate(e.target.value)}
                    >
                        {
                            webrtcHelper.FRAMERATE.map((fps) => (
                                <MenuItem key={fps} value={fps}>{fps} fps</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Audio Device</InputLabel>
                    <Select
                        value={selectedDevice.audio}
                        onChange={(e) => handleSelectChange('audio', e.target.value)}
                    >
                        {
                            devices?.audioinput?.map((a) => (
                                <MenuItem key={a.label} value={a.deviceId}>{a.label}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Max Audio Bitrate</InputLabel>
                    <Select
                        value={maxBitrates.audio}
                        onChange={(e) => handleMaxBitrateChange('audio', e.target.value)}
                    >
                        {
                            webrtcHelper.AUDIO_BITRATE.map((ab) => (
                                <MenuItem key={ab} value={ab}>{ab} Kbps</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormGroup row>
                    {
                        Object.keys(extraSettings).map((es) => (
                            <FormControlLabel
                                key={es}
                                control={
                                    <Checkbox
                                        checked={extraSettings[es]}
                                        onChange={() => handleCheckboxChange(es)}
                                    />
                                }
                                label={es}
                            />
                        ))
                    }
                </FormGroup>
            </Grid>
        </Grid>
    )
}
