import React, { useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { TextField, Grid, Button, Typography, Link } from '@mui/material';

import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function UpdateTimecode(props) {
    const classes = useStyles();
    const [timecode, setTimecode] = useState(props.timecode);
    const [hasTimecode, setHasTimecode] = useState(true);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleUpdateTextfield = (event) => {
        setTimecode(event.target.value);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleBlurredTextfield = () => {
        if (timecode == 0) {
            setHasTimecode(false);
            return;
        }
        if (timecode === "" || Number(timecode) < 500 || Number(timecode) > 3600000 || /[^0-9]+/.test(timecode)) {
            setHasTimecode(true);
            setTimecode(500);
        }
    }


    const handleUpdateTimecode = () => {
        let data = {
            streamid: props.streamid,
            hasTimecode: hasTimecode,
            ...(hasTimecode && { timecode: Number(timecode) })
        }

        bintu.setTimecode(data)
            .then((response) => {
                if (response.success) {
                    props.updated(response.data.timecode_interval, response.data.timecode)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12} mr={2} mt={1}>
                <TextField
                    variant="standard"
                    label="Timecode (ms)"
                    value={timecode}
                    onBlur={handleBlurredTextfield}
                    onChange={handleUpdateTextfield}
                />
            </Grid>
            <Grid item xs={12} mt={1}>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleUpdateTimecode}
                >
                    Update
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>

    )
}
