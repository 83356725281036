import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';
import * as methods from '../utils/helper/methods';

import { makeStyles } from '@mui/styles';
import { Collapse, Button, Grid, Tooltip } from '@mui/material';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import AddTags from '../components/create/AddTags';
import AddTranscoding from '../components/create/AddTranscoding';
import AddMetadata from '../components/create/AddMetadata';
import SnackbarMessage from '../components/global/SnackbarMessage';
import AddThumbnail from '../components/create/AddThumbnail';
import { usePermission } from '../utils/helper/hooks';
import permissionConfig from '../utils/permissions/permission_config';
import NoAccess from '../components/global/NoAccess';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

const OptionsButton = (props) => {
    const classes = useStyles();
    const { option, title, clicked } = props;
    const [open, setOpen] = useState(option.open);
    const [enabled, setEnabled] = useState(option.enabled);

    const handleClickedButton = () => {
        clicked(title);
    }

    useEffect(() => {
        if (option.open !== open) setOpen(option.open);
        if (option.enabled !== enabled) setEnabled(option.enabled)
    }, [option])


    return (
        enabled ? (
            <Button
                size="small"
                sx={{ mx: 1 }}
                variant="outlined"
                color="primary"
                onClick={handleClickedButton}
                startIcon={open ? <RemoveOutlined /> : <AddOutlined />}
            >
                {open ? "Remove" : "Add"} {title}
            </Button>
        ) : (
            <Tooltip title={`Contact Sales to enable ${title}.`}>
                <span>
                    <Button
                        size="small"
                        disabled
                        sx={{ mx: 1 }}
                        variant="outlined"
                        startIcon={<AddOutlined />}
                    >
                        Add {title}
                    </Button>
                </span>
            </Tooltip>
        )
    );
}

export default function CreateStream(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
    const canCreateStream = usePermission(permissionConfig.permissionMasks.stream.createStream);
    const canFetchTranscodingProfiles = usePermission(permissionConfig.permissionMasks.transcoding_profiles.getTranscodingProfiles);
    const canSetTimecode = usePermission(permissionConfig.permissionMasks.streamWithId.setTimecode);
    const canSetOpcode = usePermission(permissionConfig.permissionMasks.streamWithId.setOpcode);

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [tags, setTags] = useState([]);
    const [timecode, setTimecode] = useState(0);
    const [transcodes, setTranscodes] = useState([]);
    const [thumbnail, setThumbnail] = useState({ process: true, interval: 30 });
    const [options, setOptions] = useState({
        transcoding: { enabled: orga?.transcoding && canFetchTranscodingProfiles, open: orga?.transcoding && canFetchTranscodingProfiles },
        thumbnail: { enabled: canSetOpcode && true, open: false },
        timecode: { enabled: orga?.metadata && canSetTimecode, open: false },
    });

    document.title = "Create new Stream | nanoStream Cloud Dashboard";

    const updateTagList = (list) => { setTags(list); }
    const updateTranscodingProfiles = (profiles) => { setTranscodes(profiles); }
    const updateTimecode = (newTimecode) => { setTimecode(newTimecode) }
    const updateThumbnail = (interval) => { setThumbnail(interval) }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    const expand = (id) => {
        setOptions({
            ...options,
            [id]: { ...options[id], open: !(options[id].open) }
        })
    }

    const handleCreateNewStream = async () => {
        if (!canCreateStream) return;
        let hasTranscodes = options.transcoding.enabled && options.transcoding.open
        let hasThumbnail = options.thumbnail.open;
        let hasTimecode = options.timecode.enabled && options.timecode.open;
        let transcodeList = hasTranscodes ? transcodes : [];

        let streamData = {
            ...(tags.length > 0 && { tags }),
            ...(hasTranscodes && { transcodes: transcodeList })
        }

        bintu.createNewStream(streamData)
            .then((response) => {
                if (response.success) {
                    let stream = response.data;

                    if (hasThumbnail) {
                        let thumbnailData = {
                            streamid: stream.id,
                            opcode: thumbnail.process ? "thumbs" : "none",
                            ...thumbnail.process && { interval: parseInt(thumbnail.interval) }
                        }

                        bintu.setOpcode(thumbnailData)
                            .then((response) => {
                            }).catch((error) => {
                                if (!error.success) {
                                    setError(error);
                                    setShowError(true);
                                }
                            })
                    }
                    if (hasTimecode) {
                        let timecodeData = {
                            streamid: stream.id,
                            hasTimecode: options.timecode.open,
                            timecode: timecode
                        };
                        bintu.setTimecode(timecodeData)
                            .then((response) => {
                            }).catch((error) => {
                                if (!error.success) {
                                    setError(error);
                                    setShowError(true);
                                }
                            })
                    }
                    navigate(`${link.NEW_STREAM}/${stream.id}`)
                }
            }).catch(() => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })

    }

    return (
        <Fragment>
            {
                !canCreateStream
                    ? <NoAccess content="Stream Creation" />
                    :
                    <Grid container className={classes.root}>
                        <SnackbarMessage
                            open={showError}
                            close={handleCloseError}
                            type={"error"}
                        >
                            <b>Error: {error.code}</b> {error.message}
                        </SnackbarMessage>
                        <Grid item xs={12}>
                            <SectionHeader
                                title="Create new Stream"
                                underline="Here you can create a new stream."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AddTags
                                title="Add Tags"
                                underline="Add tag(s) that you can use to identify your stream more easily in the future."
                                updateTagList={updateTagList}
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            {
                                Object.values(options).map((p, i) => (
                                    <OptionsButton
                                        key={i}
                                        title={Object.keys(options)[i]}
                                        option={p}
                                        clicked={expand}
                                    />
                                ))
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={canFetchTranscodingProfiles && options.transcoding.open}>
                                <AddTranscoding
                                    allowed={canFetchTranscodingProfiles}
                                    applyTranscodingProfiles={updateTranscodingProfiles}
                                />
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} sm={options.timecode.open ? 6 : 12}>
                            <Collapse in={canSetOpcode && options.thumbnail.open}>
                                <AddThumbnail updateThumbnail={updateThumbnail} />
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Collapse in={canSetTimecode && options.timecode.open}>
                                <AddMetadata updateTimecode={updateTimecode} />
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Button size="small"
                                disabled={!canCreateStream}
                                variant="contained"
                                onClick={handleCreateNewStream}
                            >
                                Create new Stream
                            </Button>
                        </Grid>
                    </Grid>
            }
        </Fragment>
    )
}
