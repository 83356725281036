import React, { useState, useEffect } from 'react';

import { Box, Button, Collapse, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Public } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import SnackbarMessage from '../global/SnackbarMessage';
import { useTheme } from '@mui/styles';
import { setPublicVOD } from '../../utils/bintu/api-requests';
import { usePermission } from '../../utils/helper/hooks';
import permissionConfig from '../../utils/permissions/permission_config';

export default function Vod(props) {
    const theme = useTheme();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const { publicVod } = props;
    const [isPublic, setPublic] = useState(publicVod);
    const canSetVod = usePermission(permissionConfig.permissionMasks.organisation.setVod);


    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    const handleUpdate = () => {
        let data = { isPublic: !isPublic }

        setPublicVOD(data)
            .then((response) => {
                if (response.success) {
                    setPublic(response.data.publicVOD);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Grid container mt={2}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <Box p={2} sx={{ borderRadius: theme.spacing(2), bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900] }}>
                    <SectionHeader
                        title="VOD Settings"
                        underline={canSetVod && "Select whether you want your organization's VODs to be accessible via the public request URL."}
                    />
                    <FormControlLabel
                        disabled={!canSetVod}
                        control={<Switch checked={isPublic} onChange={handleUpdate} />}
                        label={
                            <Typography color="textSecondary" variant="body1">
                                Your VODs are {isPublic ? "public" : "not accessible to the public"}.
                            </Typography>
                        }
                    />
                </Box>
            </Grid >
        </Grid >

    )
}
