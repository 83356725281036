import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { Button, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function SmallTimeRangeFilter(props) {
    const classes = useStyles();
    let now = moment().utc().subtract(1, "minutes");
    const [filterSelection] = useState({
        1: {
            index: 1, interval: "minute",
            title: "1H", value: moment().utc().subtract(1, "hours").format("YYYY-MM-DDTHH:mm")
        },
        2: {
            index: 2, interval: "hour",
            title: "1D", value: moment().utc().subtract(1, "day").format("YYYY-MM-DDTHH:mm")
        },
        3: {
            index: 3, interval: "day",
            title: "1W", value: moment().utc().subtract(1, "week").format("YYYY-MM-DDTHH:mm")
        }
    });
    const [timeRange, setTimeRange] = useState({
        index: 2,
        interval: filterSelection[2].interval,
        from: filterSelection[2].value,
        to: now.format("YYYY-MM-DDTHH:mm"),
    });

    const handleUpdateTimeRange = (event) => {
        let index = event.target.value;
        setTimeRange({
            ...timeRange,
            index: index,
            interval: filterSelection[index].interval,
            from: filterSelection[index].value
        })
    }


    useEffect(() => {
        props.updateFilter({ from: timeRange.from, to: timeRange.to, interval: timeRange.interval });
    }, [timeRange])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                {
                    Object.values(filterSelection).map((filter, index) => (
                        <Button
                            size="small"
                            variant={timeRange.index - 1 === index ? "contained" : "outlined"}
                            key={filter.value}
                            value={index + 1}
                            sx={{minWidth: 'auto', mx: 0.3, my: 0.2}}
                            onClick={handleUpdateTimeRange}
                        >
                            {filter.title}
                        </Button>
                    ))
                }
                {/* <FormControl sx={{ minWidth: 120 }} size="small">
                    <Select
                        value={timeRange.index}
                        onChange={handleUpdateTimeRange}
                    >
                        {
                            Object.values(filterSelection).map((filter, index) => (
                                <MenuItem
                                    key={filter.value}
                                    value={index + 1}
                                >
                                    {filter.title}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl> */}
            </Grid>
        </Grid>

    )
}
