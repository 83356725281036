import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Grid, InputAdornment, Stack, TextField, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ArrowRightAlt, Lock, Mail, VpnKey } from '@mui/icons-material';
import { redeemInviteToken } from '../../utils/bintu/api-requests';
import SnackbarMessage from '../global/SnackbarMessage';
import { AUTH, WELCOME } from '../../utils/helper/link-config';

export default function InviteToken(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { token } = useParams();
    let mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [textfields, setTextfields] = useState({
        email: {
            value: "", id: "email",
            autoComplete: "email", label: "E-Mail",
            type: "text", error: false,
            icon: <Mail color="primary" />
        },
        password: {
            value: "", id: "password",
            autoComplete: "new-password", label: "Password",
            type: "password", error: false,
            icon: <Lock color="primary" />
        },
        confirmPassword: {
            value: "", id: "confirmPassword",
            autoComplete: "new-password", label: "Confirm Password",
            type: "password", error: false,
            icon: <Lock color="primary" />
        }
    });


    const handleUpdateTextfield = (id) => (event) => {
        let updatedValue = event.target.value
        setTextfields({
            ...textfields,
            [id]: {
                ...textfields[id],
                value: updatedValue
            }
        })
    }

    const handleSubmitTextfield = (event) => {
        event.preventDefault();
        handleRedeemToken(event);
    }

    const handleAnyKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: handleRedeemToken(event); break;
            default: break;
        }
    }

    const credentialsAreInvalid = () => {
        let mailInvalid = !mailRegex.test(textfields.email.value)
        let passwordInvalid = (textfields.password.value === "") || (textfields.confirmPassword.value !== textfields.password.value);

        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
            password: {
                ...textfields.password,
                error: passwordInvalid
            },
            confirmPassword: {
                ...textfields.password,
                error: passwordInvalid
            }
        });

        return mailInvalid || passwordInvalid;
    }

    const handleRedeemToken = (event) => {
        event.preventDefault();

        if (credentialsAreInvalid()) return;

        let data = {
            email: textfields.email.value,
            password: textfields.password.value,
            token
        }
        redeemInviteToken(data)
            .then((response) => {
                if (response.success) {
                    navigate(WELCOME)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setShowError(false);
    }

    return (
        <Grid container spacing={2}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                    Your Invite Token
                </Typography>
                <Stack direction="row" spacing={1} justifyContent={"center"}>
                    {
                        token.split("").map((ch) => (
                            <Box key={ch}
                                sx={{
                                    py: 1, px: 1.5,
                                    borderRadius: 2,
                                    backgroundColor: alpha(theme.palette.common.white, 0.1)
                                }}
                            >
                                {ch}
                            </Box>
                        ))
                    }
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <form autoComplete>
                    {
                        Object.values(textfields).map((textfield, i) => (
                            <TextField
                                sx={{ my: 1, input: { color: theme.palette.common.white } }}
                                key={`${textfield.id}-${i}-signup`}
                                focused
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {textfield.icon}
                                        </InputAdornment>
                                    )
                                }}
                                value={textfield.value}
                                variant="standard"
                                type={textfield.type}
                                label={textfield.label}
                                disabled={textfield.disabled}
                                autoComplete={textfield.autoComplete}
                                error={textfields[textfield.id].error}
                                onKeyPress={handleAnyKeyPressed}
                                onChange={handleUpdateTextfield(Object.keys(textfields)[i])}
                                onSubmit={handleSubmitTextfield}
                            />
                        ))
                    }
                    <Stack direction="row" mt={1} spacing={1} justifyContent={"space-between"}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={handleRedeemToken}
                            endIcon={<ArrowRightAlt />}
                        >
                            Sign Up
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => navigate(AUTH)}
                        >
                            Login
                        </Button>
                    </Stack>
                </form>
            </Grid>
        </Grid>
    )
}
