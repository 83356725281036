import React, { useState } from 'react';

import * as ls from '../../../utils/helper/ls-vars';
import * as bintu from '../../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';

import AddTags from '../../create/AddTags';
import SectionContainer from '../../global/SectionContainer';
import SnackbarMessage from '../../global/SnackbarMessage';
import ContentTable from '../../global/ContentTable';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function DeleteProfile(props) {
    const classes = useStyles();
    const [details, setDetails] = useState(props.details);
    const [groupid, setGroupid] = useState(props.groupid);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});


    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleDelete = () => {

        let data = {
            streamid: details.id,
            groupid
        }

        bintu.deleteTranscodingProfile(data)
            .then((response) => {
                if (response.success) {
                    props.handleUpdateTranscode(response.data.playout.rtmp)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <SectionContainer
                    small
                    contrast
                    noMargin
                    title={`Profile ${details?.index}: Transcoding Settings (Pending Removal)`}
                >
                    <ContentTable
                        auto
                        data={[
                            ...(details?.info ? [{
                                label: "Resolution",
                                value: `${details?.info.width === 0
                                    ? "as input"
                                    : `${details?.info.width
                                        ? `${details?.info.width}x${details?.info.height}`
                                        : `n/a`
                                    }`
                                    }`,
                            }] : []),
                            ...(details?.info ? [{ label: "Bitrate", value: `${details?.info.bitrate} kbit/s` }] : []),
                            ...(details?.info ? [{
                                label: "Framerate",
                                value: `${details?.info.fps === 0
                                    ? "as input"
                                    : `${details?.info.fps
                                        ? `${details?.info.fps} fps`
                                        : `n/a`
                                    }`
                                    }`
                            }] : []),
                        ]}
                    />
                </SectionContainer>
                <Typography variant="body1" mt={1}>
                If you remove this profile, we'll automatically reorganize the remaining profiles in the stream group for optimal performance.

                </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleDelete}
                >
                    Confirm Deletion
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Go Back
                </Button>
            </Grid>
        </Grid>
    )
}
