import React, { useEffect, useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as ls from '../../utils/helper/ls-vars';
import * as m from '../../utils/helper/methods';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Collapse, Button, Grid, Tooltip, Typography } from '@mui/material';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import Ingest from '../create/Ingest';
import AddTags from '../create/AddTags';
import AddTranscoding from '../create/AddTranscoding';
import AddMetadata from '../create/AddMetadata';
import AddLiveProcessing from '../create/AddLiveProcessing';
import SnackbarMessage from '../global/SnackbarMessage';
import { usePermission } from '../../utils/helper/hooks';
import permissionConfig from '../../utils/permissions/permission_config';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    button: {
        margin: `${theme.spacing(0.5, 1, 0, 0)} !important`,
    },
}));

const OptionsButton = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.add);
    const [enabled, setEnabled] = useState(props.enabled);

    const handleClickedButton = () => {
        props.clicked(props.title);
    }

    useEffect(() => {
        if (props.add !== open) setOpen(props.add);
    }, [props.add])

    useEffect(() => {
        if (props.enabled !== enabled) setEnabled(props.enabled)
    }, [props.enabled])

    return (
        enabled ? (
            <Button
                size="small"
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={handleClickedButton}
                startIcon={open ? <RemoveOutlined /> : <AddOutlined />}
            >
                {open ? "Remove" : "Add"} {props.title}
            </Button>
        ) : (
            <Tooltip title={`Contact Sales to enable ${props.title}.`}>
                <span>
                    <Button
                        className={classes.button}
                        size="small"
                        disabled
                        variant="outlined"
                        startIcon={<AddOutlined />}
                    >
                        Add {props.title}
                    </Button>
                </span>
            </Tooltip>
        )
    );
}

export default function CreateStream(props) {
    const classes = useStyles();
    const canCreateStream = usePermission(permissionConfig.permissionMasks.stream.createStream);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [tags, setTags] = useState([]);

    const updateTagList = (list) => {
        setTags(list);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleCreateNewStream = async () => {
        if (!canCreateStream) return;
        let data = {
            ...(tags.length > 0 && { tags }),
        }

        bintu.createNewStream(data)
            .then((response) => {
                if (response.success) {
                    let stream = response.data;
                    props.newStream(stream)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <SectionHeader
                    title="Create new Stream"
                    underline="Create a new live stream and use the nanoStream Webcaster to ingest it to your audience."
                />
            </Grid>
            <Grid item xs={12}>
                <AddTags
                    title="Add Tags"
                    underline="Add tag(s) that you can use to identify your stream more easily in the future."
                    updateTagList={updateTagList}
                />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Button
                    disabled={!canCreateStream}
                    size="small"
                    variant="contained"
                    onClick={handleCreateNewStream}
                >
                    Create new Stream
                </Button>
            </Grid>
        </Grid>
    )
}
