import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { CONTACT, DASHBOARD } from '../../utils/helper/link-config';
import { Dashboard, DashboardOutlined, HeadsetMicOutlined } from '@mui/icons-material';

export default function NoAccess(props) {
    const theme = useTheme();
    const { content } = props;
    const navigate = useNavigate();

    return (
        <Grid container sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
                <Typography variant="h1">
                    403
                </Typography>
                <Typography variant="h3" color="primary">
                    Forbidden
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    You're trying to access <b>{content}</b>. That is not permitted with your current access rights. <br />
                    Please refer to your system administrator.
                </Typography>
                <Stack
                    useFlexGap spacing={2} sx={{ mt: 1 }}
                    direction="row" justifyContent={"center"}
                >
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(DASHBOARD)}
                        startIcon={<DashboardOutlined />}
                    >
                        Go to dashboard
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => window.open(CONTACT, "_blank")}
                        startIcon={<HeadsetMicOutlined />}
                    >
                        Talk to our support
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    )
}
