export const RESOLUTION = [
    { width: 320, height: 240 },
    { width: 640, height: 360 },
    { width: 640, height: 480 },
    { width: 800, height: 600 },
    { width: 1280, height: 720 },
    { width: 1920, height: 1080 },
];

export const FRAMERATE = [
    3, 5, 6, 10, 15, 20, 25, 30
];

// kbps
export const AUDIO_BITRATE = [
    8, 16, 32, 56, 64, 128, 256, 512
];

// kbps
export const VIDEO_BITRATE = [
    { label: '500 Kbps', value: 500 },
    { label: '1 Mbps', value: 1000 },
    { label: '1.5 Mbps', value: 1500 },
    { label: '2 Mbps', value: 2000 },
    { label: '2.5 Mbps', value: 2500 },
    { label: '3 Mbps', value: 3000 },
    { label: '4 Mbps', value: 4000 }
];


// webrtc resolutions https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/resolution/js/main.js
export const RES_CONSTRAINTS = {
    QVGA: { width: 320, height: { exact: 240 } },
    VGA: { width: { exact: 640 }, height: { exact: 480 } },
    HD: { width: { exact: 1280 }, height: { exact: 720 } },
    FULL_HD: { width: { exact: 1920 }, height: { exact: 1080 } },
    TV_4K: { width: { exact: 3840 }, height: { exact: 2160 } },
    CINEMA_4K: { width: { exact: 4096 }, height: { exact: 2160 } }
}
