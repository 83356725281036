import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../../utils/helper/link-config';
import * as m from '../../../utils/helper/methods';

import { makeStyles } from '@mui/styles';
import { Button, Chip, Collapse, Divider, Grid, IconButton, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CameraOutlined, CopyAllOutlined, List, LockOutlined, OpenInNew, SlowMotionVideoOutlined } from '@mui/icons-material';

import CopyButton from '../../global/CopyButton';
import SectionContainer from '../../global/SectionContainer';
import CodeSnippet from '../../player/CodeSnippet';
import MiniPlaybackToken from '../../create/MiniPlaybackToken';
import ContentTable from '../../global/ContentTable';
import IFrameCodeSnippet from '../../player/IFrameCodeSnippet';

export default function NewStreamOverview(props) {
    const navigate = useNavigate();
    const stream = props.stream;
    const playerToken = props.token;
    const isSecure = props.secure ? (props.token ? props.secure : false) : false;

    const [openSecureTokenPanel, setOpenSecureTokenPanel] = useState(false);
    const [token, setToken] = useState(playerToken);
    const ingest = [
        { label: "RTMP Ingest Streamname", value: stream.ingest.rtmp?.streamname, copy: true },
        { label: "RTMP Ingest Url", value: stream.ingest.rtmp?.url, copy: true }
    ];

    let securitySuffix = isSecure ? `?security.jwtoken=${token}` : "";
    let embedUrlSuffix = stream.playout.rtmp.length > 1 ? `group.id=${stream.id}${securitySuffix}` : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname=${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${playerToken}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
    const [playoutURL, setPlayoutURL] = useState([
        { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${securitySuffix}`, copy: true },
        { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true }
    ]);

    const overview = [{ label: "Stream Info", link: `${link.BASE}${link.STREAM}/${stream.id}`, copy: true }];

    const [hash, setHash] = useState([
        ...(isSecure ? [
            { label: "Token", value: token.token, copy: true },
            { label: "Expires", exp: moment.unix(token.expires).utc().format('MM/DD/YYYY hh:mm a (UTC)') },
            { label: "Tag", tag: token.tag },
            { label: "Options", value: token.options },
        ] : [])
    ]);

    const openInNewTab = () => { window.open(link.H5LIVE_DOCS, '_blank') }
    const handleRedirect = (link) => () => { navigate(link); }
    const handleExpandSecureToken = () => { setOpenSecureTokenPanel(!openSecureTokenPanel); }

    const handleNewSecurityOptions = (newToken) => {
        let decodedToken = m.DECODE_TOKEN(newToken);
        if (decodedToken) {
            setHash([
                { label: "JWT token", value: newToken, copy: true },
                { label: "Not Before (nbf)", nbf: moment.unix(decodedToken.nbf).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                { label: "Expires (exp)", exp: moment.unix(decodedToken.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                ...(decodedToken.orgahash ? [{ label: "Entire Organisation", bool: { is: true }, copy: false }] : []),
                ...(decodedToken.streams ? [{ label: "Stream name(s)", value: `[${decodedToken.streams.map(s => s)?.join(", ")}]`, copy: true }] : []),
                ...(decodedToken.tag ? [{ label: "Tag", tag: decodedToken.tag }] : []),
                ...(decodedToken.ip ? [{ label: "IP", value: decodedToken.ip, copy: true }] : []),
                ...(decodedToken.domain ? [{ label: "Domain", link: decodedToken.domain, copy: true }] : []),
            ]);
        }

        if (token !== newToken) {
            securitySuffix = isSecure ? `?security.jwtoken=${newToken}` : "";
            embedUrlSuffix = isSecure
                ? stream.playout.rtmp.length > 1 ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${newToken}` : ""}` : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname = ${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${newToken}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
                : embedUrlSuffix;
            let newPlayoutList = playoutURL;
            newPlayoutList[0] = { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${isSecure ? `${securitySuffix}` : ""}`, copy: true }
            newPlayoutList[1] = { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true };
            setPlayoutURL(newPlayoutList);
        }

        setToken(newToken);
        setOpenSecureTokenPanel(false);
    }

    useEffect(() => {
        if (isSecure && playerToken) handleNewSecurityOptions(playerToken)
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Stack direction="row" spacing={1} useFlexGap flexWrap={"wrap"}>
                    <Button
                        size="small" variant="contained"
                        startIcon={<List />}
                        onClick={handleRedirect(`${link.STREAM}/${stream.id}`)}
                    >
                        Stream Overview
                    </Button>
                    <Button
                        size="small" variant="contained"
                        startIcon={<CameraOutlined />}
                        onClick={handleRedirect(`${link.WEBCASTER}/${stream.id}`)}
                    >
                        Open nanoStream Webcaster
                    </Button>
                    <Button
                        size="small" variant="contained"
                        startIcon={<SlowMotionVideoOutlined />}
                        onClick={handleRedirect(`${link.PLAYOUT}/${stream.id}${securitySuffix}`)}
                    >
                        Watch Stream
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <SectionContainer
                    title="1. Start the Ingest" noMargin
                    underline="Copy the info below to your Live Encoder software or hardware (e.g.OBS), or use the nanoStream Webcaster."

                >
                    <ContentTable data={ingest} />
                </SectionContainer>
            </Grid>
            <Grid item xs={12}>
                <SectionContainer
                    title="2. Access the Web Playout"
                    button="nanoPlayer Documentation"
                    interact noMargin
                    icon={<OpenInNew />}
                    clicked={openInNewTab}
                    underline={
                        isSecure
                            ? 'This live playout url contains a standard token that is valid for your entire organization. It is valid for 24 hours. If you want to change the security setup for this stream, create a new token by clicking the "Create new Playback Token" button in 2.1.'
                            : 'Use this live playout url to access the playback of the stream.'
                    }
                >
                    <ContentTable data={playoutURL} />
                </SectionContainer>
            </Grid>
            {
                isSecure &&
                <Grid item xs={12}>
                    <SectionContainer
                        contrastBorder interact noMargin title="2.1 Playback Token"
                        underline="This is the playback token that you apply once you click on the web playout link."
                        button="Create new Playback Token"
                        icon={<LockOutlined />}
                        clicked={handleExpandSecureToken}
                    >
                        <Collapse in={openSecureTokenPanel}>
                            <Divider sx={{ mt: 2 }} />
                            <MiniPlaybackToken
                                groupid={stream.id}
                                handleUpdateToken={handleNewSecurityOptions}
                            />
                        </Collapse>
                        <ContentTable data={hash} />
                    </SectionContainer>
                </Grid>
            }
            <Grid item xs={12}>
                <IFrameCodeSnippet stream={stream} security={token} />
                <CodeSnippet stream={stream} security={token} />
            </Grid>
            <Grid item xs={12}>
                <SectionContainer noMargin title="3. Stream Overview">
                    <ContentTable data={overview} />
                </SectionContainer>
            </Grid>
        </Grid >

    )
}
