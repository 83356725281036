import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Drawer, Fab, Grid, IconButton, Stack, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import { countriesWithCoordinates } from '../../utils/helper/country-coords';
import { render } from 'react-dom';
import { Close, OpenInFull } from '@mui/icons-material';
import PopUpOverlay from './PopUpOverlay';
import SectionHeader from '../../../components/global/SectionHeader';
import BreakDownDrawer from './BreakDownDrawer';

window.mapboxgl.accessToken = 'pk.eyJ1IjoiYW5uaW5pbmFubyIsImEiOiJjbHdhaWczaWwwZTNtMmxzMmd2aDRmMnliIn0.jA58bmXspRtWGIpR88DN0A';

const MapMarker = (props) => {
    const theme = useTheme();
    const { total } = props;

    return (
        <Fab size="small" color="warning" sx={{ boxShadow: 0, bgcolor: "#ed7d0e", color: "#fff" }}>
            <Typography variant="overline">
                {total}
            </Typography>
        </Fab>
        // <Box
        //     component={"div"}
        //     sx={{
        //         px: 1, borderRadius: 5,
        //         bgcolor: "#ed7d0e", border: '1px solid #ed7d0e', color: '#fff'
        //     }}
        // >
        //     <Typography variant="body2">
        //         {total}
        //     </Typography>
        // </Box>
    )
}

export default function Map(props) {
    const { data } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [focusedCountry, setFocusedCountry] = useState(null);
    const [expandedCountry, setExpandedCountry] = useState(null);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [zoom, setZoom] = useState(1);

    const hideFocusedCountry = () => { setFocusedCountry(null) }
    const hideExpandedCountry = () => { setExpandedCountry(null) }
    const expandFocusedCountry = () => { setExpandedCountry(focusedCountry) }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setLng(longitude);
            setLat(latitude);
        });

        if (map.current) return;

        map.current = new window.mapboxgl.Map({
            container: mapContainer.current,
            projection: 'mercator',
            style: 'mapbox://styles/mapbox/light-v11',
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false
        });

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(3));
        });

        map.current.on('load', () => { map.current.resize(); });

    }, []);

    useEffect(() => {
        if (data && map.current !== null) {
            data.forEach((d) => {
                const country = countriesWithCoordinates.find((c) => c.alpha2 === d.alpha2);
                if (country) {
                    const markerNode = document.createElement('div');
                    render(<MapMarker total={d.total?.count} />, markerNode);
                    const marker = new window.mapboxgl.Marker(markerNode)
                        .setLngLat([country.longitude, country.latitude])
                        .addTo(map.current);

                    marker.getElement().addEventListener('click', () => {
                        map.current.flyTo({
                            center: [country.longitude, country.latitude],
                            essential: true, zoom: 5
                        });
                        setFocusedCountry(d);
                    });

                    marker.getElement().addEventListener('dblclick', () => { setExpandedCountry(d); });
                }
            })
        }
    }, [data, map.current])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ position: 'relative' }}>
                {
                    focusedCountry &&
                    <PopUpOverlay
                        data={focusedCountry}
                        open={expandFocusedCountry}
                        close={hideFocusedCountry}
                    />
                }
                <Box
                    component="div"
                    height={'70vh'}
                    ref={mapContainer}
                    className="map-container"
                />
            </Grid>
            <Grid item xs={12}>
                <Drawer
                    anchor="right"
                    variant="temporary"
                    open={expandedCountry}
                    onClose={hideExpandedCountry}
                >
                    <BreakDownDrawer
                        close={hideExpandedCountry}
                        data={expandedCountry}
                    />
                </Drawer>
            </Grid>
        </Grid>
    )
}
