import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function SmallCountryRangeFilter(props) {
    const classes = useStyles();
    const [filterSelection] = useState({
        1: { index: 1, country: "germany", title: "Germany" },
        2: { index: 2, country: "croatia", title: "Croatia" },
        3: { index: 3, country: "belgium", title: "Belgium" }
    });
    const [selectedCountry, setSelectedCountry] = useState({
        index: 1,
        country: filterSelection[1].country
    })

    const handleUpdateCountry = (event) => {
        let index = event.target.value;
        setSelectedCountry({
            index: index,
            country: filterSelection[index].country,
        })
    }

    useEffect(() => {
        props.updateFilter({ country: selectedCountry.country });
    }, [selectedCountry])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <FormControl size="small">
                    <Select
                        value={selectedCountry.index}
                        onChange={handleUpdateCountry}
                    >
                        {
                            Object.values(filterSelection).map((filter, index) => (
                                <MenuItem
                                    key={filter.value}
                                    value={index + 1}
                                >
                                    {filter.title}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

    )
}
