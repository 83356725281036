import React, { useEffect, useState } from 'react';

import * as ls from '../../../utils/helper/ls-vars';
import * as bintu from '../../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { TextField, Grid, FormControl, Select, MenuItem, Button } from '@mui/material';

import SectionContainer from '../../global/SectionContainer';
import SnackbarMessage from '../../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    item: {
        textTransform: 'capitalize'
    }
}));

export default function UpdateLiveProcessing(props) {
    const classes = useStyles();
    const [process, setProcess] = useState(props.opcode === null ? "none" : props.opcode);
    // const [options] = useState(["none", "thumbs"]);
    const [options] = useState([
        { title: "None", id: "none" },
        { title: "Thumbnail", id: "thumbs" },
    ])
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleUpdateLiveProcessing = (event) => {
        let newProcess = event.target.value;
        setProcess(newProcess);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdateProcessing = () => {
        let data = {
            streamid: props.streamid,
            opcode: process
        }

        bintu.setOpcode(data)
            .then((response) => {
                if (response.success) {
                    props.updated(response.data.opcode)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    console.log(process)


    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <FormControl>
                    <Select
                        className={classes.item}
                        variant="standard"
                        color="primary"
                        fullWidth
                        value={process}
                        onChange={handleUpdateLiveProcessing}
                    >
                        {
                            options.map((o) => (
                                <MenuItem className={classes.item} key={o.id} value={o.id}>
                                    {o.title}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleUpdateProcessing}
                >
                    Update
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )
}
