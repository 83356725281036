import React, { useState, useEffect, useRef, createRef, Fragment } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../utils/helper/link-config';
import * as m from '../../utils/helper/methods';
import * as nanoplayer from '../../utils/bintu/nanoplayer-requests';
import * as bintu from '../../utils/bintu/api-requests';

import notfound_light from '../../assets/StreamNotFound_light.png';
import notfound_dark from '../../assets/StreamNotFound_dark.png';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Grid, Typography, Button, Collapse, Chip, Divider } from '@mui/material';
import { Code, GroupOutlined, HeadsetMicOutlined, LockOutlined, MultilineChartOutlined, OpenInNew, Settings } from '@mui/icons-material';

import LatencyControlMode from './LatencyControlMode';
import CodeSnippet from './CodeSnippet';
import PlayerStats from './PlayerStats';
import SectionHeader from '../global/SectionHeader';
import SectionContainer from '../global/SectionContainer';
import PlayerUnderline from './PlayerUnderline';
import ContentTable from '../global/ContentTable';
import IFrameCodeSnippet from './IFrameCodeSnippet';
import ProfileSetter from './ProfileSetter';
import MiniPlaybackToken from '../create/MiniPlaybackToken';
import StopStream from '../stream/StopStream';
import { usePermission } from '../../utils/helper/hooks';
import permissionConfig from '../../utils/permissions/permission_config';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    notFound: {
        textAlign: 'center',
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    },
    player: {
        paddingTop: '56.25% !important',
        maxWidth: '100%',
        top: 0,
        left: 0,
        width: "100%",
    },
}));

export default function Player(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    let playerId = "nanoStream-h5livePlayer";
    let stream = props.stream;
    let serverDomain = props.customServerDomain;

    const canStopIngest = usePermission(permissionConfig.permissionMasks.streamWithId.stopIngest);
    const canUnlockStream = usePermission(permissionConfig.permissionMasks.streamWithId.unlockStream);

    const playerToken = props.token;
    const [token, setToken] = useState(playerToken);
    const [openStop, setOpenStop] = useState(false);
    const isSecure = props.isExternal
        ? props.token ? true : false
        : props.token ? true : props.isSecure


    const [streamInfo, setStreamInfo] = useState(false);
    const [player, setPlayer] = useState(null);
    const playerRef = useRef(player);
    const [playerVersion, setPlayerVersion] = useState("...");
    const [playerState, setPlayerState] = useState(nanoplayer.GET_STATE(1));
    const [playerStats, setPlayerStats] = useState({
        bitrate: { avg: 0, current: 0, deviation: 0, max: 0, min: 0 },
        buffer: { end: 0, start: 0, delay: { avg: 0, current: 0, deviation: 0, max: 0, min: 0 } },
        framerate: { avg: 0, current: 0, deviation: 0, max: 0, min: 0 },
    });
    const [openSecureTokenPanel, setOpenSecureTokenPanel] = useState(false);

    const [latencyControlMode, setLatencyControlMode] = useState("balancedadaptive");
    const [adaptionRule, setAdaptionRule] = useState({ index: 0, id: "deviationOfMean2" });
    const [openStats, setOpenStats] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [error, setError] = useState({
        isError: false,
        code: 0,
        message: ""
    });

    let securitySuffix = isSecure ? `?security.jwtoken=${token}` : "";
    let embedUrlSuffix = stream.playout.rtmp.length > 1 ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${token}` : ""}` : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname=${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${playerToken}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
    const [playoutURL, setPlayoutURL] = useState([
        { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${isSecure ? `${securitySuffix}` : ""}`, copy: true },
        { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true }
    ]);
    const [hash, setHash] = useState([
        ...(isSecure ? [
            { label: "Token", value: token.token, copy: true },
            { label: "Expires", exp: moment.unix(token.expires).utc().format('MM/DD/YYYY hh:mm a (UTC)') },
            { label: "Tag", tag: token.tag },
            { label: "Options", value: token.options },
        ] : [])
    ]);

    const openInNewTab = (link) => () => {
        window.open(link, '_blank')
    }

    const getStateCode = (code) => {
        setPlayerState(nanoplayer.GET_STATE(code))
    }

    // const handleOpenSettings = () => {
    //     setOpenSettings(!openSettings)
    // }

    // const handleOpenStats = () => {
    //     setOpenStats(!openStats);
    // }

    // const openErrorMessage = (event) => {
    //     setError({
    //         ...error,
    //         isError: !error
    //     });
    // }

    const handleOpenDocs = () => {
        window.open(`${link.H5LIVE_DOCS_ERROR_CODES}`, "_blank")
    }

    const handleErrorMessage = (error) => {
        getStateCode(error.state);
        if (error.data.code === 4002) return;
        setError({
            isError: true,
            code: error.data.code,
            message: error.data.message
        });
    }

    const handleStats = (event) => { setPlayerStats(event.data.stats); }
    const handleSetState = (event) => { getStateCode(event.state); }
    const handleSetStreamInfo = (event) => { setStreamInfo(event.data.streamInfo) }
    const handleSetSwitchStream = (event) => { getStateCode(event.state); }
    const handleRedirect = (link) => () => { navigate(link); }
    const handleUpdateLatencyControlMode = (mode) => { setLatencyControlMode(mode); }
    const handleExpandSecureToken = () => { setOpenSecureTokenPanel(!openSecureTokenPanel) }
    const handleStopStream = () => { setOpenStop(!openStop) }

    const setup = (stream) => {
        let config = {
            source: {
                group: {
                    id: stream.id,
                    apiurl: bintu.BINTU_API,
                    ...isSecure && {
                        security: {
                            jwtoken: playerToken
                        }
                    }
                },
                ...serverDomain && {
                    general: {
                        serverDomain: serverDomain
                    },
                },
                // entries,
                options: {
                    adaption: {
                        rule: adaptionRule.id
                    },
                },
                // metrics: {
                //     accountId: "YOUR-METRICS-ACCOUNT-ID", // replace with your metrics account id
                //     accountKey: "YOUR-METRICS-ACCOUNT-KEY", // replace with your metrics account key
                //     userId: "viewer1", // value can be changed per viewer
                //     eventId: "event1", // value can be changed per event
                //     statsInterval: 10, // statistics interval in seconds
                //     customField1: "CustomInfo1" // value can be changed
                // },
                startIndex: adaptionRule.index
            },
            events: {
                onStats: handleStats,
                onReady: handleSetState,
                onPlay: handleSetState,
                onPause: handleSetState,
                onLoading: handleSetState,
                onWarning: handleSetState,
                onDestroy: handleSetState,

                onStateBuffering: handleSetState,
                onStopBuffering: handleSetState,

                onUpdateSourceInit: handleSetState,
                onUpdateSourceSuccess: handleSetState,

                onSwitchStreamInit: handleSetSwitchStream,
                onSwitchStreamSuccess: handleSetSwitchStream,
                onStreamInfo: handleSetStreamInfo,
                onStreamInfoUpdate: handleSetStreamInfo,

                onError: handleErrorMessage,
                onUpdateSourceFail: handleErrorMessage,
                onSwitchStreamFail: handleErrorMessage,

            },
            playback: {
                autoplay: true,
                automute: true,
                latencyControlMode: latencyControlMode,
                faststart: true

            },
            style: {
                width: 'auto',
                height: 'auto'
            }
        };
        nanoplayer.setup({ player, config })
    }


    const initPlayer = () => {
        let h5livePlayer = new window.NanoPlayer(playerId);
        setPlayer(h5livePlayer);
    }

    const handleSetAdaption = (adaption) => {
        let data = {
            player,
            rule: adaption
            // downstep ?
        }
        setAdaptionRule({ ...adaptionRule, id: adaption });
        if (adaption === "deviationOfMean2") nanoplayer.setAdaption(data)
    }

    const handleSwitchStream = (index) => {
        let data = {
            player,
            index
        }

        setAdaptionRule({ ...adaptionRule, index: index })
        nanoplayer.switchStream(data)
    }

    const removePlayer = () => {
        if (playerRef.current) {
            nanoplayer.destroy(playerRef.current);
            setPlayer(null);
        }
    }


    const handleNewSecurityOptions = (newToken) => {
        let decodedToken = m.DECODE_TOKEN(newToken);
        if (decodedToken) {
            setHash([
                { label: "JWT token", value: newToken, copy: true },
                { label: "Not Before (nbf)", nbf: moment.unix(decodedToken.nbf).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                { label: "Expires (exp)", exp: moment.unix(decodedToken.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                ...(decodedToken.orgahash ? [{ label: "Entire Organisation", bool: { is: true }, copy: false }] : []),
                ...(decodedToken.streams ? [{ label: "Stream name(s)", value: `[${decodedToken.streams.map(s => s)?.join(", ")}]`, copy: true }] : []),
                ...(decodedToken.tag ? [{ label: "Tag", tag: decodedToken.tag }] : []),
                ...(decodedToken.ip ? [{ label: "IP", value: decodedToken.ip, copy: true }] : []),
                ...(decodedToken.domain ? [{ label: "Domain", link: decodedToken.domain, copy: true }] : []),
            ]);
        }

        if (token !== newToken) {
            securitySuffix = isSecure ? `?security.jwtoken=${newToken}` : "";
            embedUrlSuffix = isSecure
                ? stream.playout.rtmp.length > 1 ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${newToken}` : ""}` : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname = ${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${newToken}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
                : embedUrlSuffix;
            let newPlayoutList = playoutURL;
            newPlayoutList[0] = { label: "Live Playout Url", link: `${link.BASE}${link.PLAYOUT}/${stream.id}${isSecure ? `${securitySuffix}` : ""}`, copy: true }
            newPlayoutList[1] = { label: "iFrame Embed Url", link: `${link.H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true };
            setPlayoutURL(newPlayoutList);
        }

        window.history.pushState({}, "", `${decodedToken.orgahash ? "" : `${stream.id}?security.jwtoken=${newToken}`}`);


        setToken(newToken);
        setOpenSecureTokenPanel(false);
    }


    useEffect(() => {
        setup(stream, props.security);
    }, [latencyControlMode])

    useEffect(() => {
        playerRef.current = player;
    }, [player])

    useEffect(() => {
        if (window.NanoPlayer && player === null) {
            initPlayer();
            if (isSecure && playerToken) handleNewSecurityOptions(playerToken)
            // document.getElementById(playerId).style.zIndex = 2147483648
        }
    }, [props.stream, playerToken])

    useEffect(() => {
        if (player) {
            setPlayerVersion(player.version)
            setup(stream, props.security);
        }
    }, [player])

    useEffect(() => {
        return () => {
            removePlayer();
        }
    }, [])

    return (
        <Fragment>
            <StopStream
                open={openStop} streamid={stream.id} state={stream.state}
                cancel={handleStopStream}
                action={handleRedirect(0)}
            />
            <Grid container mb={1}>
                <Grid item xs={12} mb={1}>
                    <PlayerUnderline
                        isTranscode={stream.playout.rtmp.length > 1}
                        isSecure={props.isSecure} stream={stream} version={playerVersion}
                        playerState={playerState} isExternal={props.isExternal}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SectionHeader
                        interact noMargin title={stream.id}
                        underline={`Playing live stream with nanoStream H5Live Player`}
                        button={props.isExternal ? false : "Go to Stream Overview"}
                        icon={<OpenInNew />}
                        clicked={openInNewTab(`${link.STREAM}/${stream.id}`)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ my: 1 }} />
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent={props.isExternal ? "center" : "flex-start"}>
                <Grid item xs={12} md={6} className={classes.playerWrapper}>
                    <div className={classes.player} id={playerId} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <PlayerStats
                        playerState={playerState}
                        streamInfo={streamInfo}
                        stream={stream}
                        isExternal={props.isExternal}
                        stats={playerStats}
                    />
                    {
                        stream.playout.rtmp.length > 1
                        &&
                        <ProfileSetter
                            streamInfo={streamInfo}
                            stream={stream}
                            adaptionRule={adaptionRule}
                            switchStream={handleSwitchStream}
                            setAdaption={handleSetAdaption}
                        />
                    }
                    <LatencyControlMode
                        streamInfo={streamInfo}
                        stream={stream}
                        adaptionRule={adaptionRule}
                        latencyControlMode={latencyControlMode}
                        switchStream={handleSwitchStream}
                        setAdaption={handleSetAdaption}
                        updateLatencyControlMode={handleUpdateLatencyControlMode}
                    />
                </Grid>
                {
                    isSecure && !props.isExternal
                    &&
                    <Grid item xs={12} noMargin>
                        <SectionContainer
                            title="Playback Token"
                            underline="This is the playback token that you apply once you click on the web playout link."
                            button="Create new Playback Token"
                            interact
                            icon={<LockOutlined />}
                            clicked={handleExpandSecureToken}
                        >
                            <Collapse in={openSecureTokenPanel}>
                                <Divider sx={{ mt: 2 }} />
                                <MiniPlaybackToken
                                    groupid={stream.id}
                                    handleUpdateToken={handleNewSecurityOptions}
                                />
                            </Collapse>
                            <ContentTable data={hash} />
                        </SectionContainer>
                    </Grid>
                }
                <Grid
                    item
                    xs={12}
                >
                    <SectionContainer title="Playout URLs" noMargin>
                        <ContentTable data={playoutURL} />
                    </SectionContainer>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.item}
                >
                    <IFrameCodeSnippet
                        stream={props.stream}
                        adaptionRule={adaptionRule}
                        latencyControlMode={latencyControlMode}
                        security={token}
                    />
                    <CodeSnippet
                        button={"nanoPlayer Latest Release"}
                        interact
                        icon={<OpenInNew />}
                        clicked={openInNewTab(link.H5LIVE_LATEST_RELEASE)}
                        stream={props.stream}
                        adaptionRule={adaptionRule}
                        latencyControlMode={latencyControlMode}
                        security={token}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    {
                        canStopIngest &&
                        <SectionContainer
                            noMargin caution contrastBorder
                            title={stream.state === "locked" ? "Unlock this stream" : "Stop this stream"}
                            underline={stream.state === "locked" ? "By unlocking this stream you can ingest again." : "By stopping this stream you will not be able to ingest as long as the state is locked."}
                        >
                            <Button
                                color="error" variant="outlined" size="small" sx={{ mt: 1 }}
                                disabled={(stream.state === "locked" && !canUnlockStream) || stream.state !== "locked" && !canStopIngest}
                                onClick={handleStopStream}
                            >
                                {stream.state === "locked" ? "Unlock" : "Stop"}
                            </Button>
                        </SectionContainer>
                    }
                </Grid>
            </Grid>
        </Fragment>

    )
}
