import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, IconButton, Stack, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Close, OpenInFull, OpenWith, QueryStats } from '@mui/icons-material';

export default function BreakDownDrawer(props) {
    const { data, close } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Grid container sx={{ p: 2 }}>
            <Grid item xs={12}>
                <Stack direction="row" alignItems="flex-start" justifyContent={"space-between"} spacing={2}>
                    <Box>
                        <Typography variant="h6" color={theme.palette.primary.main} sx={{ fontWeight: 700 }}>
                            {data?.country}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            Breakdown of Playouts/Ingests
                        </Typography>
                    </Box>
                    <IconButton size="small" onClick={close}>
                        <Close fontSize="inherit" />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    )
}
