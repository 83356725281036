import React, { useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';

import AddTags from '../create/AddTags';
import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function UpdateTags(props) {
    const classes = useStyles();
    const [tags, setTags] = useState(props.tags);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const setUpdatedTagList = (newTagList) => {
        setTags(newTagList);
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdateStreamTags = () => {
        // TO DO post updaet
        // on success props to overview
        let data = {
            streamid: props.streamid,
            tags
        }

        bintu.setTags(data)
            .then((response) => {
                if (response.success) {
                    props.updated(response.data.tags)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    return (
        <Grid container className={classes.root}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <AddTags
                    tags={props.tags}
                    unsetRoot={true}
                    updateTagList={setUpdatedTagList}
                />
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="contained"
                    onClick={handleUpdateStreamTags}
                >
                    Update
                </Button>
                <Button
                    size="small"
                    sx={{ mr: 1, mt: 0.5 }}
                    variant="outlined"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )
}
