import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { Button, Collapse, FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { AlarmOutlined } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import ContentDialog from '../global/ContentDialog';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        paddingBottom: theme.spacing(2)
    },
}));

export default function DateFilter(props) {
    const classes = useStyles();
    const { from, until } = { ...props };
    const [openDialog, setOpenDialog] = useState(false);
    const [dates, setDates] = useState({ from, until });
    const [updatedTimerange, setUpdatedTimerange] = useState(false);

    const handleResetFilter = () => {
        setDates({ from, until });
        setUpdatedTimerange(false);
    }

    const handleUpdateDate = (identifier) => (date) => {
        if (!isNaN(date.valueOf())) {
            setUpdatedTimerange(true);
            let updatedDate = date?.toISOString();
            setDates({
                ...dates,
                [identifier]: updatedDate
            });
        }
    };

    const handleSelectDates = () => {
        props.update(dates);
        handleOpenDialog();
    }

    const handleOpenDialog = () => {
        setOpenDialog(!openDialog)
    }

    return (
        <Fragment>
            <Button
                size="small"
                variant={updatedTimerange ? "contained" : "outlined"}
                startIcon={<AlarmOutlined />}
                onClick={handleOpenDialog}
            >
                {
                    updatedTimerange
                        ? `${moment(dates.from).format("D MMM YY")} - ${moment(dates.until).format("D MMM YY")}`
                        : "Select Dates"
                }
            </Button>
            <ContentDialog
                open={openDialog}
                submitButton={"Set date"}
                submit={handleSelectDates}
                close={handleOpenDialog}
                title={"Select Dates"}
                underline={"Select a time range and filter your streams."}
                content={
                    <Grid container className={classes.root}>
                        {/* <Grid item xs={12} mb={1}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={selectedDate}
                                >
                                    {
                                        dates.map((date, i) => (
                                            <FormControlLabel
                                                key={i}
                                                value={date.value}
                                                onChange={handleSelectDate}
                                                control={<Radio />}
                                                label={
                                                    <Typography variant="body2" color={date.value === selectedDate ? "primary" : "textSecondary"}>
                                                        {date.label}
                                                    </Typography>
                                                }
                                            />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: 1 } }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Start Date (UTC)"
                                    value={dates.from}
                                    // onOpen={() => setSelectedDate("custom")}
                                    onChange={handleUpdateDate("from")}
                                    renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pt: { xs: 1.5, sm: 0 } }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="End Date (UTC)"
                                    value={dates.until}
                                    // onOpen={() => setSelectedDate("custom")}
                                    onChange={handleUpdateDate("until")}
                                    renderInput={(params) => <TextField fullWidth variant="standard"  {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item mt={updatedTimerange ? 1 : 0}>
                            <Collapse in={updatedTimerange}>
                                <Link underline="hover" onClick={handleResetFilter}>
                                    Reset Filter
                                </Link>
                            </Collapse>
                        </Grid>
                    </Grid>
                }
            />
        </Fragment>
    )
}
