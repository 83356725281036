export const countriesWithCoordinates = [
    {
        "country": "Afghanistan",
        "alpha2": "AF",
        "latitude": 33.93911,
        "longitude": 67.709953
    },
    {
        "country": "Albania",
        "alpha2": "AL",
        "latitude": 41.153332,
        "longitude": 20.168331
    },
    {
        "country": "Algeria",
        "alpha2": "DZ",
        "latitude": 28.033886,
        "longitude": 1.659626
    },
    {
        "country": "Andorra",
        "alpha2": "AD",
        "latitude": 42.546245,
        "longitude": 1.601554
    },
    {
        "country": "Angola",
        "alpha2": "AO",
        "latitude": -11.202692,
        "longitude": 17.873887
    },
    {
        "country": "Antigua and Barbuda",
        "alpha2": "AG",
        "latitude": 17.060816,
        "longitude": -61.796428
    },
    {
        "country": "Argentina",
        "alpha2": "AR",
        "latitude": -38.416097,
        "longitude": -63.616672
    },
    {
        "country": "Armenia",
        "alpha2": "AM",
        "latitude": 40.069099,
        "longitude": 45.038189
    },
    {
        "country": "Australia",
        "alpha2": "AU",
        "latitude": -25.274398,
        "longitude": 133.775136
    },
    {
        "country": "Austria",
        "alpha2": "AT",
        "latitude": 47.516231,
        "longitude": 14.550072
    },
    {
        "country": "Azerbaijan",
        "alpha2": "AZ",
        "latitude": 40.143105,
        "longitude": 47.576927
    },
    {
        "country": "Bahamas",
        "alpha2": "BS",
        "latitude": 25.03428,
        "longitude": -77.39628
    },
    {
        "country": "Bahrain",
        "alpha2": "BH",
        "latitude": 26.0667,
        "longitude": 50.5577
    },
    {
        "country": "Bangladesh",
        "alpha2": "BD",
        "latitude": 23.684994,
        "longitude": 90.356331
    },
    {
        "country": "Barbados",
        "alpha2": "BB",
        "latitude": 13.193887,
        "longitude": -59.543198
    },
    {
        "country": "Belarus",
        "alpha2": "BY",
        "latitude": 53.709807,
        "longitude": 27.953389
    },
    {
        "country": "Belgium",
        "alpha2": "BE",
        "latitude": 50.503887,
        "longitude": 4.469936
    },
    {
        "country": "Belize",
        "alpha2": "BZ",
        "latitude": 17.189877,
        "longitude": -88.49765
    },
    {
        "country": "Benin",
        "alpha2": "BJ",
        "latitude": 9.30769,
        "longitude": 2.315834
    },
    {
        "country": "Bhutan",
        "alpha2": "BT",
        "latitude": 27.514162,
        "longitude": 90.433601
    },
    {
        "country": "Bolivia",
        "alpha2": "BO",
        "latitude": -16.290154,
        "longitude": -63.588653
    },
    {
        "country": "Bosnia and Herzegovina",
        "alpha2": "BA",
        "latitude": 43.915886,
        "longitude": 17.679076
    },
    {
        "country": "Botswana",
        "alpha2": "BW",
        "latitude": -22.328474,
        "longitude": 24.684866
    },
    {
        "country": "Brazil",
        "alpha2": "BR",
        "latitude": -14.235004,
        "longitude": -51.92528
    },
    {
        "country": "Brunei",
        "alpha2": "BN",
        "latitude": 4.535277,
        "longitude": 114.727669
    },
    {
        "country": "Bulgaria",
        "alpha2": "BG",
        "latitude": 42.733883,
        "longitude": 25.48583
    },
    {
        "country": "Burkina Faso",
        "alpha2": "BF",
        "latitude": 12.238333,
        "longitude": -1.561593
    },
    {
        "country": "Burundi",
        "alpha2": "BI",
        "latitude": -3.373056,
        "longitude": 29.918886
    },
    {
        "country": "Cambodia",
        "alpha2": "KH",
        "latitude": 12.565679,
        "longitude": 104.990963
    },
    {
        "country": "Cameroon",
        "alpha2": "CM",
        "latitude": 7.369722,
        "longitude": 12.354722
    },
    {
        "country": "Canada",
        "alpha2": "CA",
        "latitude": 56.130366,
        "longitude": -106.346771
    },
    {
        "country": "Cape Verde",
        "alpha2": "CV",
        "latitude": 16.002082,
        "longitude": -24.013197
    },
    {
        "country": "Central African Republic",
        "alpha2": "CF",
        "latitude": 6.611111,
        "longitude": 20.939444
    },
    {
        "country": "Chad",
        "alpha2": "TD",
        "latitude": 15.454166,
        "longitude": 18.732207
    },
    {
        "country": "Chile",
        "alpha2": "CL",
        "latitude": -35.675147,
        "longitude": -71.542969
    },
    {
        "country": "China",
        "alpha2": "CN",
        "latitude": 35.86166,
        "longitude": 104.195397
    },
    {
        "country": "Colombia",
        "alpha2": "CO",
        "latitude": 4.570868,
        "longitude": -74.297333
    },
    {
        "country": "Comoros",
        "alpha2": "KM",
        "latitude": -11.875001,
        "longitude": 43.872219
    },
    {
        "country": "Congo",
        "alpha2": "CG",
        "latitude": -0.228021,
        "longitude": 15.827659
    },
    {
        "country": "Costa Rica",
        "alpha2": "CR",
        "latitude": 9.748917,
        "longitude": -83.753428
    },
    {
        "country": "Croatia",
        "alpha2": "HR",
        "latitude": 45.1,
        "longitude": 15.2
    },
    {
        "country": "Cuba",
        "alpha2": "CU",
        "latitude": 21.521757,
        "longitude": -77.781167
    },
    {
        "country": "Cyprus",
        "alpha2": "CY",
        "latitude": 35.126413,
        "longitude": 33.429859
    },
    {
        "country": "Czech Republic",
        "alpha2": "CZ",
        "latitude": 49.817492,
        "longitude": 15.472962
    },
    {
        "country": "Denmark",
        "alpha2": "DK",
        "latitude": 56.26392,
        "longitude": 9.501785
    },
    {
        "country": "Djibouti",
        "alpha2": "DJ",
        "latitude": 11.825138,
        "longitude": 42.590275
    },
    {
        "country": "Dominica",
        "alpha2": "DM",
        "latitude": 15.414999,
        "longitude": -61.370976
    },
    {
        "country": "Dominican Republic",
        "alpha2": "DO",
        "latitude": 18.735693,
        "longitude": -70.162651
    },
    {
        "country": "Ecuador",
        "alpha2": "EC",
        "latitude": -1.831239,
        "longitude": -78.183406
    },
    {
        "country": "Egypt",
        "alpha2": "EG",
        "latitude": 26.820553,
        "longitude": 30.802498
    },
    {
        "country": "El Salvador",
        "alpha2": "SV",
        "latitude": 13.794185,
        "longitude": -88.89653
    },
    {
        "country": "Equatorial Guinea",
        "alpha2": "GQ",
        "latitude": 1.650801,
        "longitude": 10.267895
    },
    {
        "country": "Eritrea",
        "alpha2": "ER",
        "latitude": 15.179384,
        "longitude": 39.782334
    },
    {
        "country": "Estonia",
        "alpha2": "EE",
        "latitude": 58.595272,
        "longitude": 25.013607
    },
    {
        "country": "Eswatini",
        "alpha2": "SZ",
        "latitude": -26.522503,
        "longitude": 31.465866
    },
    {
        "country": "Ethiopia",
        "alpha2": "ET",
        "latitude": 9.145,
        "longitude": 40.489673
    },
    {
        "country": "Fiji",
        "alpha2": "FJ",
        "latitude": -17.713371,
        "longitude": 178.065032
    },
    {
        "country": "Finland",
        "alpha2": "FI",
        "latitude": 61.92411,
        "longitude": 25.748151
    },
    {
        "country": "France",
        "alpha2": "FR",
        "latitude": 46.603354,
        "longitude": 1.888334
    },
    {
        "country": "Gabon",
        "alpha2": "GA",
        "latitude": -0.803689,
        "longitude": 11.609444
    },
    {
        "country": "Gambia",
        "alpha2": "GM",
        "latitude": 13.443182,
        "longitude": -15.310139
    },
    {
        "country": "Georgia",
        "alpha2": "GE",
        "latitude": 42.315407,
        "longitude": 43.356892
    },
    {
        "country": "Germany",
        "alpha2": "DE",
        "latitude": 51.165691,
        "longitude": 10.451526
    },
    {
        "country": "Ghana",
        "alpha2": "GH",
        "latitude": 7.946527,
        "longitude": -1.023194
    },
    {
        "country": "Greece",
        "alpha2": "GR",
        "latitude": 39.074208,
        "longitude": 21.824312
    },
    {
        "country": "Grenada",
        "alpha2": "GD",
        "latitude": 12.262776,
        "longitude": -61.604171
    },
    {
        "country": "Guatemala",
        "alpha2": "GT",
        "latitude": 15.783471,
        "longitude": -90.230759
    },
    {
        "country": "Guinea",
        "alpha2": "GN",
        "latitude": 9.945587,
        "longitude": -9.696645
    },
    {
        "country": "Guinea-Bissau",
        "alpha2": "GW",
        "latitude": 11.803749,
        "longitude": -15.180413
    },
    {
        "country": "Guyana",
        "alpha2": "GY",
        "latitude": 4.860416,
        "longitude": -58.93018
    },
    {
        "country": "Haiti",
        "alpha2": "HT",
        "latitude": 18.971187,
        "longitude": -72.285215
    },
    {
        "country": "Honduras",
        "alpha2": "HN",
        "latitude": 15.199999,
        "longitude": -86.241905
    },
    {
        "country": "Hungary",
        "alpha2": "HU",
        "latitude": 47.162494,
        "longitude": 19.503304
    },
    {
        "country": "Iceland",
        "alpha2": "IS",
        "latitude": 64.963051,
        "longitude": -19.020835
    },
    {
        "country": "India",
        "alpha2": "IN",
        "latitude": 20.593684,
        "longitude": 78.96288
    },
    {
        "country": "Indonesia",
        "alpha2": "ID",
        "latitude": -0.789275,
        "longitude": 113.921327
    },
    {
        "country": "Iran",
        "alpha2": "IR",
        "latitude": 32.427908,
        "longitude": 53.688046
    },
    {
        "country": "Iraq",
        "alpha2": "IQ",
        "latitude": 33.223191,
        "longitude": 43.679291
    },
    {
        "country": "Ireland",
        "alpha2": "IE",
        "latitude": 53.41291,
        "longitude": -8.24389
    },
    {
        "country": "Israel",
        "alpha2": "IL",
        "latitude": 31.046051,
        "longitude": 34.851612
    },
    {
        "country": "Italy",
        "alpha2": "IT",
        "latitude": 41.87194,
        "longitude": 12.56738
    },
    {
        "country": "Jamaica",
        "alpha2": "JM",
        "latitude": 18.109581,
        "longitude": -77.297508
    },
    {
        "country": "Japan",
        "alpha2": "JP",
        "latitude": 36.204824,
        "longitude": 138.252924
    },
    {
        "country": "Jordan",
        "alpha2": "JO",
        "latitude": 30.585164,
        "longitude": 36.238414
    },
    {
        "country": "Kazakhstan",
        "alpha2": "KZ",
        "latitude": 48.019573,
        "longitude": 66.923684
    },
    {
        "country": "Kenya",
        "alpha2": "KE",
        "latitude": -0.023559,
        "longitude": 37.906193
    },
    {
        "country": "Kiribati",
        "alpha2": "KI",
        "latitude": -3.370417,
        "longitude": -168.734039
    },
    {
        "country": "North Korea",
        "alpha2": "KP",
        "latitude": 40.339852,
        "longitude": 127.510093
    },
    {
        "country": "South Korea",
        "alpha2": "KR",
        "latitude": 35.907757,
        "longitude": 127.766922
    },
    {
        "country": "Kuwait",
        "alpha2": "KW",
        "latitude": 29.31166,
        "longitude": 47.481766
    },
    {
        "country": "Kyrgyzstan",
        "alpha2": "KG",
        "latitude": 41.20438,
        "longitude": 74.766098
    },
    {
        "country": "Laos",
        "alpha2": "LA",
        "latitude": 19.85627,
        "longitude": 102.495496
    },
    {
        "country": "Latvia",
        "alpha2": "LV",
        "latitude": 56.879635,
        "longitude": 24.603189
    },
    {
        "country": "Lebanon",
        "alpha2": "LB",
        "latitude": 33.854721,
        "longitude": 35.862285
    },
    {
        "country": "Lesotho",
        "alpha2": "LS",
        "latitude": -29.609988,
        "longitude": 28.233608
    },
    {
        "country": "Liberia",
        "alpha2": "LR",
        "latitude": 6.428055,
        "longitude": -9.429499
    },
    {
        "country": "Libya",
        "alpha2": "LY",
        "latitude": 26.3351,
        "longitude": 17.228331
    },
    {
        "country": "Liechtenstein",
        "alpha2": "LI",
        "latitude": 47.166,
        "longitude": 9.555373
    },
    {
        "country": "Lithuania",
        "alpha2": "LT",
        "latitude": 55.169438,
        "longitude": 23.881275
    },
    {
        "country": "Luxembourg",
        "alpha2": "LU",
        "latitude": 49.815273,
        "longitude": 6.129583
    },
    {
        "country": "Madagascar",
        "alpha2": "MG",
        "latitude": -18.766947,
        "longitude": 46.869107
    },
    {
        "country": "Malawi",
        "alpha2": "MW",
        "latitude": -13.254308,
        "longitude": 34.301525
    },
    {
        "country": "Malaysia",
        "alpha2": "MY",
        "latitude": 4.210484,
        "longitude": 101.975766
    },
    {
        "country": "Maldives",
        "alpha2": "MV",
        "latitude": 3.202778,
        "longitude": 73.22068
    },
    {
        "country": "Mali",
        "alpha2": "ML",
        "latitude": 17.570692,
        "longitude": -3.996166
    },
    {
        "country": "Malta",
        "alpha2": "MT",
        "latitude": 35.937496,
        "longitude": 14.375416
    },
    {
        "country": "Marshall Islands",
        "alpha2": "MH",
        "latitude": 7.131474,
        "longitude": 171.184478
    },
    {
        "country": "Mauritania",
        "alpha2": "MR",
        "latitude": 21.00789,
        "longitude": -10.940835
    },
    {
        "country": "Mauritius",
        "alpha2": "MU",
        "latitude": -20.348404,
        "longitude": 57.552152
    },
    {
        "country": "Mexico",
        "alpha2": "MX",
        "latitude": 23.634501,
        "longitude": -102.552784
    },
    {
        "country": "Micronesia",
        "alpha2": "FM",
        "latitude": 7.425554,
        "longitude": 150.550812
    },
    {
        "country": "Moldova",
        "alpha2": "MD",
        "latitude": 47.411631,
        "longitude": 28.369885
    },
    {
        "country": "Monaco",
        "alpha2": "MC",
        "latitude": 43.750298,
        "longitude": 7.412841
    },
    {
        "country": "Mongolia",
        "alpha2": "MN",
        "latitude": 46.862496,
        "longitude": 103.846656
    },
    {
        "country": "Montenegro",
        "alpha2": "ME",
        "latitude": 42.708678,
        "longitude": 19.37439
    },
    {
        "country": "Morocco",
        "alpha2": "MA",
        "latitude": 31.791702,
        "longitude": -7.09262
    },
    {
        "country": "Mozambique",
        "alpha2": "MZ",
        "latitude": -18.665695,
        "longitude": 35.529562
    },
    {
        "country": "Myanmar",
        "alpha2": "MM",
        "latitude": 21.913965,
        "longitude": 95.956223
    },
    {
        "country": "Namibia",
        "alpha2": "NA",
        "latitude": -22.95764,
        "longitude": 18.49041
    },
    {
        "country": "Nauru",
        "alpha2": "NR",
        "latitude": -0.522778,
        "longitude": 166.931503
    },
    {
        "country": "Nepal",
        "alpha2": "NP",
        "latitude": 28.394857,
        "longitude": 84.124008
    },
    {
        "country": "Netherlands",
        "alpha2": "NL",
        "latitude": 52.132633,
        "longitude": 5.291266
    },
    {
        "country": "New Zealand",
        "alpha2": "NZ",
        "latitude": -40.900557,
        "longitude": 174.885971
    },
    {
        "country": "Nicaragua",
        "alpha2": "NI",
        "latitude": 12.865416,
        "longitude": -85.207229
    },
    {
        "country": "Niger",
        "alpha2": "NE",
        "latitude": 17.607789,
        "longitude": 8.081666
    },
    {
        "country": "Nigeria",
        "alpha2": "NG",
        "latitude": 9.081999,
        "longitude": 8.675277
    },
    {
        "country": "North Macedonia",
        "alpha2": "MK",
        "latitude": 41.608635,
        "longitude": 21.745275
    },
    {
        "country": "Norway",
        "alpha2": "NO",
        "latitude": 60.472024,
        "longitude": 8.468946
    },
    {
        "country": "Oman",
        "alpha2": "OM",
        "latitude": 21.512583,
        "longitude": 55.923255
    },
    {
        "country": "Pakistan",
        "alpha2": "PK",
        "latitude": 30.375321,
        "longitude": 69.345116
    },
    {
        "country": "Palau",
        "alpha2": "PW",
        "latitude": 7.51498,
        "longitude": 134.58252
    },
    {
        "country": "Palestine",
        "alpha2": "PS",
        "latitude": 31.9474,
        "longitude": 35.2272
    },
    {
        "country": "Panama",
        "alpha2": "PA",
        "latitude": 8.537981,
        "longitude": -80.782127
    },
    {
        "country": "Papua New Guinea",
        "alpha2": "PG",
        "latitude": -6.314993,
        "longitude": 143.95555
    },
    {
        "country": "Paraguay",
        "alpha2": "PY",
        "latitude": -23.442503,
        "longitude": -58.443832
    },
    {
        "country": "Peru",
        "alpha2": "PE",
        "latitude": -9.189967,
        "longitude": -75.015152
    },
    {
        "country": "Philippines",
        "alpha2": "PH",
        "latitude": 12.879721,
        "longitude": 121.774017
    },
    {
        "country": "Poland",
        "alpha2": "PL",
        "latitude": 51.919438,
        "longitude": 19.145136
    },
    {
        "country": "Portugal",
        "alpha2": "PT",
        "latitude": 39.399872,
        "longitude": -8.224454
    },
    {
        "country": "Qatar",
        "alpha2": "QA",
        "latitude": 25.354826,
        "longitude": 51.183884
    },
    {
        "country": "Romania",
        "alpha2": "RO",
        "latitude": 45.943161,
        "longitude": 24.96676
    },
    {
        "country": "Russia",
        "alpha2": "RU",
        "latitude": 61.52401,
        "longitude": 105.318756
    },
    {
        "country": "Rwanda",
        "alpha2": "RW",
        "latitude": -1.940278,
        "longitude": 29.873888
    },
    {
        "country": "Saint Kitts and Nevis",
        "alpha2": "KN",
        "latitude": 17.357822,
        "longitude": -62.782998
    },
    {
        "country": "Saint Lucia",
        "alpha2": "LC",
        "latitude": 13.909444,
        "longitude": -60.978893
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "alpha2": "VC",
        "latitude": 12.984305,
        "longitude": -61.287228
    },
    {
        "country": "Samoa",
        "alpha2": "WS",
        "latitude": -13.759029,
        "longitude": -172.104629
    },
    {
        "country": "San Marino",
        "alpha2": "SM",
        "latitude": 43.94236,
        "longitude": 12.457777
    },
    {
        "country": "Sao Tome and Principe",
        "alpha2": "ST",
        "latitude": 0.18636,
        "longitude": 6.613081
    },
    {
        "country": "Saudi Arabia",
        "alpha2": "SA",
        "latitude": 23.885942,
        "longitude": 45.079162
    },
    {
        "country": "Senegal",
        "alpha2": "SN",
        "latitude": 14.497401,
        "longitude": -14.452362
    },
    {
        "country": "Serbia",
        "alpha2": "RS",
        "latitude": 44.016521,
        "longitude": 21.005859
    },
    {
        "country": "Seychelles",
        "alpha2": "SC",
        "latitude": -4.679574,
        "longitude": 55.491977
    },
    {
        "country": "Sierra Leone",
        "alpha2": "SL",
        "latitude": 8.460555,
        "longitude": -11.779889
    },
    {
        "country": "Singapore",
        "alpha2": "SG",
        "latitude": 1.352083,
        "longitude": 103.819836
    },
    {
        "country": "Slovakia",
        "alpha2": "SK",
        "latitude": 48.669026,
        "longitude": 19.699024
    },
    {
        "country": "Slovenia",
        "alpha2": "SI",
        "latitude": 46.151241,
        "longitude": 14.995463
    },
    {
        "country": "Solomon Islands",
        "alpha2": "SB",
        "latitude": -9.64571,
        "longitude": 160.156194
    },
    {
        "country": "Somalia",
        "alpha2": "SO",
        "latitude": 5.152149,
        "longitude": 46.199616
    },
    {
        "country": "South Africa",
        "alpha2": "ZA",
        "latitude": -30.559482,
        "longitude": 22.937506
    },
    {
        "country": "Spain",
        "alpha2": "ES",
        "latitude": 40.463667,
        "longitude": -3.74922
    },
    {
        "country": "Sri Lanka",
        "alpha2": "LK",
        "latitude": 7.873054,
        "longitude": 80.771797
    },
    {
        "country": "Sudan",
        "alpha2": "SD",
        "latitude": 12.862807,
        "longitude": 30.217636
    },
    {
        "country": "Suriname",
        "alpha2": "SR",
        "latitude": 3.919305,
        "longitude": -56.027783
    },
    {
        "country": "Sweden",
        "alpha2": "SE",
        "latitude": 60.128161,
        "longitude": 18.643501
    },
    {
        "country": "Switzerland",
        "alpha2": "CH",
        "latitude": 46.818188,
        "longitude": 8.227512
    },
    {
        "country": "Syria",
        "alpha2": "SY",
        "latitude": 34.802075,
        "longitude": 38.996815
    },
    {
        "country": "Taiwan",
        "alpha2": "TW",
        "latitude": 23.69781,
        "longitude": 120.960515
    },
    {
        "country": "Tajikistan",
        "alpha2": "TJ",
        "latitude": 38.861034,
        "longitude": 71.276093
    },
    {
        "country": "Tanzania",
        "alpha2": "TZ",
        "latitude": -6.369028,
        "longitude": 34.888822
    },
    {
        "country": "Thailand",
        "alpha2": "TH",
        "latitude": 15.870032,
        "longitude": 100.992541
    },
    {
        "country": "Togo",
        "alpha2": "TG",
        "latitude": 8.619543,
        "longitude": 0.824782
    },
    {
        "country": "Tonga",
        "alpha2": "TO",
        "latitude": -21.178986,
        "longitude": -175.198242
    },
    {
        "country": "Trinidad and Tobago",
        "alpha2": "TT",
        "latitude": 10.691803,
        "longitude": -61.222503
    },
    {
        "country": "Tunisia",
        "alpha2": "TN",
        "latitude": 33.886917,
        "longitude": 9.537499
    },
    {
        "country": "Turkey",
        "alpha2": "TR",
        "latitude": 38.963745,
        "longitude": 35.243322
    },
    {
        "country": "Turkmenistan",
        "alpha2": "TM",
        "latitude": 38.969719,
        "longitude": 59.556278
    },
    {
        "country": "Tuvalu",
        "alpha2": "TV",
        "latitude": -7.109535,
        "longitude": 179.194
    },
    {
        "country": "Uganda",
        "alpha2": "UG",
        "latitude": 1.373333,
        "longitude": 32.290275
    },
    {
        "country": "Ukraine",
        "alpha2": "UA",
        "latitude": 48.379433,
        "longitude": 31.16558
    },
    {
        "country": "United Arab Emirates",
        "alpha2": "AE",
        "latitude": 23.424076,
        "longitude": 53.847818
    },
    {
        "country": "United Kingdom",
        "alpha2": "GB",
        "latitude": 55.378051,
        "longitude": -3.435973
    },
    {
        "country": "United States",
        "alpha2": "US",
        "latitude": 37.09024,
        "longitude": -95.712891
    },
    {
        "country": "Uruguay",
        "alpha2": "UY",
        "latitude": -32.522779,
        "longitude": -55.765835
    },
    {
        "country": "Uzbekistan",
        "alpha2": "UZ",
        "latitude": 41.377491,
        "longitude": 64.585262
    },
    {
        "country": "Vanuatu",
        "alpha2": "VU",
        "latitude": -15.376706,
        "longitude": 166.959158
    },
    {
        "country": "Vatican City",
        "alpha2": "VA",
        "latitude": 41.902916,
        "longitude": 12.453389
    },
    {
        "country": "Venezuela",
        "alpha2": "VE",
        "latitude": 6.42375,
        "longitude": -66.58973
    },
    {
        "country": "Vietnam",
        "alpha2": "VN",
        "latitude": 14.058324,
        "longitude": 108.277199
    },
    {
        "country": "Yemen",
        "alpha2": "YE",
        "latitude": 15.552727,
        "longitude": 48.516388
    },
    {
        "country": "Zambia",
        "alpha2": "ZM",
        "latitude": -13.133897,
        "longitude": 27.849332
    },
    {
        "country": "Zimbabwe",
        "alpha2": "ZW",
        "latitude": -19.015438,
        "longitude": 29.154857
    },
    {
        "country": "North of US East Coast",
        "alpha2": "US_EN",
        "latitude": 42.0000,
        "longitude": -75.0000
    },
    {
        "country": "Middle of US East Coast",
        "alpha2": "US_EM",
        "latitude": 39.0000,
        "longitude": -77.0000
    },
    {
        "country": "South of US East Coast",
        "alpha2": "US_ES",
        "latitude": 33.0000,
        "longitude": -80.0000
    },
    {
        "country": "North of US Interior",
        "alpha2": "US_IN",
        "latitude": 44.0000,
        "longitude": -90.0000
    },
    {
        "country": "South of US Interior",
        "alpha2": "US_IS",
        "latitude": 35.0000,
        "longitude": -97.0000
    },
    {
        "country": "North of US West Coast",
        "alpha2": "US_WN",
        "latitude": 45.0000,
        "longitude": -123.0000
    },
    {
        "country": "South of US West Coast",
        "alpha2": "US_WS",
        "latitude": 34.0000,
        "longitude": -118.0000
    },
    {
        "country": "North Korea",
        "alpha2": "KP",
        "latitude": 40.339852,
        "longitude": 127.510093
    },
    {
        "country": "Northern Mariana Islands",
        "alpha2": "MP",
        "latitude": 15.0979,
        "longitude": 145.6739
    }
]
