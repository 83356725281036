import React from 'react';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandMoreOutlined, OpenInNewOutlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import ArticleCard from '../global/ArticleCard';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    accordionDetails: {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : `${theme.palette.grey[900]} !important`,
    },
    details: {
        margin: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        // borderLeft: `${theme.palette.primary.main} ${theme.spacing(0.5)} solid`,
        background: theme.palette.background.default
    },
    iconWrapper: {
        marginRight: theme.spacing(1)
    }
}));

export default function HelperContent(props) {
    const classes = useStyles();
    const theme = useTheme();
    const content = props.content;

    const handleClicked = (link) => () => {
        window.open(link, '_blank')
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} mb={2}>
                <ListItem>
                    <ListItemIcon className={classes.iconWrapper}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                            {content.icon}
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText
                        primary={content.title}
                        primaryTypographyProps={{
                            variant: "h6",
                            fontWeight: 700,
                            color: 'primary',
                            lineHeight: 1.1
                        }}
                        secondary={content.underline}
                    />
                </ListItem>
            </Grid>
            <Grid item xs={12}>
                {
                    content.links.map((c, i) => (
                        <Accordion disableGutters elevation={0} key={i}>
                            <AccordionSummary
                                className={classes.accordionDetails}
                                expandIcon={<ExpandMoreOutlined />}
                            >
                                <Typography sx={{ fontWeight: 600 }} variant="body1" color={theme.palette.primary.light}>
                                    {c.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Typography mb={1} variant="body1" color="textSecondary">
                                    {c.description}
                                </Typography>
                                <Button
                                    size="small"
                                    variant="contained"
                                    endIcon={<OpenInNewOutlined />}
                                    onClick={handleClicked(c.link)}
                                >
                                    Read more
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Grid>
        </Grid >
    )
}

// import React from 'react';

// import { makeStyles } from '@mui/styles';
// import { useTheme } from '@emotion/react';
// import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Grid, Link, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
// import { ExpandMoreOutlined, OpenInNewOutlined } from '@mui/icons-material';

// import SectionHeader from '../global/SectionHeader';
// import ArticleCard from '../global/ArticleCard';


// const useStyles = makeStyles((theme) => ({
//     // [theme.breakpoints.up('xs')]: {}
//     // [theme.breakpoints.up('sm')]: {}
//     // [theme.breakpoints.up('md')]: {}
//     // [theme.breakpoints.up('xl)]: {}
//     root: {
//         padding: theme.spacing(0, 2, 2, 2)
//     },
// }));

// export default function HelperContent(props) {
//     const classes = useStyles();
//     const theme = useTheme();
//     const content = props.content;

//     const handleClicked = (link) => () => {
//         window.open(link, '_blank')
//     }

//     return (
//         <ArticleCard
//             title={content.title}
//             underline={content.underline}
//         >
//             <Grid container className={classes.root}>

//                 {
//                     content.links.map((c, i) => (
//                         <Grid item xs={12}>
//                             <Link underline="hover" href={c.link} target="blank">
//                                 {c.title} <OpenInNewOutlined fontSize="small" />
//                             </Link>
//                         </Grid>

//                     ))
//                 }
//             </Grid >
//         </ArticleCard>
//     )
// }
