import React, { Fragment, useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';

import { makeStyles } from '@mui/styles';
import { Collapse, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import SectionContainer from '../global/SectionContainer';
import { AddOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function TokenTarget(props) {
    const classes = useStyles();
    const groupid = props.groupid;
    const streamname = props.streamnames;

    const [target, setTarget] = useState("groupid");
    const [value, setValue] = useState(groupid ? groupid : "");
    const [streamnames, setStreamnames] = useState([]);
    const [targets, setTargets] = useState([
        { value: "groupid", radioLabel: "Entire Streamgroup", textfieldLabel: "Streamgroup Id" },
        { value: "streams", radioLabel: "Streamname(s)", textfieldLabel: "Streamname", addMore: true },
        { value: "orgawide", radioLabel: "Entire Organisation", textfieldLabel: "Organisation", },
    ]);


    const handleUpdateTarget = (event) => {
        let newTarget = event.target.value;
        if (newTarget === "groupid") setValue(groupid ? groupid : "");
        if (newTarget === "streamname") setValue(streamname ? streamname : "");
        setTarget(newTarget);

    }

    const handleAppendMoreTextfields = () => {
        setStreamnames([...streamnames, ""])
    }

    const handleAddStreamnames = (index) => (event) => {
        let input = event.target.value;
        let updatedStreamnames = [...streamnames];
        updatedStreamnames[index] = input;
        setStreamnames(updatedStreamnames)
    }

    const handleUpdatedTextfield = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
    }

    useEffect(() => {
        let targetValue = target === "orgawide" ? true : value;

        if (target === "streams") {
            targetValue = [...[value], ...streamnames].filter(e => e);
        }

        props.updateTarget({ [target]: targetValue });
    }, [value, target])

    return (
        <SectionContainer
            {...props}
            title="Set token target"
            underline="Choose if you want to create a token for specific streams or your entire organisation."
        >
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            row
                            value={target}
                            onChange={handleUpdateTarget}
                        >
                            {
                                targets.map((radioItem, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={radioItem.value}
                                        control={<Radio />}
                                        label={radioItem.radioLabel}
                                    />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} mt={0.5}>
                    <Collapse in={!(target === "orgawide")}>
                        <TextField
                            variant="standard"
                            className={classes.textfield}
                            fullWidth
                            color="primary"
                            label={Object.values(targets).find(e => e.value === target).textfieldLabel}
                            value={value}
                            onChange={handleUpdatedTextfield}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{
                                endAdornment:
                                    target === "streams" && streamnames.length < 1
                                    &&
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={handleAppendMoreTextfields}
                                        >
                                            <AddOutlined />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Collapse>
                </Grid>
                {
                    target === "streams"
                    &&
                    <Fragment>
                        {
                            streamnames.map((s, i) => (
                                <Grid item xs={12} sm={6} lg={4} mt={0.5}>
                                    <TextField
                                        variant="standard"
                                        className={classes.textfield}
                                        fullWidth
                                        color="primary"
                                        label={`Streamname ${i + 2}`}
                                        value={streamnames[i]}
                                        onChange={handleAddStreamnames(i)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment:
                                                i + 1 === streamnames.length
                                                &&
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        size="small"
                                                        onClick={handleAppendMoreTextfields}
                                                    >
                                                        <AddOutlined />
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                    />
                                </Grid>
                            ))
                        }
                    </Fragment>
                }
            </Grid>
        </SectionContainer>
    )
}
