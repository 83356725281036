import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Snackbar, Alert, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function SnackbarMessage(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    return (
        <Snackbar
            open={props.open}
            onClose={props.close}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <Alert
                severity={props.type}
                variant="filled"
                action={
                    <Fragment>
                        <IconButton
                            size="small"
                            color="inherit"
                            onClick={props.close}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </Fragment>
                }
            >
                {props.children}
            </Alert>
        </Snackbar>

    )
}
