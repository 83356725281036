import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { AutoStoriesOutlined, PlayCircleFilledOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    card: {
        backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]} !important`,
        height: '100%',
        '&:hover': {
            transform: 'scale(1.01)'
        }
    }
}));

export default function ArticleCard(props) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Card sx={{ borderRadius: 5 }} elevation={0} className={classes.card}>
            {
                props.image
                &&
                <CardMedia
                    component="img"
                    height="120"
                    image={props.image}
                />
            }
            <CardContent>
                {
                    props.icon
                    &&
                    props.icon
                }
                <Typography gutterBottom variant="h5" component="div" color={theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.white}>
                    {props.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.underline}
                </Typography>
            </CardContent>
            {
                props.children
                &&
                <CardActions sx={{ mb: .5 }}>
                    {
                        props.children
                    }
                </CardActions>
            }
        </Card >
    )
}
