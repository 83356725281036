import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { IconButton, Button, Tooltip } from '@mui/material';
import { Check, FileCopyOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0.2),
        display: 'inline-block'
    },
    textArea: {
        display: 'none'
    }
}))

export default function CopyButton(props) {
    const classes = useStyles();
    const [copy, setCopied] = useState(false);

    const handleCopyClicked = () => {
        if (isIOS()) {
            handleCopySelectText();
            setCopied(true);
            return;
        }
        handleCopyWriteText();
        setCopied(true);
    }

    const handleCopyWriteText = () => {
        if (window.navigator.clipboard) {
            window.navigator.clipboard.writeText(props.copy)
        }
    }

    const isIOS = () => {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    const handleCopySelectText = () => {
        let textfieldToCopy = document.getElementById(`${props.copy}-copy`);

        var editable = textfieldToCopy.contentEditable;
        var readOnly = textfieldToCopy.readOnly;

        textfieldToCopy.contentEditable = true;
        textfieldToCopy.readOnly = false;

        let range = document.createRange();
        range.selectNodeContents(textfieldToCopy);

        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        textfieldToCopy.setSelectionRange(0, 999999);

        textfieldToCopy.contentEditable = editable;
        textfieldToCopy.readOnly = readOnly;

        document.execCommand('copy');
    }


    useEffect(() => {
        if (copy) {
            setTimeout(() => {
                setCopied(false);
            }, 1000)
        }
    }, [copy])

    return (
        <div className={classes.root}>
            {
                props.iconButton
                    ?
                    <Tooltip title={copy ? "Copied" : "Copy"}>
                        <IconButton
                            onClick={handleCopyClicked}
                            size="small"
                        >
                            {
                                copy
                                    ? <Check fontSize="inherit" />
                                    : <FileCopyOutlined fontSize="inherit" />
                            }

                        </IconButton>
                    </Tooltip>
                    :
                    <Button
                        onClick={handleCopyClicked}
                        startIcon={copy ? <Check /> : <FileCopyOutlined />}
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        {copy ? "Copied" : "Copy"}
                    </Button>
            }
            <textarea
                className={classes.textArea}
                id={`${props.copy}-copy`}
                value={props.copy}
                readOnly
            >
                {props.copy}
            </textarea>
        </div>
    )
}