import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';
import { usePermission } from '../utils/helper/hooks';
import permissionConfig from '../utils/permissions/permission_config';

import { makeStyles } from '@mui/styles';
import { Grid, Stack, Typography } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

import Loading from '../components/global/Loading';
import SnackbarMessage from '../components/global/SnackbarMessage';
import SectionHeader from '../components/global/SectionHeader';
import EnabledOptions from '../components/organisation/EnabledOptions';
import KeysOverview from '../components/organisation/KeysOverview';
import Orga from '../components/organisation/Orga';
import Webhook from '../components/organisation/Webhook';
import Vod from '../components/organisation/Vod';
import User from '../components/organisation/User';
import AllTags from '../components/organisation/AllTags';
import NoAccess from '../components/global/NoAccess';


export default function Organisation(props) {
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [orga, setOrga] = useState(false);
    const canGetUser = usePermission(permissionConfig.permissionMasks.user.getUser);
    const canGetOrga = usePermission(permissionConfig.permissionMasks.organisation.get);

    document.title = "Organisation Overview | nanoStream Cloud Dashboard";

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setShowError(false);
    }

    const fetchOrga = () => {
        bintu.getOrganisation()
            .then((response) => {
                if (response.success) {
                    sessionStorage.setItem(ls.BINTU_ORGA, JSON.stringify(response.data));
                    setOrga(response.data);
                    setLoading(false);
                }
            }).catch((error) => {
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleOpenSupport = () => {
        let supportUrl = `${link.NANO_SUPPORT}?bintu.id=${orga?.id}&bintu.name=${orga?.name}&bintu.webhook=${orga?.webhook}&bintu.vod=${orga?.vod}&bintu.webrtc=${orga?.webrtc}&bintu.transcoding=${orga?.transcoding}&bintu.h5live=${orga?.h5live}&bintu.hash=${orga?.hash}&bintu.hls=${orga?.hls}&bintu.secure=${orga?.secure}&bintu.metrics=${orga?.metrics}&bintu.sla=${orga?.sla}&bintu.trial=${orga?.trial}`
        window.open(supportUrl, "_blank");
    }

    useEffect(() => {
        if (canGetOrga) {
            if (loading) fetchOrga();
        } else {
            setLoading(false);
        }
    }, [])

    return (
        <Grid container>
            {
                !canGetOrga
                    ? <NoAccess content={"Organisation Overview"} />
                    :
                    <Grid container>
                        <SnackbarMessage
                            open={showError}
                            close={handleCloseError}
                            type={"error"}
                        >
                            <b>Error: {error.code}</b> {error.message}
                        </SnackbarMessage>
                        {
                            loading
                                ? <Loading />
                                : <Grid container spacing={2} borderRadius={1}>
                                    <Grid item xs={12}>
                                        <SectionHeader
                                            specialHeader title={orga.name} interact
                                            button={"Get Support"}
                                            icon={<OpenInNew />}
                                            clicked={handleOpenSupport}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <EnabledOptions orga={orga} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Orga orga={orga} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <KeysOverview orga={orga} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Webhook webhook={orga.webhook} />
                                        <Vod publicVod={orga.publicVOD} />
                                    </Grid>
                                    {
                                        canGetUser &&
                                        <Grid item xs={12} lg={6}>
                                            <User />
                                        </Grid>
                                    }
                                    <Grid item xs={12} lg={canGetUser ? 6 : 12}>
                                        <AllTags />
                                    </Grid>
                                </Grid>
                        }
                    </Grid>

            }
        </Grid>

    )
}
