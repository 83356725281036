import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Avatar, Box, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { Check, Close, DeleteSweep, Edit, ResetTv, Save } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import { setWebhook } from '../../utils/bintu/api-requests';
import SnackbarMessage from '../global/SnackbarMessage';
import { usePermission } from '../../utils/helper/hooks';
import permissionConfig from '../../utils/permissions/permission_config';

export default function Webhook(props) {
    const theme = useTheme();
    const { webhook } = props;
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [updatedWebhook, setUpdatedWebhook] = useState(webhook);
    const [updating, setUpdating] = useState(false);
    const canSetWebhook = usePermission(permissionConfig.permissionMasks.organisation.setWebhook);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setShowError(false);
    }

    const handleUpdating = (update) => () => {
        setUpdating(update);
        if (!update) setUpdatedWebhook(webhook);
    }

    const handleUpdatedWebhook = (event) => {
        setUpdatedWebhook(event.target.value)
    }

    const handleSubmitWebhook = (newWebhook) => () => {
        let data = {
            webhook: newWebhook?.trim().length === 0 ? null : newWebhook
        }

        setWebhook(data)
            .then((response) => {
                if (response.success) {
                    handleUpdating(false)();
                    setUpdatedWebhook(response.data.webhook);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }


    useEffect(() => {
        setUpdatedWebhook(webhook);
    }, [webhook])

    return (
        <Grid container>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12}>
                <Box p={2} sx={{ borderRadius: theme.spacing(2), bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900] }}>
                    <SectionHeader
                        title="Custom Web Hook"
                    />
                    <TextField
                        fullWidth autoComplete="off" variant="standard"
                        type="text" disabled={!updating}
                        value={updatedWebhook ? updatedWebhook : ""}
                        placeholder={canSetWebhook && !updating && 'Click on the edit icon to update.'}
                        onChange={handleUpdatedWebhook}
                        helperText={updating && "Confirm your changes."}
                        InputProps={{
                            endAdornment: canSetWebhook && (
                                <InputAdornment position="end">
                                    {
                                        updating
                                            ?
                                            <Fragment>
                                                <Tooltip title="Save">
                                                    <IconButton
                                                        size="small"
                                                        onClick={handleSubmitWebhook(updatedWebhook)}
                                                    >
                                                        <Check fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Cancel">
                                                    <IconButton
                                                        size="small"
                                                        onClick={handleUpdating(false)}
                                                    >
                                                        <Close fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Reset">
                                                    <IconButton
                                                        size="small"
                                                        onClick={handleSubmitWebhook(null)}
                                                    >
                                                        <DeleteSweep fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Fragment>
                                            :
                                            <Tooltip title="Update Web Hook">
                                                <IconButton
                                                    size="small"
                                                    onClick={handleUpdating(true)}
                                                >
                                                    <Edit fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>

                                    }
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
