import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Snackbar, Alert, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function ContentDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(props.open);

    const handleClose = () => {
        props.close();
    }

    const handleSubmit = () => {
        props.submit();
    }

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h5" color="primary">
                    {props.title}
                </Typography>
                {
                    props.underline
                    &&
                    <Typography variant="body1" color="textSecondary">
                        {props.underline}
                    </Typography>
                }
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {props.content}
                </DialogContentText>
                {
                    !props.hideActions
                    &&
                    <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: "0 !important" }}>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            {props.submitButton}
                        </Button>
                        {
                            props.hideCancel
                                ? null
                                :
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                        }
                    </DialogActions>
                }
            </DialogContent>
        </Dialog>

    )
}
