import React, { useEffect, useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import darkCode from "react-syntax-highlighter/dist/esm/styles/prism/material-dark";
import lightCode from "react-syntax-highlighter/dist/esm/styles/prism/material-light";

import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import SectionContainer from '../global/SectionContainer';
import CopyButton from '../global/CopyButton';
import SectionHeader from '../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        width: '100%',
        boxShadow: 'none !important',
        background: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]} !important`,
    },
    code: {
        fontSize: '12px !important'
    },
    details: {
        padding: theme.spacing(2)
    },
    descriptionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
}));

export default function CodeSnippet(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [codeSnippet, setCodeSnippet] = useState("");

    const generateCodeSnippet = (stream, security) => {
        const entries = stream.playout.h5live
            ?.map(((stream, index) => {
                return {
                    index: index,
                    h5live: {
                        rtmp: {
                            streamname: stream.rtmp?.streamname
                        }
                    },
                    // TO DO: ADD SECURITY
                    ...security && {
                        security: {
                            token: security
                        }
                    }
                }
            }));
        let config = {
            source: {
                group: {
                    id: stream.id,
                    apiurl: bintu.BINTU_API,
                    ...security && {
                        security: {
                            jwtoken: security
                        }
                    }
                },
                // entries,
                options: {
                    adaption: {
                        rule: props.adaptionRule ? props.adaptionRule.id : "deviationOfMean2"
                    },
                },
                startIndex: props.adaptionRule ? props.adaptionRule.index : 0,
            },
            playback: {
                latencyControlMode: props.latencyControlMode ? props.latencyControlMode : "classic",
                autoplay: true,
                automute: true,
                faststart: true
            },
            style: {
                width: "auto",
                height: "auto"
            }
        };

        setCodeSnippet(`<div id="playerDiv" style="width:100%; padding-top:56.25%"></div>
<script src="${link.NANOPLAYER_RELEASE_SRC}"></script>
<script>
var player;
var config = ${JSON.stringify(config, null, '\t')};
document.addEventListener('DOMContentLoaded', function () {
    player = new NanoPlayer("playerDiv");
    player.setup(config).then(function (config) {
        console.log("setup success");
        console.log("config: " + JSON.stringify(config, undefined, 4));
    }, function (error) {
        alert(error.message);
    });
});
</script>`)
    }

    useEffect(() => {
        if (Object.values(props.stream)) {
            generateCodeSnippet(props.stream, props.security);
        }
    }, [props.stream, props.security, props.adaptionRule, props.latencyControlMode])

    return (
        <Accordion className={classes.root} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <SectionHeader
                    title="nanoStream H5Live Code Snippet (nanoPlayer)"
                    {...props}
                />
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div className={classes.descriptionWrapper}>
                    <Typography variant="body1" color="textSecondary">
                        Integrate the nanoStream H5Live Player on any webpage using the code snippet below.
                    </Typography>
                    <CopyButton copy={codeSnippet} />
                </div>
                <code>
                    <pre id={"code-snippet"}>
                        <SyntaxHighlighter className={classes.code} showLineNumbers language="html" style={theme.palette.mode === 'light' ? lightCode : darkCode}>
                            {codeSnippet}
                        </SyntaxHighlighter>
                    </pre>
                </code>
            </AccordionDetails>
        </Accordion>
    )
}
