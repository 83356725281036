import React, { Fragment, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button, Chip, Collapse, Divider, Grid, Hidden, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import { AddCircleOutline, Check, CheckCircleOutline, HeadsetMicOutlined, HighlightOff, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, More, MoreOutlined, PlayCircleOutline, TaskAltOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import State from '../global/State';
import SectionContainer from '../global/SectionContainer';
import SectionHeader from '../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    tag: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        borderRadius: '5px !important'
    },
    cell: {
        borderLeft: `${theme.spacing(0.5)} solid transparent`
    },
    border: {
        borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`
    }
}));

export default function Row(props) {
    const classes = useStyles();
    let { stream } = props;
    const [expand, setExpand] = useState(false);

    const expandInfos = (event) => {
        event.stopPropagation();
        setExpand(!expand);
    }

    return (
        <Fragment>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }} onClick={props.clicked(stream.id)}>
                <TableCell className={clsx(classes.cell, { [classes.border]: expand })}>
                    {
                        stream.streamgroup && stream.playout.h5live.length > 1
                        &&
                        <Tooltip title={"ABR"}>
                            <IconButton
                                onClick={expandInfos}
                                size="small"
                            >
                                {
                                    expand
                                        ? <KeyboardArrowDown fontSize="inherit" />
                                        : <KeyboardArrowRight fontSize="inherit" />
                                }

                            </IconButton>
                        </Tooltip>
                    }
                </TableCell>
                <TableCell>
                    <State state={stream.state} streamid={stream.id} />
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        {stream.id}
                    </Typography>
                    {
                        (!props.isStreamgroup && stream.streamgroup)
                        &&
                        <Tooltip title="Streamgroup Id">
                            <Typography variant="caption" color="textSecondary">
                                {stream.streamgroup}
                            </Typography>
                        </Tooltip>
                    }
                </TableCell>
                <TableCell>
                    <Typography variant="body2">
                        {stream.ingest.rtmp?.streamname}
                    </Typography>
                </TableCell>
                {
                    props.isStreamgroup
                    &&
                    <TableCell>
                        {
                            stream.streamgroup && stream.playout.h5live.length > 1
                                ? <Chip
                                    // icon={<TaskAltOutlined />}
                                    label={expand ? `Passthrough` : `Streamgroup (${stream.playout.h5live.length} Playouts)`}
                                    variant="outlined"
                                    color="primary"
                                />
                                : <Chip
                                    icon={<HighlightOff />}
                                    label={`No streamgroup`}
                                    color="default"
                                />
                        }
                    </TableCell>
                }
                <TableCell>
                    <Typography color="textSecondary" variant="subtitle2">
                        {moment(stream.updatedAt).format('MM/DD/YYYY')} <br />
                        {moment(stream.updatedAt).utc().format('hh:mm a')}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Stack useFlexGap spacing={.3} direction="row" flexWrap="wrap">
                        {
                            stream.tags
                                // .filter((a, i) => i < 6)
                                .map((tag, i) => (
                                    <Chip
                                        key={i} color="primary"
                                        label={tag} size="small"
                                        sx={{ borderRadius: '5px !important' }}
                                    />
                                ))
                        }
                    </Stack>
                </TableCell>
            </TableRow>
            {
                expand
                &&
                <Fragment>
                    {
                        stream?.playout?.rtmp.slice(1).map((abr, i) => (
                            <TableRow hover key={abr.id} onClick={props.clicked(stream.id)}>
                                <TableCell className={classes.border} />
                                <TableCell>
                                    <State state={abr.state} />
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {abr.id}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {abr.streamname}
                                    </Typography>
                                </TableCell>
                                {
                                    props.isStreamgroup
                                    &&
                                    <TableCell>
                                        <Chip
                                            // icon={<TaskAltOutlined />}
                                            label={`${i + 1}. Transcode`}
                                            variant="outlined"
                                            color="primary"
                                        />
                                    </TableCell>
                                }
                                <TableCell>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {moment(abr.updatedAt).format('MM/DD/YYYY')} <br />
                                        {moment(abr.updatedAt).utc().format('hh:mm a')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Stack useFlexGap spacing={.3} direction="row" flexWrap="wrap">
                                        {
                                            abr.tags
                                                // ?.filter((a, i) => i < 6)
                                                ?.map((tag, i) => (
                                                    <Chip
                                                        key={i} color="primary"
                                                        size="small" label={tag}
                                                        sx={{ borderRadius: '5px !important' }}
                                                    />
                                                ))
                                        }
                                        {/* {
                                            abr.tags?.length > 6
                                            &&
                                            <Tooltip arrow title={stream.tags.join(", ")}>
                                                <Chip
                                                    color="primary"
                                                    icon={<More />}
                                                />
                                            </Tooltip>
                                        } */}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </Fragment>
            }
        </Fragment >
    )
}
