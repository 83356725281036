import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Grid, Button, Divider, Chip, Avatar, Typography, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Collapse, useTheme, IconButton, Link, Hidden, Box } from '@mui/material';
import { ArrowDownwardOutlined, ArrowRightAlt, ArrowUpwardOutlined, ErrorOutline, ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, List, OpenInNew, PlayCircleOutline, SupportAgentOutlined, WarningAmberOutlined } from '@mui/icons-material';

import * as helper from '../../utils/analytics-helper';
import * as link from '../../../utils/helper/link-config';
import * as metrics from '../../utils/api-requests';
import * as ls from '../../../utils/helper/ls-vars';
import * as m from '../../../utils/helper/methods';

import SectionContainer from '../../../components/global/SectionContainer';
import SectionHeader from '../../../components/global/SectionHeader';
import ContentTable from '../../../components/global/ContentTable';
import moment from 'moment';


export default function AlertTable(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
    const email = JSON.stringify(sessionStorage.getItem(ls.BINTU_MAIL));

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('severity');
    const [expand, setExpand] = useState(null);
    const [list, setList] = useState([]);

    const handleExpand = (index) => () => {
        setExpand(expand === index ? null : index)
    }

    const handleOpenInNew = (link) => () => {
        window.open(link, '_target')
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const descendingComparator = (a, b, orderBy) => {
        if (orderBy === 'type') {
            const aValue = a.alert[orderBy]?.name || '';
            const bValue = b.alert[orderBy]?.name || '';

            if (bValue < aValue) {
                return -1;
            }
            if (bValue > aValue) {
                return 1;
            }
        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
        }
        return 0;
    }


    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleOpenMetrics = (streamname) => () => {
        metrics.getMetricsTroubleshootingRedirect({ streamname: streamname })
            .then((response) => {
                if (response.success) {
                    let link = response.data.redirectLink
                    window.open(link, '_target')
                }
            })
            .catch((error) => {
                if (!error.success) {

                }
            })
    }

    const handleRedirect = (link) => () => {
        navigate(link);
    }

    const handleOpenSupport = (streamname, details) => () => {
        let queries = {
            ...orga && {
                bintu: {
                    id: orga.id,
                    name: orga.name,
                    hash: orga.hash,
                    stream: streamname
                }
            },
            ...(details.country || details.countryName) && {
                location: details.country || details.countryName
            }
            // ...email && { email: email }
        }

        let query = `?${m.TO_QUERY_STRING(queries)}`
        window.open(`${link.NANO_SUPPORT}${query}`, "_blank");
    }

    useEffect(() => {
        setList(props.list);
    }, [props.list])

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        { label: "", type: false },
                                        { label: "Severity", type: false },
                                        { label: "Type", type: "type" },
                                        { label: "Streamname", type: false }
                                    ].map((label) => (
                                        <TableCell key={label.type} onClick={handleRequestSort(label.type)}>
                                            <Typography variant="button" color="textSecondary">
                                                {label.label}
                                                {
                                                    label.type
                                                    &&
                                                    <IconButton
                                                        disableRipple={true}
                                                        size="small"
                                                    // onClick={handleRequestSort(label.type)}
                                                    >
                                                        {
                                                            orderBy === orderBy &&
                                                                order === 'asc'
                                                                ? <KeyboardArrowUp fontSize='inherit' />
                                                                : <KeyboardArrowDown fontSize='inherit' />
                                                        }
                                                    </IconButton>
                                                }
                                            </Typography>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ cursor: 'pointer' }}>
                            {
                                stableSort(list, getComparator(order, orderBy)).map((alert, index) => {
                                    let alertHelperObj = Object.values(helper.ALERT_COLOR_SCHEME).find(s => s.title === props.severity);
                                    let details = alert.streamDetails;
                                    return (
                                        <Fragment key={index}>
                                            <TableRow onClick={handleExpand(index)}>
                                                <TableCell sx={{ border: 0, width: "max-content" }} align="left">
                                                    <IconButton
                                                        onClick={handleExpand}
                                                        size="small"
                                                    >
                                                        {expand === index ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell sx={{ border: 0 }}>
                                                    <Chip
                                                        size="small"
                                                        sx={{ bgcolor: alertHelperObj?.color, color: theme.palette.common.white }}
                                                        // avatar={<Avatar>9</Avatar>}
                                                        color="primary"
                                                        icon={alertHelperObj?.icon}
                                                        label={props.severity}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ border: 0 }}>
                                                    <Typography sx={{ display: 'inline-block', lineHeight: 1, ml: 1 }} variant="subtitle1">
                                                        {alert.alert.type.label}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ border: 0 }}>
                                                    <Typography sx={{ display: 'inline-block', lineHeight: 1, ml: 1 }} variant="subtitle1">
                                                        {alert.streamname}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                                    <Collapse in={expand === index} timeout="auto" unmountOnExit>
                                                        <SectionContainer
                                                            contrast
                                                            noMargin
                                                            mb
                                                        >
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <SectionHeader small
                                                                        title={`${alertHelperObj?.title} Alert`}
                                                                        underline={`${alert.streamname} was categorized as ${alertHelperObj?.title.toLocaleLowerCase()}.`}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sx={{ mb: 1 }}>
                                                                    <ContentTable data={[
                                                                        // {
                                                                        //     label: "Severity",
                                                                        //     custom: <Chip
                                                                        //         size="small"
                                                                        //         sx={{ bgcolor: alertHelperObj?.color, color: theme.palette.common.white }}
                                                                        //         color="primary"
                                                                        //         icon={alertHelperObj?.icon}
                                                                        //         label={props.severity}
                                                                        //     />
                                                                        // },
                                                                        { label: "Type", value: alert.alert.type.label, copy: true },
                                                                        { label: "Message", value: alert.alert.message },
                                                                        ...(alert?.alert?.firstDetection ? [{ label: "First detected", date: moment(alert?.alert?.firstDetection).utc().format('MM/DD/YYYY hh:mm a (UTC)') }] : []),
                                                                        ...(alert?.alert?.elapsedTime ? [{ label: "Elapsed time (s)", value: alert?.alert?.elapsedTime }] : []),
                                                                        {
                                                                            label: "Alert Code",
                                                                            custom: <Button
                                                                                size="small"
                                                                                variant="outlined"
                                                                                onClick={handleOpenInNew(link.ALERTS_CODES)}
                                                                                endIcon={<OpenInNew fontSize='inherit' />}
                                                                            >
                                                                                {alert.alert.code}
                                                                            </Button>
                                                                        },
                                                                        ...(alert?.alert?.id ? [{ label: "Alert ID", value: alert?.alert?.id, copy: true }] : []),
                                                                    ]} />
                                                                </Grid>
                                                                <Grid item xs={12} sx={{ my: 1 }}>
                                                                    <SectionContainer
                                                                        noMargin
                                                                        small
                                                                        title="Stream Info"
                                                                    >
                                                                        <ContentTable data={[
                                                                            { label: "City", value: details?.city },
                                                                            { label: "Country", value: `${details?.country || details?.countryName} ${details?.countryCode ? `(${details?.countryCode})` : ""}` },
                                                                            { label: "IP", value: details?.ip, copy: true },
                                                                            { label: "Provider", value: details?.provider, copy: true },
                                                                            { label: "Server", value: details?.server, copy: true },
                                                                            { label: "Resolution", value: `${details?.width}x${details?.height}` },
                                                                        ]} />
                                                                    </SectionContainer>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} mt={1}>
                                                                <SectionContainer
                                                                    noMargin
                                                                    small
                                                                    title="Responding to Alerts"
                                                                    underline="A Step-by-Step Guide"
                                                                >
                                                                    {
                                                                        alert?.alert?.howToFix
                                                                            ?
                                                                            <Fragment>
                                                                                {
                                                                                    alert.alert.howToFix.map((how, i) => (
                                                                                        <Typography variant="subtitle2" sx={{ mt: .5, lineHeight: 1.3 }}>{how}</Typography>
                                                                                    ))
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            <Typography variant="subtitle2" sx={{ mt: .5, lineHeight: 1.3 }}>
                                                                                <b>1. Understand the Message</b><br />
                                                                                Is the alert message self-explanatory? Often, the alert will provide direct information on the issue and potentially how to address it. <br />
                                                                                <b>2. Refer to the Documentation</b><br />
                                                                                Before taking further action, it's always a good idea to consult the <Link underline="hover" target="_blank" href={link.ALERTS_DOCS}>documentation <OpenInNew fontSize={theme.typography.subtitle2.fontSize} /></Link>. It might contain a solution or additional information about the alert you received. <br />
                                                                                <b>3. Troubleshoot the Alert</b><br />
                                                                                If the problem persists or isn't clear, we recommend using our troubleshooting tool. To begin, <Link underline="hover" onClick={handleOpenMetrics(alert.streamname)}>log in to the metrics dashboard <OpenInNew fontSize={theme.typography.subtitle2.fontSize} /></Link>. <br />
                                                                                <b>4. Contact Support</b><br />
                                                                                Still facing challenges? We're here to assist! Create a new support request by clicking <Link underline="hover" onClick={handleOpenSupport(alert.streamname, details)}>here <OpenInNew fontSize={theme.typography.subtitle2.fontSize} /></Link>. Ensure you copy any necessary data related to the alert. Our team will review it and provide assistance as quickly as possible.
                                                                            </Typography>
                                                                    }
                                                                </SectionContainer>

                                                            </Grid>
                                                            <Grid item xs={12} mt={1}>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ mr: 1, my: 0.5 }}
                                                                    startIcon={<PlayCircleOutline />}
                                                                    onClick={handleRedirect(`${link.PLAYOUT}/${alert.streamname}`)}
                                                                >
                                                                    Playout
                                                                </Button>
                                                                {
                                                                    !(alert.alert.howToFix && (alert.alert.type?.name === "duplicatedIngest"))
                                                                    &&
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        sx={{ mr: 1, my: 0.5 }}
                                                                        endIcon={<OpenInNew />}
                                                                        onClick={handleOpenMetrics(alert.streamname)}
                                                                    >
                                                                        Troubleshooting
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ mr: 1, my: 0.5 }}
                                                                    endIcon={<OpenInNew />}
                                                                    onClick={handleOpenSupport(alert.streamname, details)}
                                                                >
                                                                    Open Support Ticket
                                                                </Button>
                                                            </Grid>
                                                        </SectionContainer>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    )
}
