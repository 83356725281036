export const AUTHORIZED = 'bintu-auth';
export const BINTU_MAIL = 'bintu-mail';
export const BINTU_TOKEN = 'bintu-token';
export const BINTU_APIKEY= 'bintu-apikey';
export const BINTU_ORGA = 'bintu-orga';

export const METRICS_LEVEL = 'metrics-level';
export const METRICS_TOKEN = 'metrics-token';
export const METRICS_SG = 'metrics-streamguard';
export const METRICS_EXPIRES = 'metrics-expires';

export const H5LIVE_HASH_SECURE = 'h5live-security';


export const THEME = 'bintu-prefers-dark-mode';

export const ROLE = 'role';