import { InfoOutlined, LightbulbOutlined, LocalFireDepartmentOutlined, WarningAmberOutlined } from "@mui/icons-material";

export const ALERT_COLOR_SCHEME = {
    advices: { title: "Advices", color: "#03a9f4", icon: <LightbulbOutlined /> },
    minorAlerts: { title: "Minor", color: "#ef9a9a", icon: <InfoOutlined /> },
    moderateAlerts: { title: "Moderate", color: "#ef5350", icon: <WarningAmberOutlined /> },
    criticalAlerts: { title: "Critical", color: "#D22315", icon: <LocalFireDepartmentOutlined /> },
};

export const GENERATE_RANDOM_COLORS = (amount) => {
    let colorList = [
        "#ed7d0e",  // Original vibrant orange
        "#0077cc",  // Vibrant blue
        "#e5177b",  // Vibrant pink/magenta
        "#26a65b",  // Strong green
        "#9b59b6",  // Purple
        "#3498db",  // Light blue
        "#e74c3c",  // Red
        "#2c3e50",  // Dark blue (good contrast on white, visible on dark)
        "#f39c12",  // Gold
        "#27ae60",  // Green (slightly different hue from above)
        "#d35400",  // Darker shade of original orange
        "#2980b9",  // Darker blue (contrasts well on white)
        "#c0392b",  // Deep red (good on white, visible on dark)
        "#8e44ad",  // Darker purple (contrasts well on white)
        "#f1c40f",  // Vibrant yellow (will stand out on dark)
        "#d2527f",  // Another shade of pink/magenta
        "#16a085",  // Teal
        "#2ecc71",  // Light green
        "#f39c12",  // Another gold/orange shade
        "#34495e"   // Another shade of dark blue/navy
    ];

    let size = amount > colorList.length ? colorList.length : amount

    return [...Array(size)].map((a, i) => `${colorList[i]}`);
}

export const GET_TIME_FORMAT = (interval) => {
    switch (interval) {
        case "minute": return "hh:mm a";
        case "hour": return "hh:mm a";
        case "day": return "dddd";
        default: return "MM/DD/YY hh:mm a";
    }
}
