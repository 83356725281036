import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import * as m from '../../../utils/helper/methods';
import * as link from '../../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Button, Chip, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { CalendarMonth, CalendarMonthOutlined, CancelOutlined, CheckCircle, CheckCircleOutline, DeleteOutline, HighlightOff, Lock, LockOutlined, MoreHorizOutlined, NotInterested, Send, StopCircle, StopCircleOutlined, TaskAltOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

import State from '../../global/State';
import CopyButton from '../../global/CopyButton';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    }
}));


export default function StreamUnderline(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { stream, isSecure, edit } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const options = [
        { icon: <StopCircleOutlined />, label: "Lock Stream", id: "Lock" },
        { icon: <DeleteOutline />, label: "Delete Stream", id: "Delete" },
    ];


    const handleOpenMenu = (event) => { setAnchorEl(event.currentTarget); };
    const handleCloseMenu = () => { setAnchorEl(null); };

    const handleClickedMenu = (id) => () => {
        edit(id);
        setAnchorEl(null);
    }


    return (
        <Stack
            direction="row" spacing={.8} useFlexGap flexWrap={"wrap"} alignItems={"center"}
            divider={<Typography variant="body1" color="textSecondary">&#8226;</Typography>}
        >
            <State state={stream.state} />
            <Tooltip title="Created at">
                <Typography variant="subtitle2" color="textSecondary">
                    <CalendarMonthOutlined sx={{ fontSize: 12 }} /> {moment(stream.created_at).utc().format('MM/DD/YYYY hh:mm a')} (UTC)
                </Typography>
            </Tooltip>
            {
                stream.streamgroup && stream.playout.h5live.length > 1
                    ? <Chip
                        size="small"
                        icon={<TaskAltOutlined />}
                        label={`Streamgroup (${stream.playout.h5live.length} Playouts)`}
                        variant="outlined"
                        color="primary"
                    />
                    : <Chip
                        size="small"
                        icon={<HighlightOff />}
                        label={`No streamgroup`}
                        color="default"
                    />
            }
            {
                isSecure
                &&
                <Chip
                    size="small" color="info"
                    icon={<LockOutlined />}
                    label={"Secure Stream"}
                    variant="contained"
                />
            }
        </Stack>
    )
}