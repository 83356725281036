import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import { Grid, Typography, IconButton, Divider, Button } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

import light from '../assets/light_mode.jpg';
import dark from '../assets/dark_mode.jpg';
import auto from '../assets/auto_mode.jpg';

import SectionHeader from '../components/global/SectionHeader';
import SectionContainer from '../components/global/SectionContainer';
import { ColorModeContext } from '../components/layout/color-context';
import SwitchToDashboardv2 from '../components/global/SwitchToDashboardv2';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    version: {
        color: theme.palette.primary.light
    },
    img: {
        height: 100,
        width: 150,
        borderRadius: theme.spacing(1)
    },
    activeImg: {
        border: `${theme.palette.primary.main} 3px solid`,
    }
}));

const ThemeBox = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const themeChoosen = sessionStorage.getItem(ls.THEME);
    const selectedTheme = themeChoosen ? themeChoosen : "auto"

    return (
        <Box mr={2} mb={2} onClick={props.clicked}>
            <img
                src={props.image}
                className={clsx(classes.img, {
                    [classes.activeImg]: selectedTheme === props.id
                })
                }
            />
            <Typography
                variant="body2"
                sx=
                {
                    selectedTheme === props.id
                    && { color: theme.palette.primary.main, fontWeight: 700 }
                }
            >
                {props.title}
            </Typography>
        </Box>
    )
}

export default function About(props) {
    const classes = useStyles();
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const [socialMedia] = useState(link.SOCIAL_MEDIA);
    const [today] = useState(new Date())
    // const [activeTheme, setActiveTheme] = useState("light")

    const handleClickedSocialMediaLink = (link) => () => {
        window.open(link, '_blank')
    }

    const handleChangeTheme = (theme) => () => {
        colorMode.toggleColorMode(theme);
    }

    document.title = "About | nanoStream Cloud Dashboard"

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <SectionHeader
                    title="About"
                    underline="Learn more about nanoStream Cloud"
                />
            </Grid>
            <Grid item xs={12} mt={1}>
                {
                    socialMedia.map((s, i) => (
                        <IconButton
                            key={i}
                            color="primary"
                            size="medium"
                            onClick={handleClickedSocialMediaLink(s.link)}
                        >
                            {s.icon}
                        </IconButton>
                    ))
                }
            </Grid>
            <Grid item xs={12} mt={1}>
                <Divider />
            </Grid>
            <Grid item xs={12} mt={1}>
                <SectionHeader
                    title="Appearance"
                    underline="Change the theme of the nanoStream Cloud dashboard based on your preference."
                />
            </Grid>
            <Grid item mt={2} xs={12} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {
                    [
                        { id: "light", title: "Light", image: light },
                        { id: "dark", title: "Dark", image: dark },
                        { id: "auto", title: "System Preference", image: auto },
                    ].map((box, index) => (
                        <ThemeBox
                            key={index}
                            clicked={handleChangeTheme(box.id)}
                            {...box}
                        />
                    ))
                }
            </Grid>
            <Grid item xs={12} mt={1}>
                <Divider />
                <Typography className={classes.version} mt={1} variant="subtitle2">
                    nanoStream Cloud Dashboard {window.VERSION} | 	&copy; {today.getFullYear()} nanocosmos GmbH | Berlin, Germany
                </Typography>
            </Grid>
        </Grid >
    )
}
