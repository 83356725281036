export const convertBytes = (bytes) => {
    if (bytes === 0) return { value: 0, unit: 'Bytes' };
    const k = 1000;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return { value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i] };
};

export const convertBytesToUnit = (bytes, unit) => {
    switch (unit) {
        case 'KB': return bytes / 1000;
        case 'MB': return bytes / 1000000;
        case 'GB': return bytes / 1000000000;
        case 'TB': return bytes / 1000000000000;
        case 'PB': return bytes / 1000000000000000;
        case 'EB': return bytes / 1000000000000000000;
        case 'ZB': return bytes / 1000000000000000000000;
        case 'YB': return bytes / 1000000000000000000000000;
        default: return bytes;
    }
}