import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import SectionContainer from '../global/SectionContainer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    item: {
        [theme.breakpoints.up('md')]: {
            marginRight: `${theme.spacing(2)} !important`
        }
    }
}));

export default function Ingest(props) {
    const classes = useStyles();
    const [ingest, setIngest] = useState(false); // RTMP = false , WEBCASTER = true

    const handleUpdateIngest = (event) => {
        let newIngest = event.target.value
        setIngest(newIngest);
    }

    useEffect(() => {
        props.updateIngest(ingest);
    }, [ingest])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.item}>
                <SectionContainer
                    title="Set Ingest Stream"
                    underline="Choose whether you want to stream via a separate live encoder software or hardware (e.g. OBS) or use our nanoStream Webcaster directly from the browser."
                >
                    <FormControl>
                        <RadioGroup
                            row
                            value={ingest}
                            onChange={handleUpdateIngest}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="RTMP" />
                            <FormControlLabel value={true} control={<Radio />} label="Webcaster" />
                        </RadioGroup>
                    </FormControl>
                </SectionContainer>
            </Grid>
        </Grid>

    )
}
