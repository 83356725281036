import React, { Fragment, useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { Divider, Chip, Grid, TablePagination, Typography } from '@mui/material';
import SnackbarMessage from '../global/SnackbarMessage';
import { useTheme } from '@mui/styles';

export default function Tags(props) {
    const theme = useTheme();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [tags, setTags] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(25);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const fetchTags = () => {
        let data = {
            limit,
            offset: page === 0 ? 0 : page * limit
        }

        bintu.getTagCollection(data)
            .then((response) => {
                if (response.success) {
                    setCount(response.data.overall_tag_count);
                    setTags(response.data.tags);
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setLimit(event.target.value);
        setPage(0);
    }

    const handleClickedTag = (tag) => () => {
        props.clicked(tag);
    }

    useEffect(() => {
        fetchTags();
    }, [limit, page])

    useEffect(() => {
        fetchTags();
    }, [])

    return (
        <Grid xs={12}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {
                tags.length === 0
                &&
                <Grid item xs={12}>
                    <Typography variant="body1">
                        You have no tags yet! Create a new tag while creating or editing a stream. This might help you later find or filter your stream later.
                    </Typography>
                </Grid>
            }
            {
                tags.length > 0
                &&
                <Fragment>
                    <Grid item xs={12}>
                        {
                            tags.map((t, i) => (
                                <Chip
                                    size="small"
                                    key={i}
                                    label={t.tag}
                                    clickable
                                    color="primary"
                                    sx={{
                                        marginRight: `${theme.spacing(0.5)} !important`,
                                        marginTop: `${theme.spacing(0.5)} !important`,
                                        borderRadius: '5px !important'
                                    }}
                                    onClick={handleClickedTag(t.tag)}
                                />
                            ))
                        }
                    </Grid>
                    <Grid item xs={12} mt={1.5}>
                        <Divider direction="horizontal" />
                    </Grid>
                    <Grid item xs={12}>
                        <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            rowsPerPageOptions={[15, 25, 35, 45]}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Fragment>
            }
        </Grid>
    )
}
